import './Language.scss';
import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setButtonState } from '../../state/features/buttonStateSlice';
import { setLanguageSlice } from '../../state/features/languageSlice';
import { ButtonState } from '../shared/button-state/ButtonState';
import { useCurrentWidth } from '../shared/resize/Resize';
import { useAppSelector } from '../../state/hooks';

const languages = [
    { value: 'eng', label: 'ENG', src: 'customize-icons/en.png' },
    { value: 'tr', label: 'TR', src: 'customize-icons/tr.png' },
];

export const Language = () => {
    const dispatch = useDispatch();
    const { i18n, t } = useTranslation();
    const width = useCurrentWidth();
    const [mobile, setMobile] = useState(false);
    const [language, setLanguage] = useState(localStorage.getItem('userLanguage') || 'tr');
    const langSlice = useAppSelector(state => state.language.value)

    useEffect(() => {
        if (width <= 767) {
            setMobile(true)
        } else {
            setMobile(false)
        }
    }, [width])

    useEffect(() => {
        localStorage.getItem('userLanguage');
    }, [])

    /**
    * Bu fonksiyon ile dil seçeneğinin değişimini dinliyoruz.
    */
    const changeLanguage = (e: any) => {
        if (e && !mobile) {
            i18n.changeLanguage(e.value);
            setLanguage(e.value);
            dispatch(setLanguageSlice(e.value))
            localStorage.setItem('userLanguage', e.value);
        }
    }

    /**
    * Seçilen dil seçeneğindeki simge için yazılan fonksiyon.
    */
    const selectedLanguageTemplate = (option: { label: string, src: string, value: string }, props: { placeholder: string }) => {
        if (option && !mobile) {
            if (langSlice !== null) {
                dispatch(setLanguageSlice(option.value))
            } else if (langSlice === null) {
                const lang = localStorage.setItem('userLanguage', option.value)
                dispatch(setLanguageSlice(lang))
            }
            return (
                <div className='country-item-value'>
                    <img alt={option.label} src={option.src} />
                    <div className='lang-span'>{option.label}</div>
                </div>
            );
        }
        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    /**
     * Dil seçeneklerindeki simgeler için yazılan fonksiyon.
     */
    const languageOptionTemplate = (option: any) => {
        if (!mobile) {
            return (
                <div className='country-item'>
                    <img alt={option.label} src={option.src} />
                    <div className='lang-span'>{option.label}</div>
                </div>
            );
        }
    }

    const buttonSelected = (e: any) => {
        i18n.changeLanguage(e.target.value);
        setLanguage(e.target.value);
        dispatch(setLanguageSlice(e.target.value))
        localStorage.setItem('userLanguage', e.target.value);
    }

    return (
        <>
            {!mobile && <div className='Language' onClick={() => dispatch(setButtonState(ButtonState.LANGUAGE))}>
                <Dropdown
                    className='languages-selection'
                    value={language}
                    options={languages}
                    onChange={changeLanguage}
                    optionLabel='label'
                    valueTemplate={selectedLanguageTemplate}
                    itemTemplate={languageOptionTemplate}
                />
            </div>}
            {mobile && <div className='MobileLanguage'>
                <div className='mobile-language'>
                    <div className='mobile-header'>
                        <i className='pi pi-arrow-left' onClick={() => dispatch(setButtonState(ButtonState.HAMBURGER))} />
                        <span>{t('MOBILE.Language')}</span>
                    </div>
                    <div className='mobile-lang-button'>
                        {
                            languages.map((lang) => {
                                return (
                                    <button
                                        key={lang.value}
                                        onClick={(e: any) => buttonSelected(e)}
                                        value={lang.value}
                                        className={(lang.label).toLowerCase() === localStorage.getItem('userLanguage') ? 'selected-button' : ''}
                                    >
                                        {lang.label}
                                    </button>
                                )
                            })
                        }
                    </div>
                </div>
            </div>}
        </>
    )
}


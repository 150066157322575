import Draw, { DrawEvent } from 'ol/interaction/Draw';
import { useContext, useEffect } from 'react'
import { MapContext } from '../MapContext'
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import Text from 'ol/style/Text';
import { never } from 'ol/events/condition';
import { useDispatch } from 'react-redux';
import { setIsDrawing } from '../../../../../state/features/drawStateSlice';
import { getArea } from 'ol/sphere';
interface Props {
    source: any
}

export const DrawPolygon = (props: Props) => {
    const map = useContext(MapContext);
    const dispatch = useDispatch();

    useEffect(() => {

        let draw = new Draw({
            source: props.source,
            type: 'Polygon',
            stopClick: true,
            style: new Style({
                fill: new Fill({
                    color: 'rgb(165, 33, 37, 0.44)',
                }),
                stroke: new Stroke({
                    color: '#A52125',
                    width: 2,
                }),
                image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({
                        color: '#A52125',
                    }),
                }),
            }),
            finishCondition: never,
            condition: function (e: any) {
                //sadece solla çizim yapıyor ve sağla bitiriyor
                if (e.originalEvent.buttons === 1) {
                    return true;
                } else if (e.originalEvent.buttons === 2) {
                    draw.finishDrawing();
                    return false;
                }
                else {
                    return false;
                }
            }
        });

        dispatch(setIsDrawing(true));
        map.addInteraction(draw);

        drawEnd(draw);

        return () => {
            map.removeInteraction(draw)
            dispatch(setIsDrawing(false));
        }

    }, [map])

    const drawEnd = (draw: Draw) => {
        draw.on('drawend', (event) => {
            const polygonArea = getArea(event.feature?.getGeometry());
            setTextAfterDrawEnd(event, polygonArea)
        });
    }

    /**
     * Çizim bittikten sonra ölçüm değerini çizilen nesnenin üzerine yazar. 
     */
    const setTextAfterDrawEnd = (event: DrawEvent, polygonArea: any) => {
        polygonArea = polygonArea.toFixed(2);

        event.feature.setStyle(new Style({
            fill: new Fill({
                color: 'rgb(165, 33, 37, 0.44)',
            }),
            stroke: new Stroke({
                color: '#A52125',
                width: 2,
            }),
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: '#A52125',
                }),
            }),
            text: new Text({
                text: `${polygonArea}m`,
                font: "bold 15px 'Open Sans', 'Arial Unicode MS', 'sans-serif'",
                placement: 'point',
                offsetY: -15,
                fill: new Fill({
                    color: 'white',
                }),
                stroke: new Stroke({
                    color: 'black',
                    width: 3,
                }),
            })

        }));
    }
    return (
        <>

        </>
    )
}

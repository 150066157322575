import { useContext, useEffect, useState } from 'react'
import Draw, { DrawEvent } from 'ol/interaction/Draw';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { transform } from 'ol/proj';
import Text from 'ol/style/Text';
import { MapContext } from '../../MapContext';
import { useDispatch } from 'react-redux';
import Snap from 'ol/interaction/Snap';
import { openAddDigiPopup, setInputValues, setProvinceProperties } from '../../../../../../state/features/digitizationSlice';
import { AddDigitization } from '../AddDigitization';
import { useAppSelector } from '../../../../../../state/hooks';
import { DrawType, From, Mode, TransactWfs } from '../TransactWfs';
import WKT from 'ol/format/WKT';
import { DigitizationRestApi } from '../../../../../../util/restapi/digitization';
import { setIsDrawing } from '../../../../../../state/features/drawStateSlice';

interface Props {
    source: any;
}
/* 
 * Bu componentle point geometri tipinde bir feature oluşrutuyoruz.
 *
*/

export const DigiDrawPoint = (props: Props) => {
    const map = useContext(MapContext);
    const dispatch = useDispatch();

    const openDigiPopup = useAppSelector((state) => state.digitization.openPopup)
    const startAddDigitization = useAppSelector((state) => state.digitization.startAddDigi)

    const layer: any = useAppSelector((state) => state.digitization.layer)

    const [feature, setFeature] = useState<any>(undefined)

    useEffect(() => {
        let draw = new Draw({
            source: props.source,
            type: 'Point',
            style: new Style({
                fill: new Fill({
                    color: '#A52125',
                }),
                image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({
                        color: '#A52125',
                    }),
                }),

            }),
        });
        const snap = new Snap({
            source: props.source
        });

        map.addInteraction(snap);

        map.addInteraction(draw);
        dispatch(setIsDrawing(true)); 


        draw.on('drawend', (event) => {
            map.removeInteraction(draw)
            setTextAfterDrawEnd(event)
            setFeature(event.feature)
        });
        const content = map.getTargetElement();
        content.addEventListener('contextmenu', (event: MouseEvent) => {
            map.removeInteraction(draw);
        });

        return () => {
            map.removeInteraction(draw)
            dispatch(setIsDrawing(false)); 
            props.source.clear();
        }

    }, [map])

    /**
      * Çizim bittikten sonra ölçüm değerini çizilen nesnenin üzerine yazar. 
      */
    const setTextAfterDrawEnd = (event: DrawEvent) => {
        let coordinates: any = event.feature?.getGeometry().getCoordinates();
        coordinates = transform(coordinates, 'EPSG:3857', 'EPSG:4326');

        let format = new WKT(),
        wktGeom = format.writeGeometry(event.feature?.getGeometry(), { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });

        dispatch(openAddDigiPopup(true))

        const cityField = layer.fields.find((field:any)=> field.type === 'City')
        const distinctField = layer.fields.find((field:any)=> field.type === 'Distinct')
        const neighorhoodField = layer.fields.find((field:any)=> field.type === 'Neighbourhood')

        if(cityField || distinctField || neighorhoodField){
            DigitizationRestApi.findProvinceWithGeometry(wktGeom).then((res) => {
                const prop = res.data.features[0].properties
                dispatch(setInputValues({ id: neighorhoodField.id, e: { mah_id: prop.mah_id, adi: prop.adi } }))
                dispatch(setInputValues({ id: distinctField.id, e: { ilce_id: prop.ilce_id, adi: prop.ilce_adi } }))
                dispatch(setInputValues({ id: cityField.id , e: { il_id: prop.il_id, adi: prop.il_adi } }))
    
                dispatch(setProvinceProperties({il_id:prop.il_id, ilce_id:prop.ilce_id, mah_id:prop.mah_id}))
            })
        }

        coordinates[0] = coordinates[0].toFixed(6)
        coordinates[1] = coordinates[1].toFixed(6)
        event.feature.setStyle(new Style({
            fill: new Fill({
                color: '#A52125',
            }),
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: '#A52125',
                }),
            }),
            text: new Text({
                text: `${coordinates}`,
                font: "bold 15px 'Open Sans', 'Arial Unicode MS', 'sans-serif'",
                placement: 'point',
                offsetY: -15,
                fill: new Fill({
                    color: 'white',
                }),
                stroke: new Stroke({
                    color: 'black',
                    width: 3,
                }),
            }),
        }));
    }

    return (
        <>
            {openDigiPopup && <AddDigitization />}
            {startAddDigitization && <TransactWfs feature={feature} mode={Mode.INSERT} source={props.source} type={DrawType.POINT} from={From.TABLE} />}
        </>
    )
}

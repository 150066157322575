import axios from 'axios';

const { REACT_APP_BASE_URL } = process.env

const getToken = () => {
    return localStorage.getItem('token')
}

async function getAttachmentInfo(layerId: any, featureId: any) {
    return axios.get(REACT_APP_BASE_URL + `rest/v1/attachment/${layerId}/${featureId}`, { params: { token: getToken() } });
}
async function deleteFileById(attachmentId: any) {
    return axios.delete(REACT_APP_BASE_URL + `rest/v1/attachment/${attachmentId}`, { params: { token: getToken() } });
}
async function addAttachment(layerId: any, featureId: any, f: any) {
    return axios.post(REACT_APP_BASE_URL + `rest/v1/attachment/${layerId}/${featureId}`, f, { params: { token: getToken() } });
}
async function getAttachmentsOfLayer(layerId: any,) {
    return axios.get(REACT_APP_BASE_URL + `rest/v1/attachment/exportRelation/${layerId}`, { params: { token: getToken() } });
}

export const AttachmentRestApi = {
    getAttachmentInfo,
    deleteFileById,
    addAttachment,
    getAttachmentsOfLayer
}
import './Map.scss'
import 'ol/ol.css';
import View from 'ol/View';
import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { Map as OlMap, MapBrowserEvent } from 'ol';
import { MapContext } from './MapContext';
import { useAppSelector } from '../../../../state/hooks';
import { useDispatch } from 'react-redux';
import { setCoordMapToPano } from '../../../../state/features/panoramaSlice';
import { transform } from 'ol/proj';
import { ButtonState } from '../../../shared/button-state/ButtonState';
import { Toast } from 'primereact/toast';
import { setButtonState } from '../../../../state/features/buttonStateSlice';
import { useTranslation } from 'react-i18next';
import * as olInteraction from 'ol/interaction';
interface IProps {
  centerX: number;
  centerY: number;
  zoom: number;
  extent: Array<any>
}

export const Map = (props: PropsWithChildren<IProps>) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tMap = useRef<OlMap | null>(null);
  const mapRef = useRef(document.createElement('div'));
  const toast = useRef<any>(null);
  const [olMap, setOlMap] = useState<OlMap | null>(null);
  const [mouseCoordinates, setMouseCoordinates] = useState<Array<any>>([]);

  const panoramaVisibility = useAppSelector((state) => state.panorama.visible);
  const buttonState = useAppSelector((state) => state.buttonState.value);
  let coordFromPano: any = useAppSelector((state) => state.panorama.panoCoord);

  useEffect(() => {
    var interactions = olInteraction.defaults({ altShiftDragRotate: false, pinchRotate: false });

    const v = new View({
      center: [props.centerX, props.centerY],
      zoom: props.zoom,
      minZoom: props.zoom - 9.5,
      maxZoom: 24,
      extent: props.extent
    });
    const map = new OlMap({
      layers: [],
      target: mapRef.current,
      view: v,
      controls: [],
      interactions: interactions
    });

    tMap.current = map;
    setOlMap(map);
    (window as any).map = map

    map.on('pointermove', (event) => {
      var coord3857 = event.coordinate;
      var coord4326 = transform(coord3857, 'EPSG:3857', 'EPSG:4326');
      setMouseCoordinates(coord4326)
    });

    return () => map.dispose();
  }, [mapRef]);

  useEffect(() => {
    if (buttonState === ButtonState.COPY_COORDINATES) {
      toast.current.show({ severity: 'success', detail: (t('CONTEXT.Coordinates copied successfully') + `${mouseCoordinates}`), life: 3000 });
      dispatch(setButtonState(ButtonState.DEFAULT))
      navigator.clipboard.writeText(`${mouseCoordinates}`)
    }
  }, [buttonState])

  const resize = () => {
    const map = tMap.current
    map!.updateSize()
  }

  const [resizeObserver, setresizeObserver] = useState(new (window as any).ResizeObserver(resize.bind(Map)))
  resizeObserver.observe(mapRef.current);

  const mouseUpUpdate = () => {
    setTimeout(() => {
      olMap?.updateSize();
    }, 100);
  }

  document.addEventListener('mouseup', mouseUpUpdate)
  window.addEventListener('resize', mouseUpUpdate)

  useEffect(() => {
    if (panoramaVisibility && coordFromPano) {
      const coordTransform = transform([coordFromPano.lon, coordFromPano.lat], 'EPSG:4326', 'EPSG:3857')
      if (coordTransform) {
        olMap?.getView().setCenter(coordTransform)
      }
    }
  }, [panoramaVisibility, coordFromPano])


  return (
    <>
      <div className='Map' ref={mapRef}></div>
      {
        olMap &&
        <MapContext.Provider value={olMap}>
          {props.children}
          <Toast ref={toast} position='bottom-center' className='coord-toast' />
        </MapContext.Provider>
      }
    </>
  )
}
import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
    searchList: []
}

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        //hızlı aramadan dönen sonucu setleyip mobil için Info.tsx'ten çağırıyoruz.
        setSearchResult: (state: any, action) => {
            state.searchList = action.payload;
        },
    }
})

export const { setSearchResult } = searchSlice.actions

export default searchSlice.reducer

import axios from 'axios';

const {REACT_APP_BASE_URL} = process.env


async function getBasemaps() {
    return axios.get(REACT_APP_BASE_URL + 'rest/v1/basemap/');

}

export const BasemapRestApi = {
    getBasemaps
}
import { useContext, useEffect } from 'react'
import { useAppSelector } from '../../../../../state/hooks'
import { MapContext } from '../MapContext';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { GeoJSON, WKT } from 'ol/format';


const SelectedFeatureExtent = () => {
  const selectedFeature: any = useAppSelector((state) => state.selectedFeature.feature)
  const extent: any = useAppSelector((state) => state.selectedFeature.extent)
  const infoVisibility = useAppSelector((state) => state.info.visible)
  const tableVisibility = useAppSelector((state) => state.table.visible)
  const map = useContext(MapContext);
  let feature: any = {}
  let geom: any = {}

  useEffect(() => {
    map.updateSize();
  }, [])

  useEffect(() => {
    // if (tableVisibility) {
    //   return;
    // }
    if (extent) {
      const vectorSource = new VectorSource();

      const vector = new VectorLayer({
        source: vectorSource,
        zIndex: 10000000000000000,
        style: new Style({
          fill: new Fill({
            color: 'rgb(56, 238, 245, 0.44)',
          }),
          stroke: new Stroke({
            color: '#38EEF5',
            width: 2,
          }),
          image: new CircleStyle({
            radius: 5,
            fill: new Fill({
              color: '#38EEF5',
            }),
          }),
        })
      });

      map.addLayer(vector);

      if (selectedFeature) {
        if (selectedFeature.type) {
          const geoJson = new GeoJSON();
          feature = geoJson.readFeature(selectedFeature);
          geom = feature.getGeometry()
        }

        if (!selectedFeature.type) {
          const wkt = new WKT();
          feature = wkt.readFeature(selectedFeature.st_astext);
          const featureGeometry = feature.getGeometry();
          geom = featureGeometry.transform('EPSG:4326', 'EPSG:3857')
        }

        vectorSource.addFeature(feature);

        map.getView().fit(geom, { duration: 500, maxZoom: 18.5 });
      }

      if (!infoVisibility) {
        map.removeLayer(vector)
      }

      return () => {
        map.removeLayer(vector)
      }
    }
  }, [selectedFeature, extent, infoVisibility, tableVisibility])

  return (
    <>
    </>
  )
}

export default SelectedFeatureExtent


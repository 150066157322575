import './DrawTools.scss'
import { useDispatch } from 'react-redux'
import { setDrawState } from '../../../../state/features/drawStateSlice'
import { useAppSelector } from '../../../../state/hooks'
import { PrimaryButton } from '../../../shared/buttons/primary-button/PrimaryButton'
import { useTranslation } from 'react-i18next'
import { setButtonState } from '../../../../state/features/buttonStateSlice'
import { ButtonState } from '../../../shared/button-state/ButtonState'

export enum DrawStateType {
    POINT = 'POINT',
    LINESTRING = 'LINESTRING',
    POLYGON = 'POLYGON',
    CLEAR = 'CLEAR',
    FINISH = 'FINISH',
    DEFAULT = 'DEFAULT',
    NONE = 'NONE'
}

export const DrawTools = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const drawState = useAppSelector(state => state.drawState.value);
    const token = useAppSelector((state) => state.login.token);

    return (
        <>
            <div className='mobile-draw-header'>
                <i className='pi pi-arrow-left' onClick={() => dispatch(setButtonState(ButtonState.HAMBURGER))} />
                <span>{t('MOBILE.Draw')}</span>
            </div>
            <div className={`${!token ? 'draw-button-group2' : 'draw-button-group'}`}>
                <div className='button-group'>
                    <span className='finish-button' onClick={() => dispatch(setDrawState(DrawStateType.FINISH))}>{t('MOBILE.Finish Draw')}</span>
                </div>
                <div className='measurement-container'>
                    <div className='button-group'>
                        <PrimaryButton
                            className='mobile-draw-button'
                            svg='customize-icons/clear.svg'
                            onClick={() => dispatch(setDrawState(DrawStateType.CLEAR))}
                            moreStyle={drawState === DrawStateType.DEFAULT ? 'passive-draw-button' : 'mobile-draw-button'}
                        ></PrimaryButton>
                        <span className='label'>{t('MOBILE.Clear')}</span>
                    </div>
                    <div className='button-group'>
                        <PrimaryButton
                            className='mobile-draw-button'
                            svg='customize-icons/area.svg'
                            moreStyle={drawState === DrawStateType.POLYGON ? 'active-draw-button' : 'mobile-draw-button'}
                            onClick={() => dispatch(setDrawState(DrawStateType.POLYGON))}>
                        </PrimaryButton>
                        <span className='label'>{t('MOBILE.Polygon')}</span>
                    </div>
                    <div className='button-group'>
                        <PrimaryButton
                            className='mobile-draw-button'
                            svg='customize-icons/line.svg'
                            moreStyle={drawState === DrawStateType.LINESTRING ? 'active-draw-button' : 'mobile-draw-button'}
                            onClick={() => dispatch(setDrawState(DrawStateType.LINESTRING))}>
                        </PrimaryButton>
                        <span className='label'>{t('MOBILE.Line')}</span>
                    </div>
                    <div className='button-group'>
                        <PrimaryButton
                            className='mobile-draw-button'
                            svg='customize-icons/point.svg'
                            moreStyle={drawState === DrawStateType.POINT ? 'active-draw-button' : 'mobile-draw-button'}
                            onClick={() => dispatch(setDrawState(DrawStateType.POINT))}>
                        </PrimaryButton>
                        <span className='label'>{t('MOBILE.Location')}</span>
                    </div>
                </div>

            </div>
        </>
    )
}

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setButtonState } from '../../state/features/buttonStateSlice';
import { setToken } from '../../state/features/loginSlice';
import { useAppSelector } from '../../state/hooks';
import { ButtonState } from '../shared/button-state/ButtonState';
import { PrimaryButton } from '../shared/buttons/primary-button/PrimaryButton'

export default function Logout() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const token = useAppSelector(state => state.login.token)

    const logout = () => {
        if (token) {
            dispatch(setToken(localStorage.removeItem('token')))
            dispatch(setButtonState(ButtonState.DEFAULT))
        }
    }

    return (
        <div className='Logout'>
            <PrimaryButton
                className='entry-exit'
                icon='pi pi-sign-out'
                tooltip={t('BUTTON.Logout')}
                onClick={logout}
            >
            </PrimaryButton>
        </div>
    )
}

import './Sidebar.scss';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../state/hooks';
import { RootState } from '../../../state/store';
import { Tree } from 'primereact/tree';
import { ContextMenu } from 'primereact/contextmenu';
import TreeNode from 'primereact/treenode';
import { ILayer, IService } from '../../../util/model/layer';
import { getServices, getFeatures } from '../../../state/features/serviceSlice';
import { changeTableVisibility, setLayer } from '../../../state/features/tableSlice';
import { useTranslation } from 'react-i18next';
import { LayerRestApi } from '../../../util/restapi/layer';
import { setDigitizationLayer, startDigitization } from '../../../state/features/digitizationSlice';

export const Sidebar = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const services = useAppSelector((state: RootState) => state.service.services);
    const geoserverWriteServices = services.filter((ws) => ws.type === 'GEOSERVER_WRITE')
    const language = useAppSelector((state) => state.language.value)
    const token = useAppSelector((state) => state.login.token)
    const [nodes, setNodes] = useState<TreeNode[]>();
    const [selectedKeys, setSelectedKeys] = useState<any>();
    const [selectedNodeKey, setSelectedNodeKey] = useState<any>();
    const [expandedKeys, setExpandedKeys] = useState({});
    const contextMenu: any = useRef(null);
    const rightClickLayerMenu = [
        {
            label: t('SIDEBAR.Open Table'),
            command: () => {
                dispatch(changeTableVisibility(true))
                // @ts-ignore
                dispatch(setLayer(contextMenu.current!.layer))
                // @ts-ignore
                LayerRestApi.getFeatures([contextMenu.current!.layer.name]).then((res: any) => {
                    dispatch(getFeatures(res.data.features));
                })
            }
        },
        {
            label: t('SIDEBAR.Draw Object'),
            command: () => {
                // @ts-ignore
                dispatch(setDigitizationLayer(contextMenu.current!.layer))
                dispatch(startDigitization(true))
            }
        },
    ];

    /**
     * Bu fonksiyon ile servislerin açılıp kapanması kontrol ediliyor.
     */
    const setNodesVisibility = (geoserverWriteServices: IService[]) => {
        let nodeCheckList: any = {};
        geoserverWriteServices.forEach((service: IService) => {

            const layerVisibility = service.layers.map((layer: ILayer) => {
                if (layer.visible) {
                    nodeCheckList[layer.name] = { checked: true, partialChecked: false }
                }
                return { key: layer.name, visible: layer.visible }
            });

            const serviceChecked = layerVisibility.every((l) => l.visible === true);
            const servicePartialChecked = layerVisibility.findIndex((l) => l.visible === true) !== -1;

            if (serviceChecked && servicePartialChecked) {
                nodeCheckList[service.name] = { checked: true, partialChecked: false }
            } else if (serviceChecked || servicePartialChecked) {
                nodeCheckList[service.name] = { checked: false, partialChecked: true }
            }
        })

        setSelectedKeys(nodeCheckList);
    }

    /**
    * Bu fonksiyon ile katmanların açılıp kapanması kontrol ediliyor.
    */
    const changeLayerVisibility = (checkListValues: any) => {
        const serviceList = (geoserverWriteServices as IService[]).concat();
        const nodes: string[] = [];

        for (const [key] of Object.entries(checkListValues)) {
            nodes.push(key);
        }

        const newServiceList = serviceList.map((service: IService) => {
            const layers = service.layers.map(ll => {
                if (nodes.findIndex(nn => nn === ll.name) !== -1) {
                    return {
                        ...ll,
                        visible: true
                    }

                } else {
                    return {
                        ...ll,
                        visible: false
                    }
                }
            })

            return {
                ...service,
                layers: layers
            }
        })
        dispatch(getServices(newServiceList));
    }

    useEffect(() => {

        LayerRestApi.getStyles().then((stylesResponse: any) => {
            const classes = document.createElement('style');
            classes.innerHTML = ``;
            const tree = []
            const styles = stylesResponse.data.styles;
            for (const service of geoserverWriteServices) {
                const serviceAlias = (language === 'tr' ? service['alias'] : (service as any)['alias_' + language]);
                const children = []
                for (const layer of service.layers) {
                    const layerAlias = (language === 'tr' ? layer['alias'] : (layer as any)['alias_' + language]);
                    const style = styles.find((obj: any) => obj.id === layer.style_id);
                    if (style) {
                        switch (style.type) {
                            case 'point':
                                if (style.icon) {
                                    classes.innerHTML += `\n.iconforsidebar_${layer.name} { background-image: url(data:image/${style.icon}) }`
                                }
                                break;
                            case 'linestring':
                                const lineCanvas = document.createElement('canvas');
                                lineCanvas.width = 20
                                lineCanvas.height = 20
                                const ctx = lineCanvas.getContext('2d')!;
                                ctx.moveTo(0, 10);
                                ctx.lineTo(20, 10);
                                ctx.lineWidth = style.stroke_width;
                                ctx.strokeStyle = style.stroke_color;
                                ctx.stroke();
                                const dataURL = lineCanvas.toDataURL();
                                const classString = `\n.iconforsidebar_${layer.name} { background-image: url(${dataURL}) }`
                                classes.innerHTML += ` ${classString}`
                                break;
                            case 'polygon':
                                const polygonCanvas = document.createElement('canvas');
                                polygonCanvas.width = 20
                                polygonCanvas.height = 20
                                const polygonCtx = polygonCanvas.getContext('2d')!;
                                polygonCtx.fillStyle = style.fill_color;
                                polygonCtx.strokeStyle = style.stroke_color;
                                polygonCtx.lineWidth = style.stroke_width;
                                polygonCtx.beginPath();
                                polygonCtx.moveTo(0, 0);
                                polygonCtx.lineTo(20, 0);
                                polygonCtx.lineTo(20, 20);
                                polygonCtx.lineTo(0, 20);
                                polygonCtx.closePath();
                                polygonCtx.fill();
                                const dataURLPolygon = polygonCanvas.toDataURL();
                                const classStringPolygon = `\n.iconforsidebar_${layer.name} { background-image: url(${dataURLPolygon}) }`
                                classes.innerHTML += ` ${classStringPolygon}`
                                break;
                            default:
                                break;
                        }
                    }
                    const child = {
                        key: layer.name,
                        label: layerAlias,
                        data: layer,
                        icon: `iconforsidebar_${layer.name}`
                    }
                    children.push(child);
                }

                const serviceNode = {
                    key: service.name,
                    label: serviceAlias,
                    data: '',
                    children
                }

                tree.push(serviceNode);
            }
            document.getElementsByTagName('head')[0].appendChild(classes);
            setNodes(tree)
            setNodesVisibility(geoserverWriteServices)
        })
    }, [language, services])

    return (
        <>
            {token && <div className='sidebar-container'>
                <ContextMenu model={rightClickLayerMenu} ref={contextMenu} onHide={() => setSelectedNodeKey(null)} />
                <Tree
                    className='sidebar'
                    value={nodes}
                    expandedKeys={expandedKeys}
                    onToggle={e => setExpandedKeys(e.value)}
                    contextMenuSelectionKey={selectedNodeKey}
                    onContextMenuSelectionChange={event => setSelectedNodeKey(event.value)}
                    onContextMenu={event => {
                        const serviceIndex = geoserverWriteServices.findIndex(ss => ss.name === event.node.key)
                        if (serviceIndex === -1) {
                            // @ts-ignore
                            contextMenu.current!.layer = event.node.data
                            // @ts-ignore
                            contextMenu.current!.show(event.originalEvent)
                        }
                    }}
                    selectionKeys={selectedKeys}
                    selectionMode='checkbox'
                    onSelectionChange={e => {
                        contextMenu.current!.state.visible = false;
                        changeLayerVisibility(e.value);

                    }}
                />
            </div>}
        </>
    )
}


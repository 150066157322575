export enum ButtonState {
    BASEMAPS = 'BASEMAPS',
    ROTATION = 'ROTATION',
    CATEGORICAL_SEARCH = 'CATEGORICAL_SEARCH',
    QUICK_SEARCH = 'QUICK_SEARCH',
    LANGUAGE = 'LANGUAGE',
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    PANORAMA = 'PANORAMA',
    MEASUREMENT = 'MEASUREMENT',
    PANORAMA_MEASUREMENT = 'PANORAMA_MEASUREMENT',
    PRINT = 'PRINT',
    SHARE = 'SHARE',
    DIGITIZATION = 'DIGITIZATION',
    TABLE = 'TABLE',
    LAYERS = 'LAYERS',
    COPY_COORDINATES = 'COPY_COORDINATES',
    DEFAULT = 'DEFAULT',

    //mobile responsive icin eklenenler
    MOBILE_SHARE = 'MOBILE_SHARE',
    HAMBURGER = 'HAMBURGER',
    CONTAINER = 'CONTAINER',
    MOBILE_CATEGORICAL_SEARCH = 'MOBILE_CATEGORICAL_SEARCH',

}
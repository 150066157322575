import { createSlice } from '@reduxjs/toolkit';

interface IFeatureState {
    feature: {},
    extent: boolean,
}

const initialState: IFeatureState = {
    feature: {},
    extent: false,
}

export const selectedFeatureSlice = createSlice({
    name: 'selectedFeature',
    initialState,
    reducers: {
        //secilen feature selectedFeatureExtent.tsx'e setleniyor.
        setSelectedFeature: (state: any, action) => {
            state.feature = action.payload;
        },
        //selectedFeatureExtent.tsx'in render olmasina boolean deger setleniyor. 
        setFeatureExtent: (state: any, action) => {
            state.extent = action.payload;
        },
    }
})

export const { setSelectedFeature, setFeatureExtent } = selectedFeatureSlice.actions

export default selectedFeatureSlice.reducer
import axios from 'axios';

const { REACT_APP_BASE_URL } = process.env

const getToken = () => {
    return localStorage.getItem('token')
}

async function login(username: string, password: string) {
    return axios.post(REACT_APP_BASE_URL + 'rest/v1/auth/login', {
        username,
        password,
    });
}

async function loginUser() {
    return axios.get(REACT_APP_BASE_URL + 'rest/v1/user/me', { params: { token: getToken() } })
}

export const LoginRestApi = {
    login,
    loginUser
}
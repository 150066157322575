import { GeoJSON } from 'ol/format';
import { useContext, useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../state/hooks'
import { MapContext } from '../MapContext';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import { RootState } from '../../../../../state/store';

export const DrawCities = () => {
    const infoVisibility = useAppSelector((state: RootState) => state.info.visible);
    const cityFeature = useAppSelector(state => state.cityFeature.feature)
    const mainHeaderFeature = useAppSelector(state => state.cityFeature.mainHeaderFeature)
    const map = useContext(MapContext);
    const [vector, setVector] = useState<VectorLayer<any>>(new VectorLayer())
    const [source, setSource] = useState<VectorSource<any>>(new VectorSource())

    useEffect(() => {
        const source = new VectorSource({ wrapX: false });
        const vector = new VectorLayer({
            source: source,
            zIndex: 100000000,
            style: new Style({
                fill: new Fill({
                    color: '#f8f9fa5e',
                }),
                stroke: new Stroke({
                    color: '#A52125',
                    width: 3,
                }),
            }),
        });

        map.addLayer(vector);
        setVector(vector)
        setSource(source)

        return () => {
            map.removeLayer(vector)
        }
    }, [])

    useEffect(() => {
        if (!infoVisibility) {
            source.clear();
        }
    }, [infoVisibility])

    useEffect(() => {
        if (!cityFeature.type) {
            source.clear();
            return;
        }

        const geojson = new GeoJSON()
        const feature = geojson.readFeature(cityFeature);

        source.addFeature(feature)

        var extent = source.getExtent();
        map.getView().fit(extent, { padding: [20, 20, 20, 20], maxZoom: 15 });
        return () => {
            source.clear();
        }
    }, [cityFeature])

    useEffect(() => {
        if (!mainHeaderFeature.type) {
            source.clear();
            return;
        }

        const geojson = new GeoJSON()
        const feature = geojson.readFeature(mainHeaderFeature);
        let featureGeometry = feature.getGeometry()
        const geom = featureGeometry.transform('EPSG:4326', 'EPSG:3857')

        var extent = source.getExtent();
        map.getView().fit(geom, { padding: [20, 20, 20, 20] });
        return () => {
            source.clear();
        }
    }, [mainHeaderFeature])

    return (
        <></>
    )
}

import axios from 'axios';

const { REACT_APP_BASE_URL } = process.env

async function getReport(selectedReport: any) {
    return axios.get(REACT_APP_BASE_URL + `rest/v1/report/${selectedReport}`, { responseType: 'blob' })
}
async function postReport(selectedReport: any, tabledata: any) {
    return axios.post(REACT_APP_BASE_URL + `rest/v1/report/${selectedReport}`, { tabledata })
}
export const TableRestApi = {
    getReport,
    postReport

}
import './App.scss';
import { Navbar } from './components/layouts/navbar/Navbar';
import { Toolbar } from './components/layouts/toolbar/Toolbar';
import { MapContainer } from './components/containers/map-container/MapContainer';
import { DataTableGridLines } from './components/shared/table/DataTableGridLines';
import { useAppSelector } from './state/hooks';
import { RootState } from './state/store';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCurrentWidth } from './components/shared/resize/Resize';
import { WorkerInfo } from './components/shared/worker-info/WorkerInfo';
import { setLanguageSlice } from './state/features/languageSlice';
import { Info } from './components/shared/info/Info';
import { EditObject } from './components/feature/EditObject';
import { DigiCallbackContextProvider } from './components/containers/map-container/map/digitization/DigiCallbackContextProvider';
import Header from './components/mobile-components/header/Header';
import ModelButtons from './components/mobile-components/model-buttons/ModelButtons';
import { setToken } from './state/features/loginSlice';
import PanoramaContainer from './components/containers/panorama-container/PanoramaContainer';
import { DigitizationRestApi } from './util/restapi/digitization';
import { setAllOfCity, setAllOfDistincts, setAllOfNeighborhoods } from './state/features/digitizationSlice';
import { useTranslation } from 'react-i18next';
import { setButtonState } from './state/features/buttonStateSlice';
import { Share } from './components/share/Share';
import { ButtonState } from './components/shared/button-state/ButtonState';
import { ContextMenu } from 'primereact/contextmenu';
import axios from 'axios';

export const App = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const width = useCurrentWidth();
  const contextMenu: any = useRef(null);
  const [mobile, setMobile] = useState<any>();
  const tableVisibility = useAppSelector((state: RootState) => state.table.visible);
  const panoramaVisibility = useAppSelector((state: RootState) => state.panorama.visible);
  const infoVisibility = useAppSelector((state: RootState) => state.info.visible);
  const token = useAppSelector((state: RootState) => state.login.token);
  const openEdit: boolean = useAppSelector((state) => state.popup.openEditPopup)
  const openEditFromInfo: boolean = useAppSelector((state) => state.popup.openEditPopupFromInfo)
  const openDigitization = useAppSelector((state) => state.digitization.openDigitization)
  const selectedFeatureOnTable = useAppSelector((state) => state.table.isSelectedFeatureOnTable)
  const openEditGeometry = useAppSelector((state) => state.digitization.openGeometryEdit)
  const buttonState = useAppSelector(state => state.buttonState.value);
  let gifElement: any = document.getElementsByClassName("anadolu-gif");
  let loginGifElement: any = document.getElementsByClassName("anadolu-worker-gif")[0];
  let cityProperties: any = []
  let distinctsOfCity: any = []
  let neighborhoods: any = []

  useEffect(() => {
    if (token && loginGifElement) {
      loginGifElement.style.display = 'block';
      setTimeout(() => {
        if (loginGifElement) {
          loginGifElement.style.display = 'none';
        }
      }, 3000);
    }
  }, [token])

  useEffect(() => {
    if (!token) {
      setTimeout(() => {
        gifElement[0].style.display = 'none'
      }, 4500);
    }
  }, [token])

  useEffect(() => {
    axios.interceptors.response.use(
      function (successfulReq) {
        return successfulReq;
      },
      function (error) {
        if (error.response.status === 401) {
          dispatch(setToken(localStorage.removeItem('token')));
        }
      }
    );
  }, [])

  useEffect(() => {
    dispatch(setLanguageSlice(localStorage.getItem('userLanguage')))
  }, []);

  useEffect(() => {
    if (width <= 767) {
      setMobile(width)
      dispatch(setToken(localStorage.removeItem('token')))
    } else {
      setMobile(width)
    }
  }, [width])

  useEffect(() => {
    document.addEventListener('contextmenu', rightClickHandler)
    return () => document.removeEventListener('contextmenu', rightClickHandler)
  }, [buttonState])

  useEffect(() => {
    setCities();
    setAllDistincts();
    setAllNeighborhoods();
  }, [])

  /**
   * Ekrana sağ tıklama ile açılan context menüyü engelliyor.
   */
  const rightClickHandler = (event: any) => {
    event.preventDefault();
    if (buttonState !== ButtonState.MEASUREMENT) {
      if (event.srcElement.localName === 'canvas' && event.srcElement.offsetParent.className !== 'pano') {
        contextMenu.current?.show(event)
      }
    }
  }

  const items = [
    {
      label: (t('CONTEXT.Share')),
      command: () => {
        dispatch(setButtonState(ButtonState.SHARE))
      }
    },
    {
      label: (t('CONTEXT.Print')),
      command: () => {
        window.print();
      }
    },
    {
      label: (t('CONTEXT.CopyCoordinates')),
      command: () => {
        dispatch(setButtonState(ButtonState.COPY_COORDINATES))
      }
    }
  ];

  /**
* Tüm şehirleri ve id lerini getirir.
*/
  const setCities = () => {
    DigitizationRestApi.getCities().then((res) => {
      const features = res.data.features
      features.map((feature: any) => {
        cityProperties.push(feature.properties)
      })
      dispatch(setAllOfCity(cityProperties))
    })
  }

  /**
  * Seçilen ildeki ilçeleri getirir
  */
  const setAllDistincts = () => {
    DigitizationRestApi.getAllDistincts().then((res) => {
      const features = res.data.features
      features?.map((feature: any) => {
        distinctsOfCity.push(feature.properties)
      })
      dispatch(setAllOfDistincts(distinctsOfCity))
    })
  }

  /**
  * Seçilen ilçedeki mahalleleri getirir
  */
  const setAllNeighborhoods = () => {
    DigitizationRestApi.getAllNeighborhoods().then((res) => {
      const features = res.data.features
      features?.map((feature: any) => {
        neighborhoods.push(feature.properties)
      })
      dispatch(setAllOfNeighborhoods(neighborhoods))
    })
  }

  return (
    <>
      <DigiCallbackContextProvider>
        {mobile > 767 && <div className='desktop App' id='app'>
          {!token && <div className='anadolu-gif' id='gif'>
            <img src='anadolu-gif.gif' alt='gif' />
          </div>}
          <div className='anadolu-worker-gif' id='gif2'>
            <img src='anadolu-worker-gif.gif' alt='gif2' />
          </div>
          {!openEdit && <ContextMenu model={items} ref={contextMenu} />}
          <Navbar />
          <div className='body'>
            {openEditFromInfo && token && <EditObject />}
            {openEdit && token && <EditObject />}
            <div className={`main-content ${panoramaVisibility ? 'panorama-opened' : ''}`}>
              <div className={`toolbar ${infoVisibility ? 'info-opened' : ''}`}>
                <Toolbar />
              </div>
              <div className={`container ${tableVisibility && token ? 'container-table-opened' : ''}`}>
                <Splitter className='p-mb-5' gutterSize={panoramaVisibility ? 5 : 0}>
                  <SplitterPanel className='p-d-flex p-ai-center p-jc-center' size={50}>
                    {<div className='map-container'>
                      <MapContainer />
                      {!token && infoVisibility && <Info />}
                      {token && infoVisibility && <WorkerInfo />}
                    </div>}
                  </SplitterPanel>
                  <SplitterPanel className={`p-d-flex p-ai-center p-jc-center ${!panoramaVisibility ? 'panorama-closed' : ''}`} size={50}>
                    {<div className='panorama-container'>
                      <PanoramaContainer />
                    </div>}
                  </SplitterPanel>
                </Splitter>
              </div>

              <div className={`table ${tableVisibility && token ? 'table-opened' : ''} ${infoVisibility ? 'table-info-opened' : ''}`}>
                {tableVisibility && <DataTableGridLines />}
              </div>

              <div className='ankageo-logo'>
                <a href='http://www.ankageo.com' target='blank'>
                  <img src='ankageo-logo.png' alt='ankageo-logo' />
                </a>
              </div>

            </div>
          </div>
        </div>}
      </DigiCallbackContextProvider>

      {/* mobil responsive icin olusturulan app */}
      {!token && mobile <= 767 && <div className='mobile App' id='app'>
        <Header />
        <div className='body'>
          <ModelButtons />
          {!token && infoVisibility && <Info />}
          <div className={`main-content ${panoramaVisibility ? 'panorama-opened' : ''}`}>
            <div className='container'>
              <div className='map-container'>
                <MapContainer />
              </div>
              <div className={`p-d-flex p-ai-center p-jc-center ${!panoramaVisibility ? 'panorama-closed' : 'panorama-container'}`}>
                {<div className='panorama-container'>
                  <PanoramaContainer />
                </div>}
              </div>
            </div>
          </div>
          {/* {panoramaVisibility && <div className='select-panogps' >
                <p>You should select a panogps point to continue</p>
                <button onClick={() => dispatch(changePanoVisibility(false))}>Close</button>
              </div>} */}
          {!infoVisibility && <div className='mobile-logo'>
            <a href='https://www.anadolu.edu.tr/' target='blank'>
              <img src='customize-icons/logo_mobil.png' alt='anadolu' />
            </a>
          </div>}
        </div>
      </div>}
      <Share onClose={() => dispatch(setButtonState(ButtonState.DEFAULT))} />
    </>
  );
}



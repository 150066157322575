import './Info.scss';
import { useDispatch } from 'react-redux';
import { changeInfoVisibility } from '../../../state/features/infoSlice';
import { useAppSelector } from '../../../state/hooks';
import { RootState } from '../../../state/store';
import { useTranslation } from 'react-i18next';
import { changePanoVisibility } from '../../../state/features/panoramaSlice';
import { useEffect } from 'react';
import { setFeatureExtent } from '../../../state/features/selectedFeatureSlice';
import { SecondaryButton } from '../buttons/secondary-button/SecondaryButton';
import { useCurrentWidth } from '../resize/Resize';

export const Info = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const width = useCurrentWidth();
    const infoVisibility = useAppSelector((state: RootState) => state.info.visible);
    const panoramaVisibility = useAppSelector((state: RootState) => state.panorama.visible);
    const selectedFeature: any = useAppSelector((state: RootState) => state.info.value);
    const unit2Names: any = useAppSelector((state: RootState) => state.info.unit2Names);
    const language: any = useAppSelector((state: RootState) => state.language.value);
    const token: any = useAppSelector((state: RootState) => state.login.token);
    const quickSearch: any = useAppSelector((state: RootState) => state.search.searchList);

    useEffect(() => {
        trigger();
    }, [quickSearch])

    useEffect(() => {
    }, [language])

    /**
     * İnfo panelinden panoramanın açılmasını kontrol eder.
     */
    const openPanorama = () => {
        dispatch(changePanoVisibility(true))
    }

    /**
     * İnfo panelin yükseklik boyutunun değişimini dinliyoruz.
     */
    const trigger = () => {
        if (width <= 767) {
            let infoPanelElement: any = document.getElementById("info-panel-id");
            if (infoPanelElement) {
                let infoPanelSize = infoPanelElement.style.height
                infoPanelElement.style.height = '11vh'
                if (infoPanelSize === '11vh') {
                    infoPanelElement.style.height = '80vh'
                }
                // else if (quickSearch.length > 0) {
                //     infoPanelElement.style.height = '11vh'
                // }
            }
        }
    }

    /**
     * İnfo paneli kapatır.
     */
    const closeInfoPanel = () => {
        dispatch(setFeatureExtent(false))
        dispatch(changeInfoVisibility(false))
    }

    return (
        <>
            {!token && infoVisibility && <div className='info-panel' id='info-panel-id'>
                <span className='vr' onClick={trigger}></span>
                <i className='pi pi-times' onClick={closeInfoPanel} />

                <div className='p-text-left'>
                    {selectedFeature['adi' + (language === 'tr' ? '' : ('_' + language))]}
                </div>

                <div className='feature-information'>
                    <div className='description'>
                        {selectedFeature['desc' + (language === 'tr' ? '' : ('_' + language))]}
                    </div>

                    {!panoramaVisibility && <div className='info-pano-button'>
                        <SecondaryButton label={t('BUTTON.360° Campus')} onClick={openPanorama} />
                    </div>}

                    <div className='feature-details'>
                        {unit2Names.length > 0 && <div className='units'>
                            {unit2Names.map((unit: any) => { return unit.unit2 !== null && <li key={unit.unit2 && unit.unit2.id}>{unit.unit2 && unit.unit2['name' + (language === 'tr' ? '' : ('_' + language))]}</li> }
                            )}
                        </div>}
                        <br />
                        <div className='details'>
                            {selectedFeature.adres !== null && <div className='detail-header'>
                                {t('INFO.Address')}:
                            </div>
                            }
                            <div className='adress'>
                                {selectedFeature.adres}
                            </div>
                            {selectedFeature.web_site !== null && <div className='detail-header'>
                                {t('INFO.Web Site')}:
                            </div>}
                            <div className='web-site'>
                                <a href='https://www.anadolu.edu.tr/' target='blank'>{selectedFeature.web_site}</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>}
        </>
    )
}


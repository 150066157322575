import { createSlice } from '@reduxjs/toolkit';
interface InfoState {
  layer: any;
  visible: boolean;
  value: any;
  unit2Names: any;
  edit: any;
  selectedFeatureValues: any;
  featureId: string;
}

const initialState: InfoState = {
  layer: {},
  visible: false,
  value: {},
  unit2Names: [],
  edit: {},
  selectedFeatureValues: '',
  featureId: ''
};

export const infoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: {
    //info-panel visibility
    changeInfoVisibility: (state: any, action) => {
      state.visible = action.payload;
    },
    //objenin property ve değerleri (layer id vs. olmayan hali)
    setSelectedFeatureForInfo: (state: any, action) => {
      state.value = action.payload;
    },
    //featurın alt birimleri için
    setUnit2Names: (state: any, action) => {
      state.unit2Names = action.payload;
    },
    //objenin property ve değerleri (layer id vs. ulaşabilmek için)
    setSelectedFeatureForInfoEdit: (state: any, action) => {
      state.edit = action.payload
    },
    //datalar farklı geldiği için seçilen feature'ın id'si bazen buradan elde ediliyor.
    setSelectedFeatureId: (state: any, action) => {
      state.featureId = action.payload
      
    },
    //WorkerInfo.tsx'ten düzenleme yapabilmek için layerlar setleniyor
    setInfoLayer: (state: any, action) => {
      state.layer = action.payload;
    },
  },
});

export const { changeInfoVisibility, setSelectedFeatureForInfo, setSelectedFeatureForInfoEdit, setUnit2Names, setSelectedFeatureId, setInfoLayer } = infoSlice.actions;

export default infoSlice.reducer;

import './MenuContainer.scss';
import { useAppSelector } from '../../../state/hooks';
import { useDispatch } from 'react-redux';
import { setButtonState } from '../../../state/features/buttonStateSlice';
import { ButtonState } from '../../shared/button-state/ButtonState';
import MenuDetails from '../menu-details/MenuDetails';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../state/store';
import { useEffect } from 'react';

export default function MenuContainer() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const buttonVisibility = useAppSelector((state) => state.buttonState.value);
    const language: any = useAppSelector((state: RootState) => state.language.value);

    useEffect(() => {
    }, [language])
    

    const toggleCategoricalSearch = () => {
        if (buttonVisibility === ButtonState.MOBILE_CATEGORICAL_SEARCH) {
            dispatch(setButtonState(ButtonState.CONTAINER))
        }
        else {
            dispatch(setButtonState(ButtonState.MOBILE_CATEGORICAL_SEARCH))
        }
    }

    const toggleLanguage = () => {
        if (buttonVisibility === ButtonState.LANGUAGE) {
            dispatch(setButtonState(ButtonState.DEFAULT))
        }
        else {
            dispatch(setButtonState(ButtonState.LANGUAGE))
        }
    }

    const toggleShare = () => {
        if (buttonVisibility === ButtonState.MOBILE_SHARE) {
            dispatch(setButtonState(ButtonState.DEFAULT))
        }
        else {
            dispatch(setButtonState(ButtonState.MOBILE_SHARE))
        }
    }

    const toggleDrawTools = () => {
        if (buttonVisibility === ButtonState.MEASUREMENT) {
            dispatch(setButtonState(ButtonState.DEFAULT))
        }
        else {
            dispatch(setButtonState(ButtonState.MEASUREMENT))
        }
    }

    return (
        <div className='MenuContainer'>
            <i className='pi pi-times' onClick={() => dispatch(setButtonState(ButtonState.DEFAULT))} />
            <div className='menu-tools'>
                {/* <div className='menu' onClick={toggleBasemap}>
                    <span>{t('MOBILE.Basemap')}</span>
                    <i className='pi pi-chevron-right' />
                </div> */}
                <div className='menu' onClick={toggleCategoricalSearch}>
                    <span>{t('MOBILE.Categorical Search')}</span>
                    <i className='pi pi-chevron-right' />
                </div>
                <div className='menu' onClick={toggleDrawTools}>
                    <span>{t('MOBILE.Draw')}</span>
                    <i className='pi pi-chevron-right' />
                </div>
                <div className='menu' onClick={toggleLanguage}>
                    <span>{t('MOBILE.Language')}</span>
                    <i className='pi pi-chevron-right' />
                </div>
                <div className='menu' onClick={toggleShare}>
                    <span>{t('MOBILE.Share')}</span>
                    <i className='pi pi-chevron-right' />
                </div>
            </div>
            <div className='menu-details' style={{ visibility: buttonVisibility === ButtonState.CONTAINER ? 'visible' : 'hidden' }}>
                <MenuDetails />
            </div>
        </div >
    )
}

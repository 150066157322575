import './PanoramaContainer.scss';
import { useEffect, useState } from 'react';
import { DrawPlugin, PanoGL, ScalablePlugin, SoftTextPlugin, AnkaDraw, PanoromicController } from './easy-pano-imports';
import { useAppSelector } from '../../../state/hooks';
import { useDispatch } from 'react-redux';
import { changePanoVisibility, setCoordPanoToMap, setViewAngle } from '../../../state/features/panoramaSlice';
import { PrimaryButton } from '../../shared/buttons/primary-button/PrimaryButton';
import { ButtonState } from '../../shared/button-state/ButtonState';
import { setPanoDrawState } from '../../../state/features/panoDrawStateSlice';
import { setButtonState } from '../../../state/features/buttonStateSlice';
import { transform } from 'ol/proj';
import { useCurrentWidth } from '../../shared/resize/Resize';
const { REACT_APP_BASE_URL } = process.env

const ankapanapiOptions = {
    content: 'panodiv',
    aroundService: `${REACT_APP_BASE_URL}pano/around/`,
    imageService: `${REACT_APP_BASE_URL}pano/img/`,
    tileService: `${REACT_APP_BASE_URL}pano/tile/`,
    pointCloudURL:  `${REACT_APP_BASE_URL}pano/`
};

export enum PanoDrawStateType {
    POINT = 'POINT',
    LINESTRING = 'LINESTRING',
    POLYGON = 'POLYGON',
    CLEAR = 'CLEAR',
    FINISH = 'FINISH',
    DEFAULT = 'DEFAULT',
    NONE = 'NONE'
}

export default function PanoramaContainer() {
    const dispatch = useDispatch();
    const width = useCurrentWidth();
    const [pano, setPano] = useState<any>();
    const [draw, setDraw] = useState<any>();
    const [panoBrightness, setPanoBrightness] = useState<any>(25);
    const panoDrawState = useAppSelector((state) => state.panoDrawState.value);
    const buttonVisibility = useAppSelector((state) => state.buttonState.value);
    const mapToPanoCoor = useAppSelector((state) => state.panorama.mapCoord);

    let panogl: any
    let softtext = window as any
    let scalable = window as any

    useEffect(() => {
        panogl = new PanoGL(ankapanapiOptions);
        setPano(panogl);
        (window as any).pano = panogl;

        softtext = new SoftTextPlugin();
        panogl.setPlugin(softtext);

        scalable = new ScalablePlugin();
        panogl.setPlugin(scalable);

        const draw = new DrawPlugin();
        panogl.setPlugin(draw);
        setDraw(draw)

        panogl.gotoLocation(39.79025747, 30.505586612); // EPSG:4326 and lat,lon
        panogl.getTilePlugin()._zoomLevels = [0.9, 0.6, 0.3]
        panogl.addEvent(PanoGL.SETUP_COMPLETE, null, onSetupComplete);
        panogl.addEvent(PanoGL.LOCATION_CHANGE, null, onLocationChange);
        panogl.addEvent(PanoGL.VIEW_ANGLE_CHANGE, null, onViewAngleChange);
        panogl.addEvent(PanoGL.MOUSE_MOVE_ON_GROUND, null, onCursorMoveGround);
        draw.addEvent(AnkaDraw.DrawPlugin.STATUS_CHANGE, null, onDrawStatusChange);
        panogl.start();



        panogl.setLabelVisibility(false);

        const interval = setInterval(() => {
            if (PanoromicController) {
                PanoromicController.MouseWheelWay = -1;
                clearInterval(interval);
            }
        }, 100);

    }, [])

    useEffect(() => {
        if (panoDrawState === PanoDrawStateType.POLYGON) {
            startGroundPolygon();
        }
        else if (panoDrawState === PanoDrawStateType.LINESTRING) {
            startGroundLine();
        }
        else if (panoDrawState === PanoDrawStateType.POINT) {
            startGroundPoint();
        }
        else if (panoDrawState === PanoDrawStateType.CLEAR) {
            clearAll()
        }
    }, [panoDrawState])

    useEffect(() => {
        if (pano && mapToPanoCoor) {
            const translated = transform(mapToPanoCoor, 'EPSG:3857', 'EPSG:4326')
            pano.gotoLocation(translated[1], translated[0])
        }
    }, [mapToPanoCoor])

    /**
     * Panoramaya tıklandığında haritanın da hareket etmesi için.
     */
    function onLocationChange(event: any) {
        dispatch(setCoordPanoToMap({ lat: event.lat, lon: event.lon }))
    }

    function onSetupComplete(event: any) {
        if (event && event.target) {
            event.target.controller.setRotationY(60 / 180 * 3.14)
        }
    }

    /**
     * Panorama açısı için.
     */
    function onViewAngleChange(e: any) {
        let fov = panogl.controller.getFov();
        dispatch(setViewAngle({ rotationX: e.rotationX, rotationY: e.rotationY, fov: fov }))
    }

    /**
     * Panorama üzerinde imlecin hareketini dinleyen fonksiyon.
     */
    function onCursorMoveGround(event: any) {
    }

    /**
     * Alan ölçümü için.
     */
    function startGroundPolygon() {
        draw.startPolygon();
    }

    /**
     * Çizgi ölçümü için.
     */
    function startGroundLine() {
        draw.startLine();
    }

    /**
     * Konum ölçümü için.
     * 
     */
    function startGroundPoint() {
        draw.startPoint();
    }

    /**
     * Ölçümleri temizlemek için.
     */
    function clearAll() {
        pano.getScalable()?.getMainSketchLayer().clearAll();
    }

    /**
     * Ölçüm durumlarını dinleyen fonksiyon. 
     */
    function onDrawStatusChange(event: any) {
        if (event.status === 'FINISHED' && event.isSuccessful) {
            dispatch(setPanoDrawState(PanoDrawStateType.FINISH));
        }
    }

    /**
     * Haritaya tıklandığında panoramanın da hareket etmesi için.
     */
    // function gotoLocation(lot: any, lan: any) {
    //     // console.log(event);
    // }

    useEffect(() => {
        document.addEventListener('dblclick', doubleClick)
    }, [])

    /**
     * Çift tıklandığında parlaklık ayarı ilk geldiği değere dönüyor.
     */
    const doubleClick = (event: any) => {
        event.preventDefault();
        setPanoBrightness(25);
        (window as any).pano.setBrightness(1);
    }

    /**
     * Panorama parlaklık ayarı.
     */
    function setBrightnessValue(value: any) {
        const brightness = (4 * value) / 100;
        (window as any).pano.setBrightness(brightness);
        setPanoBrightness(value)
    }

    /**
     * Bu fonksiyon panorama ölçüm buton grubunun açılıp kapanmasını kontrol eder.
     */
    const togglePanoDrawTools = () => {
        if (buttonVisibility === ButtonState.PANORAMA_MEASUREMENT) {
            dispatch(setButtonState(ButtonState.DEFAULT));
            dispatch(setPanoDrawState(PanoDrawStateType.NONE));
        }
        else {
            dispatch(setButtonState(ButtonState.PANORAMA_MEASUREMENT));
        }
    }

    return (
        <div id='panodiv' className='pano' tabIndex={0}>

            {width > 767 && <div className='pano-draw-button'>
                <PrimaryButton
                    svg={buttonVisibility === ButtonState.PANORAMA_MEASUREMENT ? 'customize-icons/measurement-white.svg' : 'customize-icons/measurement.svg'}
                    onClick={togglePanoDrawTools}
                    active={buttonVisibility === ButtonState.PANORAMA_MEASUREMENT}>
                </PrimaryButton>
            </div>}


            {width > 767 && buttonVisibility === ButtonState.PANORAMA_MEASUREMENT && <div className='pano-draw-button-group'>
                <div className='button-group'>
                    <PrimaryButton
                        className='mobile-draw-button'
                        svg='customize-icons/clear.svg'
                        moreStyle={panoDrawState === PanoDrawStateType.DEFAULT ? 'passive-draw-button' : ''}
                        onClick={() => dispatch(setPanoDrawState(PanoDrawStateType.CLEAR))}
                    ></PrimaryButton>
                </div>
                <div className='button-group'>
                    <PrimaryButton
                        className='mobile-draw-button'
                        svg='customize-icons/area.svg'
                        moreStyle={panoDrawState === PanoDrawStateType.POLYGON ? 'active-draw-button' : ''}
                        onClick={() => dispatch(setPanoDrawState(PanoDrawStateType.POLYGON))}
                    >
                    </PrimaryButton>
                </div>
                <div className='button-group'>
                    <PrimaryButton
                        className='mobile-draw-button'
                        svg='customize-icons/line.svg'
                        moreStyle={panoDrawState === PanoDrawStateType.LINESTRING ? 'active-draw-button' : ''}
                        onClick={() => dispatch(setPanoDrawState(PanoDrawStateType.LINESTRING))}
                    >
                    </PrimaryButton>
                </div>
                <div className='button-group'>
                    <PrimaryButton
                        className='mobile-draw-button'
                        svg='customize-icons/point.svg'
                        moreStyle={panoDrawState === PanoDrawStateType.POINT ? 'active-draw-button' : ''}
                        onClick={() => dispatch(setPanoDrawState(PanoDrawStateType.POINT))}
                    >
                    </PrimaryButton>
                </div>
            </div>
            }

            <input type="range" className='pano-slider' value={panoBrightness} onInput={(e: any) => setBrightnessValue(e.target.value)} />
            <i className='pi pi-times' onClick={() => dispatch(changePanoVisibility(false))} />

        </div>
    )
}
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { transform } from 'ol/proj';
import { Icon, Style } from 'ol/style';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCoordMapToPano, setVisibilityOnMobile } from '../../../../../state/features/panoramaSlice';
import { useAppSelector } from '../../../../../state/hooks';
import { MapContext } from '../MapContext';

export default function PanoLook() {
    const panoCoord: any = useAppSelector(state => state.panorama.panoCoord)
    const panoramaVisibility = useAppSelector((state) => state.panorama.visible)
    const angle = useAppSelector((state) => state.panorama.angle)
    // const [panoLookCoordinate, setPanoLookCoordinate] = useState([3395306.1932237986, 4836089.964055118])
    const [context, setContext] = useState<CanvasRenderingContext2D | undefined>(undefined)
    const [feature, setFeature] = useState<any>(null)
    const [canvasValue, setCanvasValue] = useState<any>(null)

    const map = useContext(MapContext);
    const dispatch = useDispatch();
    let canvas: HTMLCanvasElement;
    let radius: number = 50;
    let layer: VectorLayer<any>;
    let f: Feature<Geometry>;
    let ctx: CanvasRenderingContext2D;
    let color: string = '#ff0000';
    // let color: string = '#6d4a9f';
    let style: Style;

    useEffect(() => {
        createCanvas();
        layer = createLayer();
        map.addLayer(layer)
        createStyle();
        createFeature();

        map.on('click', mapClick);

        return () => {
            map.removeLayer(layer)
        }
    }, [])

    useEffect(() => {
        if (canvasValue) {
            createCanvasContent(angle.fov);
        }
    }, [canvasValue])

    useEffect(() => {
        if (panoCoord.lat && feature) {
            const coord = [panoCoord.lon, panoCoord.lat];
            const locationTransform = transform(coord, 'EPSG:4326', 'EPSG:3857');
            dispatch(setCoordMapToPano(locationTransform))
            feature.setGeometry(new Point(locationTransform))
        }
    }, [panoCoord])

    useEffect(() => {
        if (angle.rotationY && feature) {
            const radius = angle.rotationY * (Math.PI / 180);
            if (feature) {
                feature!.getStyle().getImage().setRotation(radius);
                feature.notify();
            }
        }

        if (canvasValue && context) {
            context.clearRect(0, 0, canvasValue.width, canvasValue.height);
            createCanvasContent(angle.fov);
            feature.notify();
        }
    }, [angle])

    const mapClick = (event: any) => {
        const clickedCoordinate = event.coordinate;
        if (clickedCoordinate) {
            // map.getView().setCenter(clickedCoordinate);
            dispatch(setCoordMapToPano(clickedCoordinate));
            dispatch(setVisibilityOnMobile(true));
        }

    }

    const createCanvas = () => {
        canvas = document.createElement('canvas');
        canvas.width = radius * 2;
        canvas.height = radius * 2;
        setCanvasValue(canvas);
    }

    const createCanvasContent = (fov: number) => {
        const degree = fov;
        const startDegree = 270 - (degree / 2);
        const endDegree = startDegree + degree;

        ctx = canvasValue.getContext('2d')!;
        ctx.beginPath();
        ctx.moveTo(radius, radius);
        ctx.arc(radius, radius, radius, startDegree * (Math.PI / 180), endDegree * (Math.PI / 180));
        ctx.closePath();
        ctx.fillStyle = color;
        ctx.fill();
        setContext(ctx);
    }

    const createStyle = () => {
        const image = new Icon({
            img: canvas,
            rotateWithView: true,
            imgSize: [radius * 2, radius * 2],
            opacity: 0.5
        });
        style = new Style({
            zIndex: 1000000,
            image
        });
    }

    const createLayer = () => {
        return new VectorLayer({ source: new VectorSource(), zIndex: 200000000 });
    }

    const createFeature = () => {
        f = new Feature();
        f.setStyle(style);
        const coord = [panoCoord.lon, panoCoord.lat];
        const locationTransform = transform(coord, 'EPSG:4326', 'EPSG:3857');
        const point = new Point(locationTransform);
        f.setGeometry(point);
        layer.getSource().addFeature(f);
        setFeature(f)
    }

    return (
        <>
            <div className='PanoLook' style={{ visibility: panoramaVisibility ? 'visible' : 'hidden' }}></div>
        </>
    )
}

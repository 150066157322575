import { QuickSearch } from '../../quick-search/QuickSearch';
import Hamburger from '../hamburger/Hamburger';
import './Header.scss';

export default function Header() {
  return (
    <div className='Header'>
      <Hamburger />
      <QuickSearch />
    </div>
  )
}

import axios from 'axios';
import { ISearchResult } from '../model/search';

const {REACT_APP_BASE_URL} = process.env

/**
 * Hızlı arama yapıldığında arama sonuçlarını girilen harflere göre filtreleyerek getirir.
 */
async function getSearchResults(key: string) {
    return axios.get<ISearchResult[]>(REACT_APP_BASE_URL + 'rest/v1/search?search=' + key)
}

export const SearchRestApi = {
    getSearchResults
}
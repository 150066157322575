import './Hamburger.scss';
import { useDispatch } from 'react-redux';
import { setButtonState } from '../../../state/features/buttonStateSlice';
import { useAppSelector } from '../../../state/hooks';
import { ButtonState } from '../../shared/button-state/ButtonState';
import MenuContainer from '../menu-container/MenuContainer';
import { changeInfoVisibility } from '../../../state/features/infoSlice';
import { setFeatureExtent } from '../../../state/features/selectedFeatureSlice';
import { RootState } from '../../../state/store';
import { useEffect } from 'react';

export default function Hamburger() {
    const dispatch = useDispatch();
    const buttonVisibility = useAppSelector((state) => state.buttonState.value)
    const language: any = useAppSelector((state: RootState) => state.language.value);

    useEffect(() => {
    }, [language])

    /**
     * Mobildeki menü butonu burada kontrol ediyoruz.
     */
    const toggleMenu = () => {
        if (buttonVisibility === ButtonState.HAMBURGER) {
            dispatch(setButtonState(ButtonState.DEFAULT))
        } else {
            dispatch(setButtonState(ButtonState.HAMBURGER))
            dispatch(changeInfoVisibility(false))
            dispatch(setFeatureExtent(false))
        }
    }

    return (
        <>
            <div className='Hamburger' onClick={toggleMenu}>
                <i className='pi pi-bars' />
            </div>
            <div style={{ visibility: buttonVisibility === ButtonState.HAMBURGER ? 'visible' : 'hidden' }}>
                <MenuContainer />
            </div>
        </>
    )
}

import { useContext, useEffect } from 'react'
import { MapContext } from './MapContext';
import View from 'ol/View';
import { useAppSelector } from '../../../../state/hooks';

export const ChangeExtent = () => {
    const map = useContext(MapContext);

    const centerX = useAppSelector((state)=> state.changeExtent.centerX)
    const centerY = useAppSelector((state)=> state.changeExtent.centerY)
    const zoom = useAppSelector((state)=> state.changeExtent.zoom)

    useEffect(() => {
        map.updateSize();
    }, [])
    map.setView(new View({
        projection: 'EPSG:3857',
        center: [centerX, centerY],
        zoom: zoom,
        maxZoom: 19,
        extent: [2817774.6107,4265797.6745,5102324.5121,5278435.4253]
      }));
  
      map.updateSize();


    return (
        <></>
    )
}

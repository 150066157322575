import { useContext, useEffect } from 'react'
import { MapContext } from '../MapContext'
import Draw, { DrawEvent } from 'ol/interaction/Draw';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { transform } from 'ol/proj';
import Text from 'ol/style/Text';
import { setIsDrawing } from '../../../../../state/features/drawStateSlice';
import { useDispatch } from 'react-redux';
interface Props {
    source: any;
}

export const DrawPoint = (props: Props) => {
    const map = useContext(MapContext);
    const dispatch = useDispatch()

    useEffect(() => {
        let draw = new Draw({
            source: props.source,
            stopClick: true,
            type: 'Point',
            style: new Style({
                fill: new Fill({
                    color: '#A52125',
                }),
                image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({
                        color: '#A52125',
                    }),
                }),

            }),
        });
        map.addInteraction(draw);
        dispatch(setIsDrawing(true)); 

        draw.on('drawend', (event) => {
            setTextAfterDrawEnd(event)
        });

        return () => {
            map.removeInteraction(draw);
            dispatch(setIsDrawing(false));
        }

    }, [map])

    /**
      * Çizim bittikten sonra ölçüm değerini çizilen nesnenin üzerine yazar. 
      */
    const setTextAfterDrawEnd = (event: DrawEvent) => {
        let coordinates: any = event.feature?.getGeometry().getCoordinates();
        coordinates = transform(coordinates, 'EPSG:3857', 'EPSG:4326');

        coordinates[0] = coordinates[0].toFixed(6)
        coordinates[1] = coordinates[1].toFixed(6)


        event.feature.setStyle(new Style({
            fill: new Fill({
                color: '#A52125',
            }),
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: '#A52125',
                }),
            }),
            text: new Text({
                text: `${coordinates}`,
                font: "bold 15px 'Open Sans', 'Arial Unicode MS', 'sans-serif'",
                placement: 'point',
                offsetY: -15,
                fill: new Fill({
                    color: 'white',
                }),
                stroke: new Stroke({
                    color: 'black',
                    width: 3,
                }),
            }),
        }));
    }

    return (
        <>
        </>
    )
}

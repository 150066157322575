import { createSlice } from '@reduxjs/toolkit';


//interface duzelt
const initialState: any = {
    feature : {},
    mainHeaderFeature : {}
}

export const cityFeatureSlice = createSlice({
    name: 'city',
    initialState,
    reducers: {
        setCityFeature: (state: any, action) => {
            state.feature = action.payload;
        },
        setMainHeaderFeature: (state: any, action) => {
            state.mainHeaderFeature = action.payload;
        }
    },
})

export const { setCityFeature,setMainHeaderFeature } = cityFeatureSlice.actions

export default cityFeatureSlice.reducer
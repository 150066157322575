import { createSlice } from '@reduxjs/toolkit';
import { ICustomizeModel } from '../../util/model/customize';

interface CustomizeState {
    value: ICustomizeModel
}
const initialState: CustomizeState = {
    value: {
        id: 0,
        logo: '',
        primarycolor: '',
        secondarycolor: '',
        startextent: '' || null,
    }
}

export const customizeSlice = createSlice({
    name: 'customize',
    initialState,
    reducers: {
        getCustomize: (state: any, action) => {
            state.value = action.payload;
        }
    }
})

export const { getCustomize } = customizeSlice.actions

export default customizeSlice.reducer
import { Tile } from 'ol/layer';
import { TileWMS } from 'ol/source';
import { PropsWithChildren, useContext, useEffect, useRef } from 'react'
import { MapContext } from '../MapContext'

interface IProps {
    url: string;
    layername: any;
    serviceId: number;
    tag:string;
}

export const WmsLayer = (props: PropsWithChildren<IProps>) => {
    const token = localStorage.getItem('token');
    const map = useContext(MapContext)
    const t = useRef<Tile<any> | null>(null);
    
    useEffect(() => {
        const tileLayer = new Tile({
            source: new TileWMS({
                params: {
                    layers: props.layername
                },
                url: ((props.layername!=='aof_alan_usr')||(props.layername!=='yapilar_usr')) && token ? props.url +`?token=${token}` : props.url
            }),
            zIndex: 100
            
        })
        tileLayer.set('serviceId', props.serviceId);
        tileLayer.set('tag', props.tag);
        tileLayer.setVisible(props.layername.length > 0)
        tileLayer.getVisible()
        t.current = tileLayer;
        map.addLayer(tileLayer);
        return () => { map.removeLayer(tileLayer) }
    }, [])


    useEffect(() => {
        const tileLayer = t.current!;
        tileLayer.setVisible(props.layername.length > 0);
        tileLayer.getSource().updateParams({
            layers: props.layername
        })
    }, [props.layername, props.serviceId])

    return null;
}

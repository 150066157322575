import { createSlice } from '@reduxjs/toolkit';
import { ButtonState } from '../../components/shared/button-state/ButtonState';

const initialState: any = {
    value: ButtonState.DEFAULT
}

export const buttonStateSlice = createSlice({
    name: 'buttonState',
    initialState,
    reducers: {
        setButtonState: (state: any, action) => {
            state.value = action.payload;
        }
    }
})

export const { setButtonState } = buttonStateSlice.actions

export default buttonStateSlice.reducer
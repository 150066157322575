import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: ''
}
export const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setLanguageSlice: (state: any, action) => {
            state.value = action.payload;
        }
    }
})

export const { setLanguageSlice } = languageSlice.actions;

export default languageSlice.reducer;
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    visible: false,
}

export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        changeSidebarVisibility: (state: any, action) => {
            state.visible = action.payload;
        },
    }
})

export const { changeSidebarVisibility } = sidebarSlice.actions;

export default sidebarSlice.reducer;
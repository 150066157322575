import { useContext, useEffect, useState } from 'react'
import { MapContext } from '../MapContext'
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import { DrawPoint } from './DrawPoint';
import { useAppSelector } from '../../../../../state/hooks';
import { DrawStateType } from '../../../../layouts/toolbar/draw-tools/DrawTools';
import { DrawLinestring } from './DrawLinestring';
import { ClearAll } from './ClearAll';
import { DrawPolygon } from './DrawPolygon';

export const Draw = () => {

  const map = useContext(MapContext);
  const drawState = useAppSelector(state => state.drawState.value);
  const [vector, setVector] = useState<VectorLayer<any>>(new VectorLayer())
  const [source, setSource] = useState<VectorSource<any>>(new VectorSource())
  
  useEffect(() => {
    const source = new VectorSource({ wrapX: false });
    const vector = new VectorLayer({
      source: source,
      zIndex: 1000,
      style: new Style({
        fill: new Fill({
          color: 'A52125',
        }),
        stroke: new Stroke({
          color: '#A52125',
          width: 3,
        }),
      }),
    });

    map.addLayer(vector);
    setVector(vector)
    setSource(source)

    return () => {
      map.removeLayer(vector)
    }
  }, [])

  return (
    <>
      {drawState === DrawStateType.POINT && <DrawPoint source={source}></DrawPoint>}
      {drawState === DrawStateType.LINESTRING && <DrawLinestring source={source}></DrawLinestring>}
      {drawState === DrawStateType.POLYGON && <DrawPolygon source={source}></DrawPolygon>}
      {drawState === DrawStateType.CLEAR && <ClearAll source={source}></ClearAll>}
    </>
  )
}

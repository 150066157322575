import axios from 'axios';

const { REACT_APP_BASE_URL } = process.env

async function getCityGeometries(cityId: number) {
    const url = REACT_APP_BASE_URL + `rest/v1/geoserver/anadolu/ows?service=WFS&version=1.0.0&request=GetFeature&outputFormat=application/json&srsname=EPSG:3857&typeName=iller&cql_filter=il_id=`
    return axios.get(url + cityId);
}

export const CityGeomRestApi = {
    getCityGeometries
}


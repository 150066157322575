import { createSlice } from '@reduxjs/toolkit';
const initialState: any = {
    token: localStorage.getItem('token'),
}
export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setToken: (state: any, action) => {
            state.token = action.payload;
        },
    }
})
export const { setToken } = loginSlice.actions
export default loginSlice.reducer
import './ModelButtons.scss';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../state/hooks';
import { RootState } from '../../../state/store';
import { SecondaryButton } from '../../shared/buttons/secondary-button/SecondaryButton';
import { useDispatch } from 'react-redux';
import { changePanoVisibility } from '../../../state/features/panoramaSlice';


export default function ModelButtons() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const panoramaVisibility = useAppSelector((state: RootState) => state.panorama.visible);
    const infoVisibility = useAppSelector((state: RootState) => state.info.visible);

    /**
      * Panoramanın görünürlüğünü kontrol eder.
      */
    const openClosePanorama = () => {
        dispatch(changePanoVisibility(true))
        if (panoramaVisibility) {
            dispatch(changePanoVisibility(false))
        }
    }
    return (
        <div className='ModelButtons'>
            {!infoVisibility && !panoramaVisibility && <SecondaryButton moreStyle='degrees-button' svg='customize-icons/360-degrees.png' onClick={openClosePanorama} />}
            {!panoramaVisibility && !infoVisibility && <SecondaryButton moreStyle='three-d-button' svg='customize-icons/3d.svg' />}
        </div>
    )
}

import axios from 'axios';

const { REACT_APP_BASE_URL } = process.env

const url = REACT_APP_BASE_URL + `rest/v1/geoserver/anadolu/ows?service=WFS&version=1.0.0&request=GetFeature&outputFormat=application/json&srsname=EPSG:3857&typeName=yapilar_usr`;
const url2 = REACT_APP_BASE_URL + `rest/v1/geoserver/anadolu/ows?service=WFS&version=1.0.0&request=GetFeature&outputFormat=application/json&srsname=EPSG:3857&typeName=aof_alan_usr`;

async function getFeatures() {
    return axios.get(url);
}

async function getAOB() {
    return axios.get(url2);
}

export const CategoricalSearchRestApi = {
    getFeatures,
    getAOB
}



import React, { useState, useMemo } from 'react';

type CB = null | ((a: string) => void);

interface FunctionCallback {
    callbackFunction: CB | null;
    setCallbackFunction: (cb: CB) => void;

}
export const DigiCallbackContext = React.createContext<FunctionCallback>(null!);

/* 
 * Bu context sayesinde sayısallaştırma yaparken feature i ekledikten sonra callback yaparak feature_id yi dönüyoruz. Böylece
 * forma attachment eklenebiliyor. 
 *
*/

export const DigiCallbackContextProvider: React.FC = ({ children }) => {
    const [callbackFunction, setCallbackFunction] = useState<{ cb: CB } | null>(null)
    const value = useMemo(() => ({
        callbackFunction: callbackFunction?.cb || null,
        setCallbackFunction: (cb: CB) => {
            setCallbackFunction({ cb: cb })
        }
    }), [callbackFunction, setCallbackFunction])

    // const value = {
    //     callbackFunction, setCallbackFunction
    // }
    return (
        <DigiCallbackContext.Provider value={value}>
            {children}
        </DigiCallbackContext.Provider>
    )
}

import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
    value: []
}

export const attachmentSlice = createSlice({
    name: 'attachment',
    initialState,
    reducers: {
        setAttachment: (state: any, action) => {
            state.value = action.payload;
        }
    }
})

export const { setAttachment } = attachmentSlice.actions

export default attachmentSlice.reducer
import './Basemap.scss'
import { useDispatch } from 'react-redux';
import { changeVisibility } from '../../state/features/basemapSlice';
import { RootState } from '../../state/store';
import { useAppSelector } from '../../state/hooks';
import { CustomMap } from './CustomMap/CustomMap';
import { useTranslation } from 'react-i18next';
import { setButtonState } from '../../state/features/buttonStateSlice';
import { ButtonState } from '../shared/button-state/ButtonState';

export const Basemap = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const basemaps = useAppSelector((state: RootState) => state.basemap.value);
    const token = useAppSelector((state: RootState) => state.login.token);

    const changeBasemapVisibility = (index: number, visibility: boolean) => {
        dispatch(changeVisibility({ index, visibility }))
    }

    return (
        <div>
            <div className={`${!token ? 'basemap-container2' : 'basemap-container' }`}>
                <div className='mobile-header'>
                    <i className='pi pi-arrow-left' onClick={() => dispatch(setButtonState(ButtonState.HAMBURGER))} />
                    <span>{t('MOBILE.Basemap')}</span>
                </div>
                <div className='map-group'>
                    <h4 className='header'>{t('BASEMAP.Standart Maps')}</h4>
                    <div className='basemap-group'>
                        {basemaps.length > 0 && basemaps.map((basemap: any, index: number) =>
                            <div className='basemap' key={index} onClick={() => changeBasemapVisibility(index, true)}>
                                <img className='image-box' src={basemap.icon} alt='googleStreet' />
                                <p className='title'>{basemap.title}</p>
                            </div>
                        )}
                    </div>
                </div>
                <div className='map-group'>
                    <h4 className='header'>{t('BASEMAP.Custom Maps')}</h4>
                    <div className='layer'>
                        <CustomMap />
                    </div>
                </div>
            </div>
        </div>
    )
}

import React from 'react';
import { Dialog, DialogTemplateType } from 'primereact/dialog';

interface IProps {
    header?: string,
    footer?: DialogTemplateType,
    onHide?: () => void,
    visible?: boolean
    className?: string,
    content?: any,
}
export const Popup: React.FC<IProps> = (props) => {

    const onHide = () => {
        if (typeof props.onHide === 'function') {
            props.onHide();
        }
    }

    return (
        <Dialog
            header={props.header}
            visible={props.visible}
            footer={props.footer}
            onHide={onHide}
        >
            {props.content}
        </Dialog>
    )
}

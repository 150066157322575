import { createSlice } from '@reduxjs/toolkit';
import { PanoDrawStateType } from '../../components/containers/panorama-container/PanoramaContainer';

const initialState: any = {
    value: {
        panoDrawStateType: PanoDrawStateType.NONE,
    }
}

export const panoDrawStateSlice = createSlice({
    name: 'pano-draw',
    initialState,
    reducers: {
        setPanoDrawState: (state: any, action) => {
            state.value = action.payload;
        }
    }
})

export const { setPanoDrawState } = panoDrawStateSlice.actions

export default panoDrawStateSlice.reducer
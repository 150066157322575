import './WorkerInfo.scss';
import { useDispatch } from 'react-redux';
import { changeInfoVisibility, setInfoLayer } from '../../../state/features/infoSlice';
import { useAppSelector } from '../../../state/hooks';
import { RootState } from '../../../state/store';
import { useEffect, useState } from 'react';
import { Image } from 'primereact/image';
import { saveAs } from 'file-saver';
import { SecondaryButton } from '../buttons/secondary-button/SecondaryButton';
import { useTranslation } from 'react-i18next';
import { changePanoVisibility, setLayerNameForPano } from '../../../state/features/panoramaSlice';
import { changeEditPopupFromInfoVisibility } from '../../../state/features/popupSlice';
import { PrimaryButton } from '../buttons/primary-button/PrimaryButton';
import { setDataColumns, setHeader, setProperties } from '../../../state/features/editObjectSlice';
import { setFeatureExtent } from '../../../state/features/selectedFeatureSlice';
import { AttachmentRestApi } from '../../../util/restapi/attachment';
import { IAttachment } from '../../../util/model/attachment';
import { DigitizationRestApi } from '../../../util/restapi/digitization';

export const WorkerInfo = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const infoVisibility = useAppSelector((state: RootState) => state.info.visible);
    const panoramaVisibility = useAppSelector((state: RootState) => state.panorama.visible);
    const selectedFeature: any = useAppSelector((state: RootState) => state.info.value);
    const selectedFeatureForInfoEdit: any = useAppSelector((state: RootState) => state.info.edit);
    const selectedFeatureId: any = useAppSelector((state) => state.info.featureId);
    const unit2Names: any = useAppSelector((state: RootState) => state.info.unit2Names);
    const language: any = useAppSelector((state: RootState) => state.language.value);
    const services = useAppSelector((state) => state.service.services);
    const token: any = useAppSelector((state: RootState) => state.login.token);
    const [dataColumnsArray, setDataColumnsArray] = useState<any>([]);
    const [editFieldsValues, setEditFieldsValues] = useState<any>([]);
    const [layerName, setLayerName] = useState('');
    const [files, setFiles] = useState<any>();
    const [image, setImage] = useState<any>()
    const [otherFile, setOtherFile] = useState<any>()
    const [propertiesForDomain, setPropertiesForDomain] = useState<any>(null)
    const [domainsOfCity, setDomainsOfCity] = useState<any>([])
    const [domainsOfDistincts, setDomainsOfDistincts] = useState<any>([])
    const [domainsOfNeighborhoods, setDomainsOfNeighborhoods] = useState<any>([])
    let cityProperties: any = []
    let distinctsOfCity: any = []
    let neighborhoods: any = []

    useEffect(() => {
        setCities();
    }, [])

    useEffect(() => {
    }, [language])


    useEffect(() => {
    }, [domainsOfDistincts, domainsOfNeighborhoods])

    useEffect(() => {
        services.map((service) => {
            const findedLayer = service.layers.find((layer) => layer.id === selectedFeatureForInfoEdit.layer_id)
            if (findedLayer) {
                dispatch(setInfoLayer(findedLayer))
                if (findedLayer && findedLayer.fields) {
                    setLayerName(findedLayer.name)
                    dispatch(setLayerNameForPano(findedLayer.name))
                    const dataColumns: any[] = []
                    const propertiesForEditObj: any[] = []
                    findedLayer.fields.map((obj: any) => {
                        if (obj.has_domain) {
                            dataColumns.push({ name: obj.name, alias: obj.alias, domain: true, type:obj.type })
                            propertiesForEditObj.push(obj) // domain icin
                        }
                        else {
                            dataColumns.push({ name: obj.name, alias: obj.alias, domain: false, type:obj.type })
                        }
                    })

                    setDataColumnsArray(dataColumns);
                    setPropertiesForDomain(propertiesForEditObj)


                }
            }
        })
    }, [selectedFeatureForInfoEdit]);

    useEffect(() => {

        setImage(null)
        setOtherFile(null)

        if(selectedFeatureForInfoEdit.layer_id && selectedFeatureId){
            AttachmentRestApi.getAttachmentInfo(selectedFeatureForInfoEdit.layer_id, selectedFeatureId).then((res: any) => {
                const response = res.data
    
                const pattern = /[\/.](gif|jpg|jpeg|tiff|png|svg)$/;
                response.forEach((item: any) => {
                    const isValidImage = pattern.test(item.filename.toLowerCase());
                    const url2 = generateLink(item)
                    item.imageUrl = isValidImage ? url2 : null;
                    item.downloadUrl = url2 + `&download=true`;
                    item.isValidImage = isValidImage;
    
                    const dataTypeImage = response.filter((type: any) => {
                        return type.file_type === 'image/png'
                    })
                    const dataTypeOthers = response.filter((type: any) => {
                        return type.file_type !== 'image/png'
                    })
                    setImage(dataTypeImage)
                    setOtherFile(dataTypeOthers)
                    setFiles(response)
                });
            })
        }
    }, [selectedFeatureForInfoEdit, selectedFeatureId])

    const generateLink = (attachment: IAttachment) => {
        const { REACT_APP_BASE_URL } = process.env
        return REACT_APP_BASE_URL + `rest/v1/attachment/${attachment.id}?token=${token}`
    }

    const filteredDataColumns = dataColumnsArray.filter((dc: any) => (
        dc.name !== 'bbox' &&
        dc.name !== 'st_astext' &&
        dc.name !== 'id' &&
        dc.name !== 'layer_id' &&
        dc.name !== 'desc' &&
        dc.name !== 'desc_eng' &&
        dc.name !== 'adi_eng' &&
        dc.name !== 'createdBy' &&
        dc.name !== 'createdDate' &&
        dc.name !== 'geom'
    ))

    // @ts-ignore
    const alphabeticalDataColumns = filteredDataColumns.sort((a, b) => {
        const textA = a.alias.toUpperCase();
        const textB = b.alias.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    useEffect(() => {
        if (selectedFeatureForInfoEdit.result) {
            setDistincts(+selectedFeatureForInfoEdit.result[0].il);
            setNeighborhoods(+selectedFeatureForInfoEdit.result[0].ilce)
            const {
                adi_eng,
                desc,
                desc_eng,
                createdBy,
                createdDate,
                geom,
                ...filteredElem } = selectedFeatureForInfoEdit.result[0];

            Object.keys(filteredElem).forEach(function (key) {
                if (filteredElem[key] === null) {
                    filteredElem[key] = '-';
                }
                else if (files && files.length !== 0) {

                    filteredElem.belgeler =
                        <>
                            {image && image.length > 0 &&
                                <div>
                                    {
                                        image.map((item: any, i: any) => {
                                            return <div className='file-box'>
                                                <Image
                                                    src={item.imageUrl}
                                                    alt={item.file_type}
                                                    preview
                                                />
                                                <div key={i} className='file-name-container'>
                                                    <div className='name file-name'>
                                                        {item.filename}
                                                    </div>
                                                </div>
                                                <div className='button-container'>
                                                    <a className='anim anim-from-left' onClick={() => downloadFile(item)} download>
                                                        {t('EDIT.Download')}
                                                    </a>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            }

                            {otherFile && otherFile.length > 0 &&
                                <div>
                                    {
                                        otherFile.map((item: any) => {
                                            return <div className='file-box'>
                                                <Image
                                                    src='customize-icons/file.svg'
                                                    alt={item.file_type}
                                                />
                                                <div className='file-name-container'>
                                                    <div className='name file-name'>
                                                        {item.filename}
                                                    </div>
                                                </div>
                                                <div className='button-container'>
                                                    <a className='anim anim-from-left' onClick={() => downloadFile(item)} download>
                                                        {t('EDIT.Download')}
                                                    </a>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            }
                        </>
                }
            })
            setEditFieldsValues(filteredElem)
            // setDistincts(+editFieldsValues.il)
            // setNeighborhoods(+editFieldsValues.ilce)
        }

        else if (selectedFeatureForInfoEdit) {
            setDistincts(+selectedFeatureForInfoEdit.il);
            setNeighborhoods(+selectedFeatureForInfoEdit.ilce)
            const {
                adi_eng,
                desc,
                desc_eng,
                createdBy,
                createdDate,
                geom,
                ...filteredElem } = selectedFeatureForInfoEdit;

            Object.keys(filteredElem).forEach(function (key) {
                if (filteredElem[key] === null) {
                    filteredElem[key] = '-';
                }
                else if (files && files.length !== 0) {

                    filteredElem.belgeler =
                        <>
                            {image && image.length > 0 &&
                                <div>
                                    {
                                        image.map((item: any, i: any) => {
                                            return <div className='file-box' key={i}>
                                                <Image
                                                    src={item.imageUrl}
                                                    alt={item.file_type}
                                                    preview
                                                />
                                                <div key={i} className='file-name-container'>
                                                    <div className='name file-name'>
                                                        {item.filename}
                                                    </div>
                                                </div>
                                                <div className='button-container'>
                                                    <a className='anim anim-from-left' onClick={() => downloadFile(item)} download>
                                                        {t('EDIT.Download')}
                                                    </a>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            }

                            {otherFile && otherFile.length > 0 &&
                                <div>
                                    {
                                        otherFile.map((item: any) => {
                                            return <div className='file-box' key={item.filename}>
                                                <Image
                                                    src='customize-icons/file.svg'
                                                    alt={item.file_type}
                                                />
                                                <div className='file-name-container'>
                                                    <div className='name file-name'>
                                                        {item.filename}
                                                    </div>
                                                </div>
                                                <div className='button-container'>
                                                    <a className='anim anim-from-left' onClick={() => downloadFile(item)} download>
                                                        {t('EDIT.Download')}
                                                    </a>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            }
                        </>
                }
            })
            setEditFieldsValues(filteredElem)
            // setDistincts(+editFieldsValues.il)
            // setNeighborhoods(+editFieldsValues.ilce)
        }


    }, [selectedFeatureForInfoEdit, files, image, otherFile])

    /**
    * Tüm şehirleri ve id lerini getirir.
    */
    const setCities = () => {
        DigitizationRestApi.getCities().then((res) => {
            const features = res.data.features
            features.map((feature: any) => {
                cityProperties.push(feature.properties)
            })
            setDomainsOfCity(cityProperties)
        })
    }

    /**
    * Seçilen ildeki ilçeleri getirir
    */
    const setDistincts = (cityId: number) => {
        DigitizationRestApi.getDistincts(cityId).then((res) => {
            const features = res.data.features
            features?.map((feature: any) => {
                distinctsOfCity.push(feature.properties)
            })
            setDomainsOfDistincts(distinctsOfCity)
        })
    }

    /**
    * Seçilen ilçedeki mahalleleri getirir
    */
    const setNeighborhoods = (distinctId: number) => {
        DigitizationRestApi.getNeighborhoods(distinctId).then((res) => {
            const features = res.data.features
            features?.map((feature: any) => {
                neighborhoods.push(feature.properties)
            })
            setDomainsOfNeighborhoods(neighborhoods)
        })
    }

    /**
     * Belgeleri indirmek için.
     */
    const downloadFile = (item: any) => {
        saveAs(item.downloadUrl);
    }

    /**
     * İnfo panelinden panoramanın açılmasını kontrol eder.
     */
    const openPanorama = () => {
        dispatch(changePanoVisibility(true))
    }

    /**
     * İnfo panelinden edit ekranının açılmasını kontrol eder.
     */
    const openEditPopup = () => {
        if (alphabeticalDataColumns.length > 0) {
            dispatch(setDataColumns(alphabeticalDataColumns))
            dispatch(setHeader(selectedFeatureForInfoEdit.result ? selectedFeatureForInfoEdit.result[0].adi : selectedFeatureForInfoEdit.adi))
            dispatch(changeEditPopupFromInfoVisibility(true))
            dispatch(setProperties(selectedFeatureForInfoEdit.result ? selectedFeatureForInfoEdit.result[0] : selectedFeatureForInfoEdit))
            dispatch(changeInfoVisibility(false))
        }
    }

    /**
     * İnfo paneli kapatır.
     */
    const closeInfoPanel = () => {
        dispatch(setFeatureExtent(false))
        dispatch(changeInfoVisibility(false))
    }

    return (
        <>
            {token && infoVisibility &&
                <div className='worker-info-panel'>
                    <i className='pi pi-times' onClick={closeInfoPanel} />

                    <div className='p-text-left'>
                        {selectedFeature['adi' + (language === 'tr' ? '' : ('_' + language))]}
                    </div>

                    <div className='feature-information'>
                        {unit2Names.length > 0 && <div className='units'>
                            {unit2Names.map((unit: any) => { return unit.unit2 !== null && <li key={unit.unit2 && unit.unit2.id}>{unit.unit2 && unit.unit2['name' + (language === 'tr' ? '' : ('_' + language))]}</li> }
                            )}
                        </div>}
                        <div className='feature-details'>
                            {!panoramaVisibility && <div className='info-pano-button'>
                                {layerName !== 'aof_alan_usr' && <SecondaryButton label={t('BUTTON.360° Campus')} onClick={openPanorama} />}
                            </div>}
                            <div className='build-information'>
                                {alphabeticalDataColumns.map((info: any) =>
                                    <div className='rows' key={info.name}>
                                        <li className='info-alias' key={info.name}>{info.alias}</li>
                                        {info.domain && <div className='info-values'>
                                            {
                                                info.name !== 'mevcut_kullanim_kodu' ?
                                                    propertiesForDomain
                                                        .find((field: any) => info.name === field.name)
                                                        ?.domain
                                                        .find((domain: any) => domain.id === editFieldsValues[info.name])?.desc :

                                                    propertiesForDomain
                                                        .find((field: any) => info.name === field.name)
                                                        ?.domain
                                                        .find((domain: any) => domain.id === editFieldsValues[info.name])?.desc_code
                                            }
                                        </div>
                                        }
                                        {info.type === 'City' && <div className='info-values'>
                                            {domainsOfCity && domainsOfCity.find((domain: any) => domain.il_id === +editFieldsValues[info.name])?.adi}
                                        </div>}
                                        {info.type === 'Distinct' && <div className='info-values'>
                                            {domainsOfDistincts && domainsOfDistincts.find((domain: any) => domain.ilce_id === +editFieldsValues[info.name])?.adi}
                                        </div>}
                                        {info.type === 'Neighbourhood' && <div className='info-values'>
                                            {domainsOfNeighborhoods && domainsOfNeighborhoods.find((domain: any) => domain.mah_id === +editFieldsValues[info.name])?.adi}
                                        </div>}
                                        {info.type === 'Date' && <div className='info-values'>
                                            {editFieldsValues[info.name]?.split('Z')[0]}
                                        </div>}
                                        {!info.domain &&
                                            info.type !== 'City' && 
                                            info.type !== 'Distinct' && 
                                            info.type !== 'Neighbourhood' && 
                                            info.type !== 'Date' && 
                                            <div className='info-values'>
                                                {editFieldsValues[info.name]}
                                            </div>}
                                    </div>
                                )}
                            </div>
                            <br />
                        </div>

                    </div>
                    <div className='info-edit-button'>
                        <PrimaryButton label={t('BUTTON.Edit')} onClick={openEditPopup}></PrimaryButton>
                    </div>

                </div>
            }
        </>
    )
}



import { Slider } from 'primereact/slider'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setCustomMap, setOpacity } from '../../../state/features/customMapSlice'
import { useAppSelector } from '../../../state/hooks'
import { RootState } from '../../../state/store'
import './CustomMap.scss'

export const CustomMap = () => {

    const dispatch = useDispatch();
    const [customMaps, setCustomMaps] = useState<any>([]);
    const customMapArray = useAppSelector((state: RootState) => state.customMap.customMap);
    const services = useAppSelector(state => state.service.services);

    useEffect(() => {
        services.forEach(service => {
            if (service.type === 'GEOSERVER_READ') {
                let customMaps = service.layers;
                customMaps = customMaps.map((layer: any) => ({
                    ...layer,
                    visible: false,
                    opacity: 0
                }))
                dispatch(setCustomMap(customMaps))
                setCustomMaps(customMapArray)
            }
        })
    }, [services])

    useEffect(() => {
        setCustomMaps(customMapArray)
    }, [customMapArray])

    const sliderValueChanged = (customMapId: number, value: any) => {
        dispatch(setOpacity({ opacity: value, id: customMapId }))
    }

    return (
        <React.Fragment>
            {customMaps.length > 0 && customMaps.map((customMap: any) =>
                <div className='boxes' key={customMap.id}>
                    <img className='image-box' src={`basemap-icons/${customMap.name}.png`} alt='googleStreet' />
                    <div className='group'>
                        <p className='title'>{customMap.alias} <span>%{customMap.opacity}</span></p>
                        {/* <Slider key={customMap.id} className='custom-spinner' value={customMap.opacity} onSlideEnd={(e) => sliderValueChanged(customMap.id, e.value)} /> */}
                        <input type="range" className='styled-slider'  min="0" max="100" value={customMap.opacity} onInput={(e : any) => sliderValueChanged(customMap.id, e.target.value)} />
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setDigitizationLayer, startDeleteDigitization } from '../../state/features/digitizationSlice';
import { changeDeletePopupVisibility } from '../../state/features/popupSlice';
import { ILayer } from '../../util/model/layer';
import { PrimaryButton } from '../shared/buttons/primary-button/PrimaryButton';
import { SecondaryButton } from '../shared/buttons/secondary-button/SecondaryButton';
import { Popup } from '../shared/popup/Popup'
import './DeleteObject.scss'

interface Props {
    //deletedData: any;
    layer: ILayer
}

const DeleteObject = (props: Props) => {
    const dispatch = useDispatch();
    dispatch(setDigitizationLayer(props.layer))
    const { t } = useTranslation();

    const deleteObject = () => {
        dispatch(startDeleteDigitization(true))
        closeDeletePopup()
    }

    const deleteObjectContainer =
        <div className='delete-object-container'>
            <div className='delete-confirm-text'>
                <i className='pi pi-info-circle' />
                <span>{t('POPUP.Are you sure you want to permanently delete this object?')}</span>
            </div>
            <div className='delete-confirm-buttons'>
                <PrimaryButton label={'Hayır'} onClick={() => closeDeletePopup()}></PrimaryButton>
                <SecondaryButton label={'Evet'} onClick={deleteObject}></SecondaryButton>
            </div>
        </div>

    const closeDeletePopup = () => {
        dispatch(changeDeletePopupVisibility(false))
    }

    return (
        <div>
            <Popup
                className='delete-object-container'
                header={t('POPUP.Delete Confirmation')}
                visible={true}
                onHide={() => closeDeletePopup()}
                content={deleteObjectContainer}>
            </Popup>
        </div>
    )
}

export default DeleteObject
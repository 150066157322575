import { configureStore } from '@reduxjs/toolkit';
import { basemapSlice } from './features/basemapSlice';
import { customizeSlice } from './features/customizeSlice';
import { customMapSlice } from './features/customMapSlice';
import { serviceSlice } from './features/serviceSlice';
import { drawStateSlice } from './features/drawStateSlice';
import { searchSlice } from './features/searchSlice';
import { popupSlice } from './features/popupSlice';
import { sidebarSlice } from './features/sidebarSlice';
import { tableSlice } from './features/tableSlice';
import { panoramaSlice } from './features/panoramaSlice';
import { infoSlice } from './features/infoSlice';
import { buttonStateSlice } from './features/buttonStateSlice';
import { selectedFeatureSlice } from './features/selectedFeatureSlice';
import { languageSlice } from './features/languageSlice';
import { digitizationSlice } from './features/digitizationSlice';
import { editObjectSlice } from './features/editObjectSlice';
import { changeExtentSlice } from './features/changeExtentSlice';
import { loginSlice } from './features/loginSlice';
import { attachmentSlice } from './features/attachmentSlice';
import { cityFeatureSlice } from './features/cityFeatureSlice';
import { panoDrawStateSlice } from './features/panoDrawStateSlice';
import { categoricalSearchSlice } from './features/categoricalSearchSlice';


export const store = configureStore({
    reducer: {
        customize: customizeSlice.reducer,
        basemap: basemapSlice.reducer,
        service: serviceSlice.reducer,
        customMap: customMapSlice.reducer,
        sidebar: sidebarSlice.reducer,
        table: tableSlice.reducer,
        drawState: drawStateSlice.reducer,
        panoDrawState: panoDrawStateSlice.reducer,
        search: searchSlice.reducer,
        popup: popupSlice.reducer,
        panorama: panoramaSlice.reducer,
        info: infoSlice.reducer,
        buttonState: buttonStateSlice.reducer,
        selectedFeature: selectedFeatureSlice.reducer,
        language: languageSlice.reducer,
        digitization: digitizationSlice.reducer,
        editObject: editObjectSlice.reducer,
        changeExtent: changeExtentSlice.reducer,
        login: loginSlice.reducer,
        attachment: attachmentSlice.reducer,
        cityFeature: cityFeatureSlice.reducer,
        categoricalSearch: categoricalSearchSlice.reducer
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false
    })
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

import { createSlice } from '@reduxjs/toolkit';
import { ICustomMap } from '../../util/model/customMap';

interface CustomMap {
    customMap: ICustomMap
}

const initialState: any = {
    customMap: {
        id: 0,
        url: '',
        layername: '',
        image: '',
        alias: '',
        visible: false,
        deseription: '',
        fields: [],
        geomType: '',
        name: '',
        perm: 0,
        priority: 0,
        public: false,
        service_id: 0,
        style_id: 0,
        opacity: 0
    }
}

export const customMapSlice = createSlice({
    name: 'customMap',
    initialState,
    reducers: {
        setCustomMap: (state: any, action) => {
            state.customMap = action.payload;
        },

        setOpacity: (state: any, action) => {
            const { id, opacity } = action.payload as { id: number, opacity: number };
            const map = state.customMap.find((map: any) => map.id === id)
            map.opacity = opacity
        }
    }
})

export const { setCustomMap, setOpacity } = customMapSlice.actions

export default customMapSlice.reducer
import { useContext, useEffect, useRef, useState } from "react";
import { Overlay as OlOverlay } from 'ol';
import { Menu } from "primereact/menu";
import { MapContext } from '../MapContext';
interface Props {
    wmsItems: any
}

export default function Overlay(props: Props) {

    const { wmsItems } = props;
    const cm = useRef(null);
    const map = useContext(MapContext)
    const [coordinate, setCoordinate] = useState(null);
    const [popup, setPopup] = useState(null);

    useEffect(() => {
        const popup: any = new OlOverlay({
            element: document.getElementById('popup')!
        });
        setPopup(popup);
        popup.setPosition(coordinate);
        map.addOverlay(popup);

        map.on('click', (event: any) => {
            if (wmsItems) {
                setCoordinate(event.coordinate)
            }
        })

        return () => {
            map.removeOverlay(popup);
        }
    }, [])

    useEffect(() => {
        if (popup) {
            if (wmsItems.length > 1) {
                (popup as any).setPosition(coordinate)

            } else {
                (popup as any).setPosition(undefined)

            }
        }
    }, [wmsItems])



    return (
        <Menu id="popup" model={wmsItems} ref={cm} />
    )
}

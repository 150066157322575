import { MapBrowserEvent } from 'ol';
import { Tile } from 'ol/layer';
import { TileWMS } from 'ol/source';
import { useContext, useEffect } from 'react'
import { useAppSelector } from '../../../../../state/hooks';
import { RootState } from '../../../../../state/store';
import { ButtonState } from '../../../../shared/button-state/ButtonState';
import { MapContext } from '../MapContext';

interface Props {
    tag: string;
    callback: (arr: { url: string, wms: Tile<TileWMS> }[], coordinate: number[]) => void
}
export default function WmsInfo(props: Props) {
    const map = useContext(MapContext)
    const buttonVisibility = useAppSelector((state: RootState) => state.buttonState.value)
    const drawGeom = useAppSelector((state) => state.digitization.openDigitization)
    const geometryEdit = useAppSelector((state) => state.digitization.openGeometryEdit)

    const onClick = (event: MapBrowserEvent<any>) => {
        const view = map.getView();
        const layers = map.getLayers().getArray()
            .filter(l => l.get('tag') === props.tag)
            .filter(l => l.getVisible())
            .map(l => {
                const wms = l as Tile<TileWMS>;
                const url = wms.getSource().getFeatureInfoUrl(
                    event.coordinate,
                    view.getResolution()!,
                    view.getProjection(),
                    {
                        QUERY_LAYERS: wms.getSource().getParams().layers,
                        info_format: 'application/json'
                    }
                )!;
                return { url, wms }
            })

        props.callback(layers, event.coordinate);
    }


    useEffect(() => {

        if (buttonVisibility !== ButtonState.MEASUREMENT) {
            map.on('click', onClick)
        }
        if (drawGeom) {
            map.un('click', onClick)
        }
        if (geometryEdit) {
            map.un('click', onClick)
        }
        return () => {
            map.un('click', onClick)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.callback, buttonVisibility, drawGeom, geometryEdit]);


    return null;
}

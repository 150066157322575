import axios from 'axios';

const { REACT_APP_BASE_URL } = process.env

function sleep() {
  return new Promise(((res) => setTimeout(res, 100)));
}

const getToken = () => {
  return localStorage.getItem('token')
}

/**
 * Servisler ve altındaki tüm katmanları getirir
 * @returns 
 */

async function getServices() {
  await sleep();
  return axios.get(REACT_APP_BASE_URL + `rest/v1/services`, { params: { token: getToken() } })
}
async function getStyleById(styleId: any) {
  await sleep();
  return axios.get(REACT_APP_BASE_URL + 'rest/v1/style/byId/' + styleId)
}
async function getStyles() {
  await sleep();
  return axios.get(REACT_APP_BASE_URL + 'rest/v1/style')
}
async function getFeatures(layers: string[], addToUrl?: string, filter?: string) {
  await sleep();
  const token = localStorage.getItem('token');

  if (layers.length > 1) {
    const layersString = layers.join(',');
    const urlWfs =
      REACT_APP_BASE_URL +
      'rest/v1/geoserver/anadolu/ows?service=WFS&version=1.0.0&request=GetFeature&outputFormat=application/json&srsname=EPSG:3857&typeName=' +
      layersString +
      '&' +
      addToUrl +
      '&' +
      filter
      +
      `&token=${token}`
      ;
    return axios.get(urlWfs);
  }
  else if (layers.length === 1) {
    const urlWfs =
      REACT_APP_BASE_URL +
      'rest/v1/geoserver/anadolu/ows?service=WFS&version=1.0.0&request=GetFeature&outputFormat=application/json&srsname=EPSG:3857&typeName=' +
      layers
      +
      `&token=${token}`
      ;
    return axios.get(urlWfs);
  }
}

async function getGeojsonFromGetFeatureInfoUrlArray(urlArray: string[]) {
  const result = await Promise.all(
    urlArray.map(url => axios.get(url).then(res => res.data.features))
  )
  return result;
}

export const LayerRestApi = {
  getServices,
  getFeatures,
  getGeojsonFromGetFeatureInfoUrlArray,
  getStyleById,
  getStyles
}
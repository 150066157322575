import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: false,
    layer: {
        id: 0,
        name: '',
        alias: '',
        geomtype: '',
        public: false,
        priority: 0,
        service_id: 0,
        style_id: null,
        fields: [],
        visible: false,
        perm: false,
        desription: null
    },
    openDigitization: false,
    openPopup: false,
    dataColumns: [],
    inputValues: {},
    startAddDigi: false,
    startDeleteDigi: false,
    openGeometryEdit: false,
    digiFeature: {
        geometry: {}
    },
    addDigiCancelActive: false,
    showToastMessage: false,
    provinceProperties: {},
    allNeighborhoods: {},
    allDistincts: {},
    allCities: {}
}
export const digitizationSlice = createSlice({
    name: 'digitization',
    initialState,
    reducers: {
        // suanda kullanilmiyor, silinebilir
        drawObjectVisibility: (state: any, action) => {
            state.value = action.payload;
        },
        startDigitization: (state: any, action) => {
            state.openDigitization = action.payload;
        },
        // hangi layerda sayisallastirma yapacagimizi setleyip, o layer in fieldlarini aliyoruz 
        setDigitizationLayer: (state: any, action) => {
            state.layer = action.payload;
            if (state.layer && state.layer.fields) {
                for (const field of state.layer.fields) {
                    state.dataColumns = [...state.dataColumns, field]
                }
            }
        },
        // insert yaparken acilan popup ı kontrol eder
        openAddDigiPopup: (state: any, action) => {
            state.openPopup = action.payload;
        },
        // fieldlar la input icindeki degerleri eslestiriyoruz, deger bossa null atiyoruz
        setInputValues: (state: any, action) => {
            state.inputValues = action.payload

            // layer id yi setliyoruz
            const findLayerIdIndex: any = state.dataColumns.findIndex((elem: any) => elem.name === 'layer_id')
            state.dataColumns[findLayerIdIndex] = { ...state.dataColumns[findLayerIdIndex], val: state.layer.id };

            //domainse hem objeyi hem domain_id yi tutuyoruz. (objeyi dropdown ı setlemesi için)
            if (state.inputValues.e?.desc) {
                const findRowIndex: any = state.dataColumns.findIndex((elem: any) => elem.id === state.inputValues.id);
                state.dataColumns[findRowIndex] = { ...state.dataColumns[findRowIndex], val: state.inputValues.e.id };
                state.dataColumns[findRowIndex] = { ...state.dataColumns[findRowIndex], valObj: state.inputValues.e };
            }
            // il, ilce, mahalle icin yine hem objeyi hem id yi tutuyoruz
            else if(state.inputValues.e.il_id){
                const findRowIndex: any = state.dataColumns.findIndex((elem: any) => elem.id === state.inputValues.id);
                state.dataColumns[findRowIndex] = { ...state.dataColumns[findRowIndex], val: state.inputValues.e.il_id };
                state.dataColumns[findRowIndex] = { ...state.dataColumns[findRowIndex], valObj: state.inputValues.e };
            }
            else if(state.inputValues.e.ilce_id){
                const findRowIndex: any = state.dataColumns.findIndex((elem: any) => elem.id === state.inputValues.id);
                state.dataColumns[findRowIndex] = { ...state.dataColumns[findRowIndex], val: state.inputValues.e.ilce_id };
                state.dataColumns[findRowIndex] = { ...state.dataColumns[findRowIndex], valObj: state.inputValues.e };
            }
            else if(state.inputValues.e.mah_id){
                const findRowIndex: any = state.dataColumns.findIndex((elem: any) => elem.id === state.inputValues.id);
                state.dataColumns[findRowIndex] = { ...state.dataColumns[findRowIndex], val: state.inputValues.e.mah_id };
                state.dataColumns[findRowIndex] = { ...state.dataColumns[findRowIndex], valObj: state.inputValues.e };
            }
            else {
                const findRowIndex: any = state.dataColumns.findIndex((elem: any) => elem.id === state.inputValues.id);
                state.dataColumns[findRowIndex] = { ...state.dataColumns[findRowIndex], val: state.inputValues.e };
            }
            state.dataColumns.map((data: any) => {
                if (!data.val) {
                    data.val = null
                }
            })
        },
        // insert islemini baslaityor
        startAddDigitization: (state: any, action) => {
            state.startAddDigi = action.payload;
            if (action.payload === false) {
                state.dataColumns = []
            }
        },
        // edit-delete islemlerini baslatmak icin
        startDeleteDigitization: (state: any, action) => {
            state.startDeleteDigi = action.payload;
        },
        // edit-delete islemlerinde tablodan secilen feature ı map e ciziyoruz 
        setDigiFetaure: (state: any, action) => {
            state.digiFeature = action.payload;
        },
        // geometry edit islemini baslatir
        setOpenGeometryEdit: (state: any, action) => {
            state.openGeometryEdit = action.payload;
        },
        // add geometri de popupta cancel e basınca işlem iptal edilir 
        setAddDigiCancelActive: (state: any, action) => {
            state.addDigiCancelActive = action.payload
        },
        setToastMessageStatus: (state: any, action) => {
            state.showToastMessage = action.payload
        },
        setProvinceProperties:(state: any, action) => {
            state.provinceProperties = action.payload
        },
        setAllOfCity:(state: any, action) => {
            state.allCities = action.payload
        },
        setAllOfDistincts:(state: any, action) => {
            state.allDistincts = action.payload
        },
        setAllOfNeighborhoods:(state: any, action) => {
            state.allNeighborhoods = action.payload
        },

    }
})

export const { drawObjectVisibility,
    startDigitization,
    setDigitizationLayer,
    openAddDigiPopup,
    setInputValues,
    startAddDigitization,
    startDeleteDigitization,
    setDigiFetaure,
    setOpenGeometryEdit,
    setAddDigiCancelActive,
    setToastMessageStatus,
    setProvinceProperties,
    setAllOfCity,
    setAllOfDistincts,
    setAllOfNeighborhoods
} = digitizationSlice.actions;


export default digitizationSlice.reducer;
import { createSlice } from '@reduxjs/toolkit';
import { IService } from '../../util/model/layer';
interface IInitialState {
    services: IService[],
    features: any
}

const initialState: IInitialState = {
    services: [],
    features: []
}

const getToken = () => {
    return localStorage.getItem('token')
}

export const serviceSlice = createSlice({
    name: 'service',
    initialState,
    reducers: {
        getServices: (state: IInitialState, action) => {
            state.services = action.payload;
            if (!getToken()) {
                const visibleService = state.services.find((layer: any) => { return layer.name === 'tasinmazlar' })
                if (visibleService) {
                    const visibleLayers = visibleService.layers.filter((obj: any) => { return obj.name === 'yapilar_usr' || obj.name === 'aof_alan_usr' })
                    for (const layer of visibleLayers) {
                        layer.visible = true;
                    }
                }
            }
        },
        getFeatures: (state: IInitialState, action) => {
            state.features = action.payload
        }
    }
})

export const { getServices, getFeatures } = serviceSlice.actions

export default serviceSlice.reducer
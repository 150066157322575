import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { changeReportVisibility } from '../../state/features/tableSlice';
import { useAppSelector } from '../../state/hooks';
import { PrimaryButton } from '../shared/buttons/primary-button/PrimaryButton';
import { Popup } from '../shared/popup/Popup';
import './Reports.scss'
import { SecondaryButton } from '../shared/buttons/secondary-button/SecondaryButton';
import { TableRestApi } from '../../util/restapi/table';
import FileSaver from 'file-saver';

interface Props {
    tableData: any;
    dataColumns: any;
}

const Reports = (props: Props) => {
    const { tableData, dataColumns } = props
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const services = useAppSelector((state) => state.service.services)
    const layerFromTable: any = useAppSelector((state) => state.table.layerFromTable)
    const [selectedLayer, setSelectedLayer] = useState<any>(null);
    const [selectedReport, setSelectedReport] = useState<any>(null);
    const [options, setOptions] = useState<any>(null)
    let layers: any[] = []

    const yapilar = [
        { label: 'ESKİŞEHİR BİNALAR BEDEL', value: '1' },
    ];

    const parsel = [
        { label: 'T.KAYITLI TAŞINMAZ EK-2', value: '2' },
    ]
    const tahsisliParsel = [
        { label: 'SINIRLI AYNİ HAKLAR EK-6', value: '2' },
    ]
    const aof = [
        { label: 'DİĞER İLLER BİNA BEDEL', value: '1' },
        { label: 'T.KAYITLI TAŞINMAZ İLLER EK-2', value: '2' }
    ]

    useEffect(() => {
        if (layerFromTable.name === 'yapilar_usr') {
            setOptions(yapilar)
        }
        else if (layerFromTable.name === 'aof_alan_usr') {
            setOptions(aof)
        }
        else if (layerFromTable.name === 'parsel_usr') {
            setOptions(parsel)
        }
        else if (layerFromTable.name === 'tahsisli_parsel_usr') {
            setOptions(tahsisliParsel)
        }
    }, [selectedLayer])

    const open: boolean = useAppSelector((state) => state.table.reportVisible)

    const findedService = services.find((service: any) => (service.name === 'tasinmazlar'))
    findedService?.layers.map((layer: any) => {
        layers.push({ label: layer.alias, value: layer.name })
    })

    const closeReportsPopup = () => {
        dispatch(changeReportVisibility(false))
    }

    const getReport = async () => {

        await TableRestApi.postReport(selectedReport, tableData)

        //window.open(`https://anadolu.ankageo.com/rest/v1/report/${selectedReport}`)
        const selectedObject = options.find((option: any) => option.value === selectedReport)
        TableRestApi.getReport(selectedReport).then((res) => {
            let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            let EXCEL_EXTENSION = ".xlsx";
            const data: Blob = new Blob([res.data], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(
                data,
                selectedObject.label + "_" + new Date().toISOString() + EXCEL_EXTENSION
            );
        })
    }

    const reports =
        <>
            <div className='reports-container'>
                <Dropdown className='report-type-dropdown' value={selectedReport} options={options} optionLabel="label" optionValue="value" onChange={(e: any) => setSelectedReport(e.value)} placeholder={t('REPORTS.Select Report Type')} />
                <div className='report-buttons'>
                    <SecondaryButton label={t('BUTTON.Cancel')} onClick={closeReportsPopup} moreStyle='cancel-report-button'></SecondaryButton>
                    <PrimaryButton className='get-report-button' label={t('REPORTS.Get Report')} onClick={getReport} />
                </div>
            </div>
        </>

    return (
        <>
            <Popup
                className='table-reports'
                header={t('REPORTS.Reports')}
                visible={open}
                onHide={() => closeReportsPopup()}
                content={reports}>
            </Popup>
        </>
    )
};

export default Reports;


import { Vector } from 'ol/source';
import { GeoJSON } from 'ol/format';
import VectorLayer from 'ol/layer/Vector';
import { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { MapContext } from '../MapContext';
import { Snap } from 'ol/interaction';
import axios from 'axios';
import VectorSource from 'ol/source/Vector';
import { useAppSelector } from '../../../../../state/hooks';
import { DrawStateType } from '../../../../layouts/toolbar/draw-tools/DrawTools';

/* 
 * Bu component yeni feature oluştururken bu feature ı diğer featurelarla birleştirmek ve yakalamak için yazıldı.
 * Diğer featureların noktalarını görebilmesi için haritaya featureları wfs layer olarak ekliyoruz.
 *
*/

interface IProps {
    layername: string,
    visible: boolean,
}

function WfsLayer(props: PropsWithChildren<IProps>) {

    const { layername, visible } = props;
    const map = useContext(MapContext);
    const t = useRef<VectorLayer<any> | null>(null);
    const [source, setSource] = useState<VectorSource<any>>(new VectorSource())
    const [isLoaded, setIsLoaded] = useState(false)
    const [snapInteraction, setSnapInteraction] = useState<any>(undefined)
    const drawState = useAppSelector(state => state.drawState.isDrawing);
    //const openDigitization = useAppSelector((state) => state.digitization.openDigitization)

    useEffect(() => {
        const token = localStorage.getItem('token');
        const { REACT_APP_BASE_URL } = process.env

        const url = REACT_APP_BASE_URL + `rest/v1/geoserver/anadolu/ows?service=WFS&version=1.0.0&request=GetFeature&outputFormat=application/json&srsname=EPSG:3857&typeName=` +
            layername + (token ? `&token=${token}` : '')

        const response = axios.get(url).then(response => {
            const featureCollection = response?.data;

            // feature collection [] gelince hata veriyor, onu kontrol ediyoruz length ile
            if (featureCollection && !featureCollection.length) {
                const geojson = new GeoJSON();
                const features: any = geojson?.readFeatures(featureCollection)
                let vectorSource = new Vector();

                vectorSource.addFeatures(features)
                let vector = new VectorLayer({
                    source: vectorSource,
                    visible: visible,
                    zIndex: 10000000
                });

                setSource(vectorSource);
                setIsLoaded(true)

                t.current = vector;
                map.addLayer(vector);
            }
        })

        return () => {
            map.removeLayer(t.current!);
        }
    }, [])

    useEffect(() => {
        const vectorLayer = t.current!;
        if (vectorLayer) {
            vectorLayer.setVisible(visible);
        }
    }, [visible])

    useEffect(() => {
        const isSnapActive = (drawState && visible && isLoaded)
        if (isSnapActive) {
            const snap = new Snap({
                source: source
            })
            map.addInteraction(snap)
            setSnapInteraction(snap);
        } else {
            if (snapInteraction) {
                map.removeInteraction(snapInteraction)
            }
        }
    }, [drawState, visible, isLoaded])


    return (
        <></>
    )
}

export default WfsLayer
import WFS from 'ol/format/WFS';
import GML from 'ol/format/GML';
import { useAppSelector } from '../../../../../state/hooks';
import { DigitizationRestApi } from '../../../../../util/restapi/digitization';
import { setDigitizationLayer, startDeleteDigitization, setOpenGeometryEdit, startAddDigitization, startDigitization, setToastMessageStatus } from '../../../../../state/features/digitizationSlice';
import { useDispatch } from 'react-redux';
import { useContext, useEffect } from 'react';
import MultiPolygon from 'ol/geom/MultiPolygon';
import MultiPoint from 'ol/geom/MultiPoint';
import MultiLineString from 'ol/geom/MultiLineString';
import Feature from 'ol/Feature';
import { setEditSave } from '../../../../../state/features/editObjectSlice';
import { DigiCallbackContext } from './DigiCallbackContextProvider';
import { changeEditPopupFromInfoVisibility, changeEditPopupVisibility } from '../../../../../state/features/popupSlice';
import { GeoJSON } from 'ol/format';
import { LayerRestApi } from '../../../../../util/restapi/layer';
import { getFeatures } from '../../../../../state/features/serviceSlice';
import { MapContext } from '../MapContext';
import { setLayer } from '../../../../../state/features/tableSlice';

/* 
 * Sayısallaştırma işlemlerinin (write,delete,update) yapıldığı component.
 *
*/

export enum Mode {
    INSERT = 'INSERT',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE'
}

export enum DrawType {
    POINT = 'POINT',
    LINESTRING = 'LINESTRING',
    POLYGON = 'POLYGON'
}

export enum From {
    TABLE = 'TABLE',
    INFO = 'INFO',
}
interface Props {
    feature: any;
    mode: Mode;
    source?: any;
    type: DrawType;
    from: From;
}

export const TransactWfs = (props: Props) => {
    const dispatch = useDispatch();
    const allProperties = useAppSelector((state) => state.digitization.dataColumns)
    const layerTable = useAppSelector((state) => state.digitization.layer)
    const layerInfo = useAppSelector((state) => state.info.layer)
    const editProperties = useAppSelector((state) => state.editObject.properties)
    const features = useAppSelector((state) => state.editObject.features)
    const map = useContext(MapContext);

    const digiCallback = useContext(DigiCallbackContext)
    useEffect(() => {
        let formatWFS = new WFS();

        let formatGML = new GML({
            featureNS: 'anadolu',
            featureType: props.from === 'TABLE' ? layerTable.name : layerInfo.name,
            srsName: 'EPSG:3857',
            hasZ: true
        });

        let xs = new XMLSerializer();

        let node: any;
        switch (props.mode) {
            case 'INSERT':
                const insertFeature = insertOperation();
                //@ts-ignore
                node = formatWFS.writeTransaction([insertFeature], null, null, formatGML);
                break;
            case 'UPDATE':
                const editedFeature = editOperation();
                editedFeature.unset('bbox', true)
                editedFeature.unset('key', true)
                editedFeature.unset('label', true)
                //@ts-ignore
                node = formatWFS.writeTransaction(null, [editedFeature], null, formatGML);
                break;
            case 'DELETE':
                const deleteFeature = deleteOperation();
                //@ts-ignore
                node = formatWFS.writeTransaction(null, null, [deleteFeature], formatGML);
                break;
        }

        let payload = xs.serializeToString(node);

        dispatch(setLayer(props.from === 'TABLE' ? layerTable : layerInfo))

        DigitizationRestApi.digiAddFeature(payload).then((res: any) => {
            if(res){
                dispatch(setToastMessageStatus(true))
            }
            // insert yapiliyorsa geoservera istek yapip featureId yi alacak o id ile attachment istegi yapacak
            if (props.mode === 'INSERT') {
                const featureId = res.split('.')[1]
                if (typeof (window as any).addDigiCallback === 'function') {
                    (window as any).addDigiCallback(featureId)
                }
                if (typeof digiCallback.callbackFunction === 'function') {
                    digiCallback.callbackFunction(featureId)
                }
            }
        })

        LayerRestApi.getFeatures(props.from === 'TABLE' ? [layerTable.name] : [layerInfo.name]).then((res: any) => {
            dispatch(getFeatures(res.data.features));
        })

        // map update 
        const layersOfMap = map.getLayers();

        layersOfMap.forEach((layer: any) => {
            let source: any = layer.getSource();
            if (source.updateParams != undefined)
                source.updateParams({ 'time': Date.now() });
        });

        //dispatch(openAddDigiPopup(false))
        dispatch(startAddDigitization(false))
        dispatch(startDeleteDigitization(false))
        dispatch(startDigitization(false))
        dispatch(setDigitizationLayer(undefined))
        dispatch(setOpenGeometryEdit(false))
        dispatch(setEditSave(false))
        props.source?.clear()

    }, [props.feature])




    const insertOperation = () => {
        if (props.mode === 'INSERT') {
            let newFeature = new Feature();

            let propertyOfFeatures: any[] = []
            allProperties.map((property: any) => {
                propertyOfFeatures.push({ [property.name]: property.val })
            })

            const properties = Object.assign({}, ...propertyOfFeatures);

            newFeature.setProperties(properties)
            newFeature.setGeometryName('geom')

            if (props.type === 'POINT') {
                const point = new MultiPoint([])
                point.appendPoint(props.feature.getGeometry())
                newFeature.setGeometry(point)
                //newFeature.setGeometry(new Point(props.feature.getGeometry().getCoordinates()))
            }
            else if (props.type === 'LINESTRING') {
                const linestring = new MultiLineString([]);
                linestring.appendLineString(props.feature.getGeometry())
                newFeature.setGeometry(linestring)
            }
            else if (props.type === 'POLYGON') {
                const polygon = new MultiPolygon([]);
                polygon.appendPolygon(props.feature.getGeometry())
                newFeature.setGeometry(polygon)
            }
            return newFeature
        }
    }

    const deleteOperation = () => {
        const deleteFeature = new GeoJSON().readFeature(props.feature)
        return deleteFeature
    }

    const editOperation = () => {
        // geometri de editleniyor ise ve table dan acilan property editleme
        if (props.from === 'TABLE') {
            let newFeature = props.feature
            newFeature.setProperties(editProperties)
            newFeature.setGeometryName('geom')
            dispatch(changeEditPopupVisibility(false))
            return newFeature
        }

        // sadece property editleniyor ise 
        else if (props.from === 'INFO') {
            //type geliyorsa categoric veya map den geliyordur
            if (props.type) {
                let newFeature = new GeoJSON().readFeature(props.feature)
                newFeature.setProperties(editProperties)
                newFeature.setGeometryName('geom')
                dispatch(changeEditPopupFromInfoVisibility(false))
                return newFeature
            }
            // quick searchten arama ve property düzenleme yapilacaksa önce quick searchten aranan
            // feature ı yapılar featurelarında arayıp buluyor. sonra sayısallaştırma yapiyor 
            else if (props.feature.geom) {
                // gelen tüm featurelardan bu feature ı bul ve devamı aynı 
                if (features) {
                    const findedFeature = features.find((elem: any) => (+(elem.id.split('.')[1]) === +props.feature.id) && elem.id.split('.')[0] === 'yapilar_usr')
                    let newFeature = new GeoJSON().readFeature(findedFeature)
                    newFeature.setProperties(editProperties)
                    newFeature.setGeometryName('geom')
                    dispatch(changeEditPopupFromInfoVisibility(false))
                    return newFeature
                }

            }
        }
    }

    return (
        <>
        </>
    )
}

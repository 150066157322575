import { createSlice } from '@reduxjs/toolkit';

const initialState:any = {
    centerX: 3395271.8857,
    centerY: 4835689.4652,
    zoom: 16.5,
    changeExtent: false
}

export const changeExtentSlice = createSlice({
    name: 'changeExtent',
    initialState,
    reducers: {
        changeExtent: (state: any, action) => {
            state.changeExtent = action.payload;
        },
        changeExtentCenterX: (state: any, action) => {
            state.centerX = action.payload;
        },
        changeExtentCenterY: (state: any, action) => {
            state.centerY = action.payload;
        },
        changeExtentZoom: (state: any, action) => {
            state.zoom = action.payload;
        },
    }
})

export const { changeExtentCenterX, changeExtentCenterY, changeExtentZoom, changeExtent } = changeExtentSlice.actions

export default changeExtentSlice.reducer
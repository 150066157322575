import { useContext, useEffect, useState } from 'react'
import { MapContext } from '../MapContext'
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import { useAppSelector } from '../../../../../state/hooks';
import { DigiDrawPoint } from './digi-draw/DigiDrawPoint';
import { DigiDrawPolygon } from './digi-draw/DigiDrawPolygon';
import { DigiDrawLinestring } from './digi-draw/DigiDrawLinestring';
import { useDispatch } from 'react-redux';
import { setAddDigiCancelActive, startDigitization } from '../../../../../state/features/digitizationSlice';

/* 
 * Sayısallaştırmanın ilk başladığı component. Burada geom tiplerine göre diğer componentler çağırılıyor.
 *
*/

export const Digitization = () => {
  const map = useContext(MapContext);
  const dispatch = useDispatch();
  const layer: any = useAppSelector((state) => state.digitization.layer)
  const [vector, setVector] = useState<VectorLayer<any>>(new VectorLayer())
  const [source, setSource] = useState<VectorSource<any>>(new VectorSource())
  const openDigitization = useAppSelector((state) => state.digitization.openDigitization)
  const cancelButtonActive = useAppSelector((state) => state.digitization.addDigiCancelActive)

  useEffect(() => {
    const source = new VectorSource({ wrapX: false });
    const vector = new VectorLayer({
      source: source,
      zIndex: 1000,
      style: new Style({
        fill: new Fill({
          color: 'A52125',
        }),
        stroke: new Stroke({
          color: '#A52125',
          width: 3,
        }),
      }),
    });

    map.addLayer(vector);
    setVector(vector)
    setSource(source)

    return () => {
      map.removeLayer(vector)
    }
  }, [])

  useEffect(() => {
    if(cancelButtonActive){
      source.clear();
      dispatch(startDigitization(false))
      dispatch(setAddDigiCancelActive(false))
  }
  }, [cancelButtonActive])
  

  return (
    <>
      {layer && layer.geomtype === 'MultiPointZ' &&  openDigitization && <DigiDrawPoint source={source} />}
      {layer && layer.geomtype === 'MultiLineStringZ' && openDigitization && <DigiDrawLinestring source={source} />}
      {layer && layer.geomtype === 'MultiPolygonZ' && openDigitization && <DigiDrawPolygon source={source} />}
    </>
  )
};


import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    visible: false,
    layerName: '',
    angle: {
        rotationX: 0.0,
        rotationY: 0.0,
        fov: 0.8333333333333334
    },
    mapCoord: [3395306.1932237986, 4836089.964055118],  //EPSG:3857 and lon,lat
    panoCoord: { lat: '', lon: '' },  //onLocationChange 
    displayPanoOnMobile: false
}

export const panoramaSlice = createSlice({
    name: 'panorama',
    initialState,
    reducers: {
        changePanoVisibility: (state: any, action) => {
            state.visible = action.payload
        },
        //bilgi kutusunda tasinmazlar disindaki katmanlar icin 
        //360 kampüs butonu gelmeyecek.  
        setLayerNameForPano: (state: any, action) => {
            state.layerName = action.payload
        },
        //panorama icin aci degeri setleniyor.
        setViewAngle: (state: any, action) => {
            state.angle = action.payload
        },
        //haritaya tıklandığında panoramanın da harita ile hareket etmesi için 
        //Map.tsx'ten setlenen koordinatlar.
        setCoordMapToPano: (state: any, action) => {
            state.mapCoord = action.payload
        },
        //panoramaya tıklandığında haritanın da panorama ile hareket etmesi için 
        //PanoramaContainer.tsx'ten setlenen koordinatlar.
        setCoordPanoToMap: (state: any, action) => {
            state.panoCoord = action.payload
        },
        setVisibilityOnMobile: (state: any, action) => {
            state.displayPanoOnMobile = action.payload
        },
    }
})

export const { changePanoVisibility, setCoordMapToPano, setCoordPanoToMap, setLayerNameForPano,setVisibilityOnMobile, setViewAngle } = panoramaSlice.actions;

export default panoramaSlice.reducer;
import './Login.scss';
import { InputText } from 'primereact/inputtext';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { RootState } from '../../state/store';
import { PrimaryButton } from '../shared/buttons/primary-button/PrimaryButton'
import { Popup } from '../shared/popup/Popup';
import { Checkbox } from 'primereact/checkbox';
import { SecondaryButton } from '../shared/buttons/secondary-button/SecondaryButton';
import { ButtonState } from '../shared/button-state/ButtonState';
import { setButtonState } from '../../state/features/buttonStateSlice';
import { LoginRestApi } from '../../util/restapi/login';
import { Password } from 'primereact/password';
import { setToken } from '../../state/features/loginSlice';
import { Toast } from 'primereact/toast';
import { AxiosError } from 'axios';
import { useAppSelector } from '../../state/hooks';

export const Login = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const toast = useRef<any>();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [checkboxStatus, setCheckboxStatus] = useState(false);
    const customize = useAppSelector((state: RootState) => state.customize.value);
    const buttonState = useAppSelector((state: RootState) => state.buttonState.value)
    const token = useAppSelector((state: RootState) => state.login.token)

    const login = () => {
        if (!token) {
            dispatch(setButtonState(ButtonState.LOGIN))
        }
    }

    const setUsernameInput = (e: any) => {
        setUsername(e)
    }

    const setPasswordInput = (e: any) => {
        setPassword(e)
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        LoginRestApi.login(username, password).then((res) => {
            if (res.data.token) {
                localStorage.setItem('token', res.data.token)
                dispatch(setToken(res.data.token))
            }
        }).catch((err: AxiosError) => {
            toast.current?.show({ severity: 'error', detail: (t('LOGIN.Login Fail')), life: 3000 });
            if (err.response?.status === 400) {
                localStorage.removeItem('token')
            }
        })
    }

    const loginContainer =
        <>
            <form onSubmit={handleSubmit}>
                <div className='content'>
                    <div className='header'>
                        <img src={customize.logo} alt='logo' width='150px' />
                        <h2>{t('LOGIN.University Information System')}</h2>
                    </div>
                    <div className='login-container'>
                        <div className='input-group'>
                            <div className='p-field'>
                                <label className='p-d-block'>{t('LOGIN.Username')}</label>
                                <InputText value={username} onChange={(e) => setUsernameInput(e.target.value)} />
                                {/* <small id='username2-help' className='p-error p-d-block'>Username is not available.</small> */}
                            </div>
                            <div className='p-field'>
                                <label className='p-d-block'>{t('LOGIN.Password')}</label>
                                <Password type='password' value={password} onChange={(e) => setPasswordInput(e.target.value)} toggleMask feedback={false} />
                                {/* <small id='username2-help' className='p-error p-d-block'>Username is not available.</small> */}
                            </div>

                        </div>
                        <div className='group'>
                            <div className='p-col-12'>
                                <Checkbox inputId='remember-me' onChange={e => setCheckboxStatus(e.checked)} checked={checkboxStatus}></Checkbox>
                                <label htmlFor='remember-me' style={{ marginLeft: '3px', cursor: 'pointer' }} className='p-checkbox-label'>{t('LOGIN.Remember Me')}</label>
                            </div>
                        </div>
                        <div>
                            <SecondaryButton type='submit' label={t('BUTTON.Login')} moreStyle='long-button' />
                        </div>
                    </div>
                </div>
            </form>
            <Toast ref={toast} position='bottom-center' className='login-toast' style={{ zIndex: '9 !important' }} />
        </>



    return (
        <div className='Login'>
            <PrimaryButton
                className='entry-exit'
                icon='pi pi-sign-in'
                tooltip={t('BUTTON.Login')}
                onClick={login}
            >
            </PrimaryButton>
            <Popup
                visible={buttonState === ButtonState.LOGIN}
                onHide={() => dispatch(setButtonState(ButtonState.DEFAULT))}
                content={loginContainer}
                className='popup'
            >
            </Popup>
        </div>
    )
}

import './QuickSearch.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoComplete } from 'primereact/autocomplete';
import { SearchRestApi } from '../../util/restapi/search';
import { useDispatch } from 'react-redux';
import { changeInfoVisibility, setSelectedFeatureForInfo, setSelectedFeatureForInfoEdit, setSelectedFeatureId, setUnit2Names } from '../../state/features/infoSlice';
import { useAppSelector } from '../../state/hooks';
import { RootState } from '../../state/store';
import { setButtonState } from '../../state/features/buttonStateSlice';
import { ButtonState } from '../shared/button-state/ButtonState';
import { UnitsRestApi } from '../../util/restapi/units';
import { setFeatureExtent, setSelectedFeature } from '../../state/features/selectedFeatureSlice';
import { isSelectedFeatureOnTable } from '../../state/features/tableSlice';
import { setSearchResult } from '../../state/features/searchSlice';
import { setQuickSearchValue, setValue } from '../../state/features/categoricalSearchSlice';

export const QuickSearch = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const [featureList, setFeatureList] = useState<any>();
    const [searchList, setSearchList] = useState<any>();
    const [data, setData] = useState<any>();
    const infoVisibility = useAppSelector((state: RootState) => state.info.visible);
    const quickSearchValue = useAppSelector((state: RootState) => state.categoricalSearch.quickSearchValue);
    const language: any = useAppSelector((state: RootState) => state.language.value);

    useEffect(() => {
        if (!infoVisibility) {
            dispatch(setQuickSearchValue(undefined))
        }
    }, [infoVisibility])

    useEffect(() => {
    }, [language, featureList])

    /**
     * Bu fonksiyon ile filtrelemeye göre arama listesi geliyor.
     */
    const getSearchList = (e: any) => {
        try {
            const quickSearchValue = e.query;
            if (quickSearchValue.length > 2) {
                SearchRestApi.getSearchResults(quickSearchValue).then((response: any) => {
                    dispatch(setSearchResult(response.data.result))
                    const res = response.data.result.map((properties: any) => { return properties.result })
                    const featuresNames = res.map((d: any) => { return d[0]['adi' + (language === 'tr' ? '' : ('_' + language))] })
                    const searchResponseList = response.data.result.map((s: any) => s)
                    const alphabeticalSearchList = featuresNames.sort()
                    setFeatureList(alphabeticalSearchList)
                    setSearchList(searchResponseList)
                    setData(res)
                });
            }
            if(quickSearchValue){
                dispatch(setValue(t('SEARCH.Open Education Faculty Offices and Units Search')))
            }
        }
        catch (error) {
            console.log('Arama İsteği Yapılamadı!');
            return;
        }
    }

    /**
     * Bu fonksiyon ile arama listesinden seçilen değer arama kısmına gönderilir.
     */
    const setSelectedFeatures = (e: any) => {
        dispatch(setQuickSearchValue(e.value))
        dispatch(changeInfoVisibility(true))
        dispatch(isSelectedFeatureOnTable(false))
        if (e.value) {
            const selectedFeatureProperties = data.find((feature: any) => feature[0]['adi' + (language === 'tr' ? '' : ('_' + language))] === e.value)
            dispatch(setSelectedFeatureForInfo(selectedFeatureProperties[0]))
            searchList.find((list: any) => {
                list.result.find((res: any) => {
                    if (res['adi' + (language === 'tr' ? '' : ('_' + language))] === e.value) {
                        dispatch(setSelectedFeatureForInfoEdit(list))
                        dispatch(setSelectedFeatureId(list.id));
                        dispatch(setSelectedFeature(list))
                        dispatch(setFeatureExtent(true))
                        const unitArray: any[] = []
                        UnitsRestApi.getUnits().then((unitRes) => {
                            const unitResult = unitRes.data.response
                            unitResult.find((unit: any) => {
                                if (unit.build_id === list.id) {
                                    unitArray.push(unit)
                                }
                            })
                            dispatch(setUnit2Names(unitArray));
                        })
                    }
                })
            })

        }
    }

    /**
     * Bu fonksiyon ile arama listesi temizlenir.
     */
    const clearSearch = () => {
        dispatch(changeInfoVisibility(false))
        dispatch(setFeatureExtent(false))
        dispatch(setSelectedFeatureForInfo(false))
        dispatch(setQuickSearchValue(undefined))
    }

    return (
        <div className='QuickSearch' onClick={() => dispatch(setButtonState(ButtonState.QUICK_SEARCH))}>
            <i className='pi pi-search' />
            <AutoComplete
                value={quickSearchValue}
                completeMethod={getSearchList}
                suggestions={featureList}
                onSelect={setSelectedFeatures}
                onChange={(e) => dispatch(setQuickSearchValue(e.value))}
                placeholder={t('SEARCH.Quick Search')}>
            </AutoComplete>
            {infoVisibility && quickSearchValue && <i className='pi pi-times' onClick={clearSearch} />}
        </div>
    )
}

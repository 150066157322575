import { Button, ButtonPositionType } from 'primereact/button';
interface IProps {
    label?: string,
    icon?: string,
    svg?: string,
    type?: any,
    iconPos?: ButtonPositionType,
    onClick?: () => void,
    moreStyle?: string,
    disabled?: boolean,
}

export const SecondaryButton = (props: IProps) => {
    const handleClick = () => {
        if (typeof props.onClick === 'function') {
            props.onClick();
        }
    }
    return (
        <Button
            label={props.label}
            icon={props.icon}
            iconPos={props.iconPos}
            className={`button-secondary p-button-sm ${props.moreStyle}`}
            onClick={handleClick}
            type={props.type}
            disabled={props.disabled}
        >
            {props.svg && <img src={props.svg} alt='icon'/>}
        </Button>
    )
}

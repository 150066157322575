import './Navbar.scss';
import { Menubar } from 'primereact/menubar';
import { useDispatch } from 'react-redux';
import { RootState } from '../../../state/store';
import { getCustomize } from '../../../state/features/customizeSlice';
import { useEffect } from 'react';
import { CustomizeRestApi } from '../../../util/restapi/customize';
import { QuickSearch } from '../../quick-search/QuickSearch';
import { CategoricalSearch } from '../../categorical-search/CategoricalSearch';
import { Language } from '../../language/Language';
import { Login } from '../../login/Login';
import { useTranslation } from 'react-i18next';
import Logout from '../../logout/Logout';
import { useAppSelector } from '../../../state/hooks';

export const Navbar = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const customize = useAppSelector((state: RootState) => state.customize.value);
    const token = useAppSelector((state: RootState) => state.login.token);

    useEffect(() => {
        CustomizeRestApi.customize().then((res: any) => {
            dispatch(getCustomize(res.data.customize));
        })
    }, [])

    /**
     * Navbar içeriği burada verildi.
     */
    const start =
        <div className='menubar-items'>
            <a href='https://www.anadolu.edu.tr/' target='blank' className='customer-link'>
                <img className='anadolu-logo' src={customize.logo} />
                <div className='customer'>
                    <div style={{
                        fontWeight: '500',
                        fontSize: '15px',
                        paddingLeft: '5px',
                        paddingTop: '6px'
                    }}>
                        ANADOLU
                        {t('CUSTOMER')}
                    </div>
                    {/* <div style={{
                        fontWeight: '500',
                        fontSize: '12px'
                    }}>
                    </div> */}
                </div>
            </a >
            <div className='center-item'>
                <QuickSearch></QuickSearch>
                <CategoricalSearch></CategoricalSearch>
            </div>
            <div className='right-item'>
                <Language />
                {!token && <Login />}
                {token && <Logout />}
            </div>
        </div >

    return (
        <Menubar className='menubar' start={start} />
    )
}

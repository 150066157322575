import { Button, ButtonPositionType, ButtonProps } from 'primereact/button';
import React from 'react';
interface IProps {
    label?: string,
    icon?: string;
    iconPos?: ButtonPositionType,
    svg?: string,
    onClick?: () => void,
    active?: boolean
    className?: string,
    tooltip?: string,
    moreStyle?: string,
    disabled?: boolean,
    type?: any
}
export const PrimaryButton: React.FC<IProps> = (props) => {

    const handleClick = () => {
        if (typeof props.onClick === 'function') {
            props.onClick();
        }
    }

    return (
        <Button
            label={props.label}
            icon={props.icon}
            iconPos={props.iconPos}
            tooltip={props.tooltip}
            tooltipOptions={{ position: 'top', showDelay: 1000, hideDelay: 300 }}
            className={`button-primary p-button-sm ${props.moreStyle} ${props.active ? 'button-primary-active' : ''} }`}
            onClick={handleClick}
            disabled={props.disabled}
            type={props.type}
        >
            {props.svg && <img src={props.svg} />}
        </Button>
    )
}

import './AddDigitization.scss'
import { Popup } from '../../../../shared/popup/Popup'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../../state/hooks';
import { setAddDigiCancelActive, openAddDigiPopup, setInputValues, startAddDigitization } from '../../../../../state/features/digitizationSlice';
import { InputText } from 'primereact/inputtext';
import { SyntheticEvent, useContext, useRef, useState, useEffect } from 'react';
import { PrimaryButton } from '../../../../shared/buttons/primary-button/PrimaryButton';
import { SecondaryButton } from '../../../../shared/buttons/secondary-button/SecondaryButton';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { AttachmentRestApi } from '../../../../../util/restapi/attachment';
import { DigiCallbackContext } from './DigiCallbackContextProvider';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { DigitizationRestApi } from '../../../../../util/restapi/digitization';
import { Message } from 'primereact/message';

// import { useForm, Controller } from 'react-hook-form';
/* 
 *  Yeni bir feature oluştururken propertylerin yazıldığı formun bulunduğu component.
 *
*/

export const AddDigitization = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const digiCallback = useContext(DigiCallbackContext)
    const fileUploadRef = useRef<any>(null);
    const dataColumns: any = useAppSelector((state) => state.digitization.dataColumns);
    const allProperties = useAppSelector((state) => state.digitization.dataColumns);
    const layer = useAppSelector((state) => state.digitization.layer)
    const [totalSize, setTotalSize] = useState(0);
    const [submitFiles, setSubmitFiles] = useState<any>([]);
    const [isValid, setIsValid] = useState(false);
    const [domainsOfCity, setDomainsOfCity] = useState<any>([])
    const [domainsOfDistincts, setDomainsOfDistincts] = useState<any>([])
    const [domainsOfNeighborhoods, setDomainsOfNeighborhoods] = useState<any>([])
    const provinceProperties: any = useAppSelector((state) => state.digitization.provinceProperties)
    let notNullableProperties: any = []

    let arrayForSort = []
    let cityProperties: any = []
    let distinctsOfCity: any = []
    let neighborhoods: any = []

    // const { control, formState: { errors }, reset } = useForm({  });

    const requiredFields = () => {
        allProperties.map((property: any) => {
            // nullable olamayan fieldları bi arraye atıyoruz
            if (!property.nullable) {
                notNullableProperties.push(property)
            }
        })
        const filteredProperties = notNullableProperties.filter((property: any) => (property.name !== 'id' && property.name !== 'createdDate' && property.name !== 'createdBy' && property.name !== 'geom' && property.type !== 'Attachment'))

        if (filteredProperties.length === 0) {
            setIsValid(true)
        }
        filteredProperties.map((property: any) => {
            if (property.val && property.val !== null) {
                setIsValid(true)
            }
            else {
                setIsValid(false)
                return
            }
        })
    }

    useEffect(() => {
        requiredFields()
    }, [allProperties])

    arrayForSort = [...dataColumns]
    // @ts-ignore
    const alphabeticalDataColumns = arrayForSort.sort((a, b) => {
        const textA = a.alias.toUpperCase();
        const textB = b.alias.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    const closeAddPopup = () => {
        dispatch(setAddDigiCancelActive(true))
        dispatch(openAddDigiPopup(false))
        dispatch(startAddDigitization(false))
    }

    const onChangeDateFormat = (fieldId: number, e: any) => {
        const offset = e.getTimezoneOffset()
        e = new Date(e.getTime() - (offset * 60 * 1000))
        onChangeInput(fieldId, e.toISOString().split('T')[0])
    }

    const onChangeInput = (id: number, e: any, type?: any) => {
        // il setlendiyse ilce icindeki domainleri değiştirmek için istek yap
        if (type === 'City') {
            setDistincts(e.il_id)
        }
        // ilçe setlendiyse mahalle icindeki domainleri değiştirmek icin istek yap
        if (type === 'Distinct') {
            setNeighborhoods(e.ilce_id)
        }

        dispatch(setInputValues({ id, e }))

        // mevcut kullanim sekli ve mevcut kullanim sekli 1 aynı degerle setleniyor 
        if (id === 3773) {
            dispatch(setInputValues({ id: 3774, e }))
        }
    }

    const handleSubmit = (e: SyntheticEvent) => {
        e.preventDefault();
        dispatch(startAddDigitization(true))
        dispatch(openAddDigiPopup(false))
        const layerId = layer.id;
        digiCallback.setCallbackFunction((params) => {
            return new Promise(async resolve => {
                if (submitFiles.length > 0) {
                    for (const file of submitFiles) {
                        const f = new FormData()
                        f.append('file', file)
                        AttachmentRestApi.addAttachment(layerId, +params, f).then((res: any) => {
                        }).catch((err: any) => { console.error(err); })
                        resolve(true)
                    }
                }
            })
        })
    }

    const onTemplateRemove = (file: any, callback: any) => {
        setTotalSize(totalSize - file.size);
        callback();
    }
    const onTemplateSelect = (e: any) => {
        let _totalSize = totalSize;
        [...e.files].forEach((file: any) => {
            setSubmitFiles([...e.files])
            _totalSize += file.size;
        });
        setTotalSize(_totalSize);
    }

    const onTemplateClear = () => {
        setTotalSize(0);
    }

    // tüm illeri getirir ve değişkene setler
    const setCities = () => {
        DigitizationRestApi.getCities().then((res) => {
            const features = res.data.features
            features.map((feature: any) => {
                cityProperties.push(feature.properties)
            })
            setDomainsOfCity(cityProperties)
        })
    }

    // seçilen ildeki ilçeleri getirir
    const setDistincts = (cityId: number) => {
        DigitizationRestApi.getDistincts(cityId).then((res) => {
            const features = res.data.features
            features?.map((feature: any) => {
                distinctsOfCity.push(feature.properties)
            })
            setDomainsOfDistincts(distinctsOfCity)
        })
    }

    // seçilen ilçedeki mahalleleri getirir
    const setNeighborhoods = (distinctId: number) => {
        DigitizationRestApi.getNeighborhoods(distinctId).then((res) => {
            const features = res.data.features
            features?.map((feature: any) => {
                neighborhoods.push(feature.properties)
            })
            setDomainsOfNeighborhoods(neighborhoods)
        })
    }


    // cizim(sayisallastirma) yapildiginda il,ilce ve mahalle setleniyor
    useEffect(() => {
        setCities();
        setDistincts(provinceProperties.il_id)
        setNeighborhoods(provinceProperties.ilce_id)
    }, [provinceProperties])
    useEffect(() => {
    }, [alphabeticalDataColumns])


    const headerTemplate = (options: any) => {
        const { className, chooseButton } = options;

        return (
            <div
                className={className}
                style={{
                    backgroundColor: 'transparent',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    padding: '1.25rem 0',
                    border: 'none',

                }}>
                {t('EDIT.Upload')}
                {chooseButton}
            </div>
        );
    }
    const chooseOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'file-upload-icon' };

    const itemTemplate = (file: any, props: any) => {
        return (
            <div className='flex align-items-center flex-wrap'>
                <div className='flex align-items-center' style={{ width: '100%' }}>
                    <img role='presentation' src={file.objectURL ? file.objectURL : 'customize-icons/file.svg'} style={{ width: '214px', height: '150px', backgroundColor: 'lightgray', border: '1px solid lightgray', }} />
                    <span className='flex flex-column text-left ml-3'>
                        {file.name}
                    </span>
                </div>
                <div className='file-footer'>
                    <Tag value={props.formatSize} className='format-size' />
                    <Button type='button' icon='pi pi-times' className='delete-file' onClick={() => onTemplateRemove(file, props.onRemove)} />
                </div>
            </div>
        )
    }

    const addDigi =
        <>
            <form onSubmit={handleSubmit} action='#'>
                <h3 className='add-digi-header'> {t('DIGI.Add Feature')}</h3>
                <div className='add-form-data-container'>
                    <div className='form-data-properties'>
                        {
                            alphabeticalDataColumns.map((fp: any) => {

                                if (fp.name !== 'bbox' && fp.name !== 'createdDate' && fp.name != 'createdBy' && fp.name != 'geom') {
                                    return (
                                        <div className='form-data' key={fp.id} >
                                            {fp && fp.type === 'Attachment' &&
                                                <div className='attachment-container'>
                                                    <div className='attachment-header'>{fp.alias}</div>
                                                    <div className='file-container'>
                                                        <div className='file-selection'>
                                                            <FileUpload
                                                                className='file-upload-container'
                                                                ref={fileUploadRef}
                                                                name='demo[]'
                                                                url='https://primefaces.org/primereact/showcase/upload.php'
                                                                multiple
                                                                onSelect={onTemplateSelect}
                                                                onError={onTemplateClear}
                                                                onClear={onTemplateClear}
                                                                headerTemplate={headerTemplate}
                                                                itemTemplate={itemTemplate}
                                                                chooseOptions={chooseOptions}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {fp && fp.type === 'Text' &&
                                                <>
                                                    {!fp.nullable ? <label htmlFor='field' className='p-d-block'>{fp.alias} *</label> : <label htmlFor='field' className='p-d-block'>{fp.alias}</label>}
                                                    <InputText key={fp.alias} id={fp.alias} value={fp.val ? fp.val : ''} onChange={(e) => onChangeInput(fp.id, e.target.value)} required={!fp.nullable} maxLength={250} className='p-inputtext-sm p-d-block p-mb-2' />
                                                    {/* {!fp.nullable && !fp.val && <small className="p-error">{"Field is required"}</small>} */}
                                                </>
                                            }
                                            {fp && fp.type === 'Integer' &&
                                                <>
                                                    {!fp.nullable ? <label htmlFor='integeronly' className='p-d-block'>{fp.alias} *</label> : <label htmlFor='integeronly' className='p-d-block'>{fp.alias}</label>}
                                                    <InputNumber disabled={fp.name === 'layer_id'} inputId='integeronly' id={fp.alias} value={fp.val ? fp.val : ''} required={!fp.nullable} onChange={(e: any) => onChangeInput(fp.id, e.value)} min={0} max={2147483647} useGrouping={false} className='p-inputtext-sm p-d-block p-mb-2' />
                                                </>
                                            }
                                            {fp && (fp.type === 'Float' || fp.type === 'Area') &&
                                                <>
                                                    {!fp.nullable ? <label htmlFor='minmaxfraction' className='p-d-block'>{fp.alias} *</label> : <label htmlFor='minmaxfraction' className='p-d-block'>{fp.alias}</label>}
                                                    <InputNumber inputId='minmaxfraction' mode='decimal' id={fp.alias} value={fp.val ? fp.val : ''} required={!fp.nullable} onChange={(e: any) => onChangeInput(fp.id, e.value)} className='p-inputtext-sm p-d-block p-mb-2' />
                                                </>
                                            }
                                            {fp && fp.type === 'Date' &&
                                                <>
                                                    {!fp.nullable ? <label htmlFor='date' className='p-d-block'>{fp.alias} *</label> : <label htmlFor='date' className='p-d-block'>{fp.alias}</label>}
                                                    <Calendar id='date' value={fp.val ? fp.val : ''} onChange={(e: any) => onChangeDateFormat(fp.id, e.value)} required={!fp.nullable} dateFormat='dd/mm/yy' />
                                                </>
                                            }
                                            {fp && fp.type === 'City' &&
                                                <>
                                                    {!fp.nullable ? <label htmlFor='city' className='p-d-block'>{fp.alias} *</label> : <label htmlFor='city' className='p-d-block'>{fp.alias}</label>}
                                                    <Dropdown className='input-domain' id='city' value={fp.valObj ? fp.valObj : ''} options={domainsOfCity} required={!fp.nullable} placeholder={t('Select')} optionLabel='adi' onChange={(e: any) => onChangeInput(fp.id, e.value, fp.type)} style={{ height: '36px', display: 'flex', alignItems: 'center' }} />
                                                </>
                                            }
                                            {fp && fp.type === 'Distinct' &&
                                                <>
                                                    {!fp.nullable ? <label htmlFor='distinct' className='p-d-block'>{fp.alias} *</label> : <label htmlFor='distinct' className='p-d-block'>{fp.alias}</label>}
                                                    <Dropdown className='input-domain' id='distinct' value={fp.valObj ? fp.valObj : ''} options={domainsOfDistincts} required={!fp.nullable} placeholder={t('Select')} optionLabel='adi' onChange={(e: any) => onChangeInput(fp.id, e.value, fp.type)} style={{ height: '36px', display: 'flex', alignItems: 'center' }} />
                                                </>
                                            }
                                            {fp && fp.type === 'Neighbourhood' &&
                                                <>
                                                    {!fp.nullable ? <label htmlFor='neighborhood' className='p-d-block'>{fp.alias} *</label> : <label htmlFor='neighborhood' className='p-d-block'>{fp.alias}</label>}
                                                    <Dropdown className='input-domain' id='neighborhood' value={fp.valObj ? fp.valObj : ''} options={domainsOfNeighborhoods} required={!fp.nullable} placeholder={t('Select')} optionLabel='adi' onChange={(e: any) => onChangeInput(fp.id, e.value, fp.type)} style={{ height: '36px', display: 'flex', alignItems: 'center' }} />
                                                </>
                                            }
                                            {fp && fp.type === 'Domain' &&
                                                <>
                                                    {!fp.nullable ? <label htmlFor='domain' className='p-d-block'>{fp.alias} *</label> : <label htmlFor='domain' className='p-d-block'>{fp.alias}</label>}
                                                    {
                                                        fp.name !== 'mevcut_kullanim_kodu' && <Dropdown className='input-domain' id='domain' value={fp.valObj ? fp.valObj : ''} required={!fp.nullable} options={fp.domain} placeholder={t('Select')} optionLabel='desc' onChange={(e: any) => onChangeInput(fp.id, e.value)} style={{ height: '36px', display: 'flex', alignItems: 'center' }} />
                                                    }
                                                    {
                                                        fp.name === 'mevcut_kullanim_kodu' && <Dropdown disabled className='input-domain' id='domain' value={fp.valObj ? fp.valObj : ''} required={!fp.nullable} options={fp.domain} placeholder={t('Select')} optionLabel='desc_code' style={{ height: '36px', display: 'flex', alignItems: 'center' }} />
                                                    }
                                                </>
                                            }
                                        </div>)
                                }
                            })
                        }
                    </div>
                </div>
                <div className='add-confirm-buttons'>
                    <PrimaryButton type={'button'} label={t('BUTTON.Cancel')} onClick={closeAddPopup}></PrimaryButton>
                    <SecondaryButton label={t('BUTTON.Save')} disabled={!isValid}></SecondaryButton>
                </div>
            </form>

        </>
    return (
        <>
            <Popup
                className='add-feature-container'
                visible={true}
                onHide={closeAddPopup}
                content={addDigi}>
            </Popup>
        </>
    )
}
import './CategoricalSearch.scss'
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react'
import { useAppSelector } from '../../state/hooks';
import { CategoricalSearchRestApi } from '../../util/restapi/categoricalSearch';
import { UnitsRestApi } from '../../util/restapi/units';
import { changeInfoVisibility, setSelectedFeatureForInfo, setSelectedFeatureForInfoEdit, setSelectedFeatureId, setUnit2Names } from '../../state/features/infoSlice';
import { useDispatch } from 'react-redux';
import { RootState } from '../../state/store';
import { setFeatureExtent, setSelectedFeature } from '../../state/features/selectedFeatureSlice';
import { setButtonState } from '../../state/features/buttonStateSlice';
import { ButtonState } from '../shared/button-state/ButtonState';
import { isSelectedFeatureOnTable } from '../../state/features/tableSlice';
import { useCurrentWidth } from '../shared/resize/Resize';
import { InputText } from 'primereact/inputtext';
import { CityGeomRestApi } from '../../util/restapi/cityGeometries';
import { setCityFeature, setMainHeaderFeature } from '../../state/features/cityFeatureSlice';
import { setQuickSearchValue, setValue } from '../../state/features/categoricalSearchSlice';

export const CategoricalSearch = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [categoricalSearchValue, setCategoricalSearchValue] = useState<any>([]);
    const [features, setFeatures] = useState<any>([]);
    const [aofFeatures, setAofFeatures] = useState<any>([]);
    const services = useAppSelector(state => state.service.services);
    const infoVisibility = useAppSelector((state: RootState) => state.info.visible);
    const categoricalValue = useAppSelector((state: RootState) => state.categoricalSearch.categoricalSearchValue);
    const language = useAppSelector((state: RootState) => state.language.value);
    const width = useCurrentWidth();
    const [mobile, setMobile] = useState(false);
    const [toggleMenu, setToggleMenu] = useState(false);
    const cities: any = useAppSelector((state) => state.digitization.allCities)

    let dataArray: any[] = []

    useEffect(() => {
        if (width <= 767) {
            setMobile(true)
        } else {
            setMobile(false)
        }
    }, [width])

    useEffect(() => {
    }, [language])

    useEffect(() => {
        if (!infoVisibility) {
            dispatch(setValue(t('SEARCH.Open Education Faculty Offices and Units Search')))
        }
    }, [infoVisibility, language])

    useEffect(() => {
        let categoryNames: any[] = [];
        let aobNames: any[] = [];
        //Domainleri servicesten aldık.

        services.find((service: any) => {
            if (service.name === 'tasinmazlar') {
                service.layers.find((layer: any) => {
                    //aliaslari burdan alcaz
                    if (layer.name === 'yapilar_usr') {
                        layer.fields.find((field: any) => {
                            if (field.domain_id === 25) {
                                field.domain.find((domain: any) => {
                                    if (domain.desc) {
                                        categoryNames.push({
                                            id: domain.id,
                                            key: domain.desc,
                                            label: domain['desc' + (language === 'tr' ? '' : ('_' + language))],
                                            children: [],
                                        })
                                    }
                                })
                            }
                        })
                    }
                    
                    // sehirleri 2 defa eklemeye calistigi icin boyle bi kontrol koyduk
                    if (cities && aobNames.length !== 81) {
                        cities.map((city: any) => {
                            aobNames.push({
                                id: city.il_id,
                                key: city.il_id,
                                label: city.adi,
                                children: [],
                            })
                        })
                    }
                });
            }
        })
        setFeaturesInDomains(categoryNames, aobNames);
    }, [language, services.length])

    /**
      * Bu fonksiyon ile kategorik aramanın değişimini dinliyoruz.
    */
    const selectedNode = (e: any) => {
        // setSelectedNodeKey(e.node.label);
        // acıkogretim ve kampuslere basınca farklı extentlere gidiyor 

        const selectedFeature = features.find((feature: any) => {
            return feature.properties['adi' + (language === 'tr' ? '' : ('_' + language))] === e.label
        });

        if (selectedFeature) {
            dispatch(setSelectedFeature(selectedFeature))
            dispatch(setFeatureExtent(true))

            const splittedArray = selectedFeature.id.split('.');
            dispatch(setSelectedFeatureId(splittedArray[1]));
            getUnits(+splittedArray[1], selectedFeature.properties)
            dispatch(setButtonState(ButtonState.DEFAULT))
        }

        // featurelarda bulamadıysa aof featurelarına bakıyor
        if (!selectedFeature) {
            dispatch(setFeatureExtent(false))
            const aofSelectedFeature = aofFeatures.find((feature: any) => {
                return feature.properties['adi' + (language === 'tr' ? '' : ('_' + language))] === e.label
            });

            if (aofSelectedFeature) {
                dispatch(setSelectedFeature(aofSelectedFeature))
                dispatch(setFeatureExtent(true))
                const splittedArray = aofSelectedFeature.id.split('.');
                getUnits(+splittedArray[1], aofSelectedFeature.properties)
                dispatch(setButtonState(ButtonState.DEFAULT))
            }
        }

        if (e) {
            dispatch(setQuickSearchValue(''))
        }
    }

    const getUnits = (selectedFeatureId: number, selectedFeatureProperty: object) => {
        let unit2Names: any[] = []
        UnitsRestApi.getUnits().then((res: any) => {
            res.data.response.find((build: any) => {
                if (build.build_id === selectedFeatureId) {
                    unit2Names.push(build)
                }
            })
            dispatch(setSelectedFeatureId(selectedFeatureId))
            dispatch(setSelectedFeatureForInfo(selectedFeatureProperty));
            dispatch(setSelectedFeatureForInfoEdit(selectedFeatureProperty));
            dispatch(setUnit2Names(unit2Names));
            dispatch(changeInfoVisibility(true))
            dispatch(isSelectedFeatureOnTable(false))
        })
    }

    /**
     * Bu fonksiyon featureların hangi domain name ile eşleştiğini bularak guruplar.
     */
    const setFeaturesInDomains = (categoryNames: any, aobNames: any) => {
        dataArray = [
            {
                key: 0,
                label: (t('SEARCH.Campuses')),
                children: categoryNames,
            },
            {
                key: 1,
                label: (t('SEARCH.Open Education Offices')),
                children: aobNames
            }
        ]

        CategoricalSearchRestApi.getFeatures().then((res) => {
            dataArray.find((data: any) => {
                if (data.key !== 0) {
                    return;
                }
                if (data.children.length > 0) {
                    data.icon = 'pi pi-angle-right'
                }
                data.children.find((d: any) => {
                    setFeatures(res.data.features);
                    res.data.features.find((feature: any) => {
                        if (feature.properties.tip_id === d.id) {
                            feature.properties.key = feature.properties['adi' + (language === 'tr' ? '' : ('_' + language))]
                            // feature.properties.adi
                            feature.properties.label = feature.properties['adi' + (language === 'tr' ? '' : ('_' + language))]
                            // feature.properties.adi
                            d.children.push(feature.properties);
                        }
                    })
                    if (d.children.length > 0) {
                        d.icon = 'pi pi-angle-right'
                    }
                })
            })
        })

        CategoricalSearchRestApi.getAOB().then((res) => {
            setAofFeatures(res.data.features);
            dataArray.find((data: any) => {
                if (data.key !== 1) {
                    return;
                }
                if (data.children.length > 0) {
                    data.icon = 'pi pi-angle-right'
                }
                data.children.find((d: any) => {
                    res.data.features?.find((feature: any) => {
                        if (feature.properties.il === d.id) {
                            feature.properties.key = language === 'tr' ? feature.properties['adi'] : feature.properties['adi_' + language]
                            feature.properties.label = language === 'tr' ? feature.properties['adi'] : feature.properties['adi_' + language]
                            d.children.push(feature.properties);
                        }
                    })
                    if (d.children.length > 0) {
                        d.icon = 'pi pi-angle-right'
                    }
                })
            })
        })
        setCategoricalSearchValue(dataArray);
    }

    const showMenu = () => {
        setToggleMenu(!toggleMenu)
    }

    const showCategories = (clickedMain: any) => {
        let newArr = [...categoricalSearchValue];
        const index = categoricalSearchValue.findIndex((value: any) => clickedMain.label === value.label)
        newArr[index].icon = clickedMain.icon === 'pi pi-angle-right' ? 'pi pi-angle-down' : 'pi pi-angle-right'
        setCategoricalSearchValue(newArr)

        // kampüs haritası ve aöf haritası arasındaki geçiş
        if (clickedMain.key === 0) {
            const yunusEmreKampus = {
                "type": "FeatureCollection",
                "features": [
                    {
                        "type": "Feature",
                        "properties": {},
                        "geometry": {
                            "type": "Polygon",
                            "coordinates": [
                                [
                                    [
                                        30.48985004425049,
                                        39.78641158617536
                                    ],
                                    [
                                        30.51066398620605,
                                        39.78641158617536
                                    ],
                                    [
                                        30.51066398620605,
                                        39.796551471125866
                                    ],
                                    [
                                        30.48985004425049,
                                        39.796551471125866
                                    ],
                                    [
                                        30.48985004425049,
                                        39.78641158617536
                                    ]
                                ]
                            ]
                        }
                    }
                ]
            }
            dispatch(setMainHeaderFeature(yunusEmreKampus.features[0]));
        }
        else if (clickedMain.key === 1) {
            const aof = {
                "type": "FeatureCollection",
                "features": [
                    {
                        "type": "Feature",
                        "properties": {},
                        "geometry": {
                            "type": "Polygon",
                            "coordinates": [
                                [
                                    [
                                        24.63134765625,
                                        35.71083783530009
                                    ],
                                    [
                                        45.19775390625,
                                        35.71083783530009
                                    ],
                                    [
                                        45.19775390625,
                                        42.79540065303723
                                    ],
                                    [
                                        24.63134765625,
                                        42.79540065303723
                                    ],
                                    [
                                        24.63134765625,
                                        35.71083783530009
                                    ]
                                ]
                            ]
                        }
                    }
                ]
            }
            dispatch(setMainHeaderFeature(aof.features[0]));
        }
    }

    const showSubCategories = (clickedMain: any, clickedCategory: any) => {
        let newArr = [...categoricalSearchValue];
        const index = categoricalSearchValue.findIndex((value: any) => clickedMain.label === value.label)
        const childIndex = categoricalSearchValue[index].children.findIndex((value: any) => clickedCategory.label === value.label)
        newArr[index].children[childIndex].icon = clickedCategory.icon === 'pi pi-angle-right' ? 'pi pi-angle-down' : 'pi pi-angle-right'
        setCategoricalSearchValue(newArr)

        if (clickedMain.key === 1) {
            CityGeomRestApi.getCityGeometries(clickedCategory.id).then((res: any) => {
                dispatch(setCityFeature(res.data.features[0]));
            })
        }
    }

    const showSubCategoryDetails = (clickedSubCategory: any) => {
        dispatch(setValue(clickedSubCategory.label));
        setToggleMenu(false);
        selectedNode(clickedSubCategory)
    }

    const handleChildClick = (e: any) => {
        e.stopPropagation();
    }

    return (
        <div className='CategoricalSearch'>
            {!mobile && <div className='CategoricalSearch' onClick={() => dispatch(setButtonState(ButtonState.CATEGORICAL_SEARCH))}>
                <div className='p-input-icon-right' onClick={showMenu}>
                    <i className='pi pi-angle-down' />
                    <InputText value={categoricalValue} disabled className={toggleMenu ? 'focused' : ''} />
                    <div onClick={handleChildClick} >
                        {toggleMenu &&
                            <div className='categorical-list'>
                                {categoricalSearchValue.map((main: any) =>
                                    <div key={main.key}>
                                        <div className='group' onClick={() => showCategories(main)}>
                                            <div className='main-header'>
                                                <i className={main.icon} />
                                                {main.label}
                                            </div>
                                        </div>
                                        {main.icon === 'pi pi-angle-down' &&
                                            <div className='children'>
                                                {
                                                    main.children.map((child: any) =>
                                                        <React.Fragment key={child.key}>
                                                            {child.children.length > 0 && <div className='child' onClick={() => showSubCategories(main, child)}>
                                                                <i className={child.icon} />
                                                                {child.label}
                                                            </div>}
                                                            {child.icon === 'pi pi-angle-down' && <div className='subchild'>
                                                                {
                                                                    child.children.map((c: any) =>
                                                                        <div className='c' key={c.key} onClick={() => showSubCategoryDetails(c)}>
                                                                            {c.label}
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>}
                                                        </React.Fragment>
                                                    )
                                                }
                                            </div>}
                                    </div>
                                )}
                            </div>}
                    </div>
                </div>
            </div>}

            {mobile && <div className='MobileCategoricalSearch'>
                <div className='mobile-header'>
                    <i className='pi pi-arrow-left' onClick={() => dispatch(setButtonState(ButtonState.HAMBURGER))} />
                    <span>{t('MOBILE.Categorical Search')}</span>
                </div>
                <div className='p-input-icon-right' onClick={showMenu}>
                    <i className='pi pi-angle-down' />
                    <InputText value={categoricalValue} disabled className={toggleMenu ? 'focused' : ''} />
                    <div onClick={handleChildClick} >
                        {toggleMenu &&
                            <div className='categorical-list'>
                                {categoricalSearchValue.map((main: any) =>
                                    <div key={main.key}>
                                        <div className='group' onClick={() => showCategories(main)}>
                                            <div className='main-header'>
                                                <i className={main.icon} />
                                                {main.label}
                                            </div>
                                        </div>
                                        {main.icon === 'pi pi-angle-down' &&
                                            <div className='children'>
                                                {
                                                    main.children.map((child: any) =>
                                                        <React.Fragment key={child.key}>
                                                            <div className='child' onClick={() => showSubCategories(main, child)}>
                                                                <i className={child.icon} />
                                                                {child.label}
                                                            </div>
                                                            {child.icon === 'pi pi-angle-down' && <div className='subchild'>
                                                                {
                                                                    child.children.map((c: any) =>
                                                                        <div className='c' key={c.key} onClick={() => showSubCategoryDetails(c)}>
                                                                            {c.label}
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>}
                                                        </React.Fragment>
                                                    )
                                                }
                                            </div>}
                                    </div>
                                )}
                            </div>}
                    </div>
                </div>
            </div>}
        </div>
    )
}
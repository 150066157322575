import './EditObject.scss';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { changeEditPopupFromInfoVisibility, changeEditPopupVisibility } from '../../state/features/popupSlice';
import { useAppSelector } from '../../state/hooks';
import { PrimaryButton } from '../shared/buttons/primary-button/PrimaryButton';
import { SecondaryButton } from '../shared/buttons/secondary-button/SecondaryButton';
import { Popup } from '../shared/popup/Popup'
import { Image } from 'primereact/image';
import { AttachmentRestApi } from '../../util/restapi/attachment';
import { IAttachment } from '../../util/model/attachment';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { saveAs } from 'file-saver';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { cancelButtonActive, setEditSave, setFeatures, setInputValues } from '../../state/features/editObjectSlice';
import { From, Mode, TransactWfs } from '../containers/map-container/map/digitization/TransactWfs';
import { LayerRestApi } from '../../util/restapi/layer';
import { setAttachment } from '../../state/features/attachmentSlice';
import { Dropdown } from 'primereact/dropdown';
import { setOpenGeometryEdit } from '../../state/features/digitizationSlice';
import { Calendar } from 'primereact/calendar';
import { DigitizationRestApi } from '../../util/restapi/digitization';

export const EditObject = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const properties = useAppSelector((state) => state.editObject.properties)
    const selectedFeatureForAttachment: any = useAppSelector((state) => state.info.edit);
    const selectedFeatureId: any = useAppSelector((state) => state.info.featureId);
    const header = useAppSelector((state) => state.editObject.header)
    const inputValObj = useAppSelector((state) => state.editObject.inputValues)
    const dataColumns = useAppSelector((state) => state.editObject.dataColumns)
    const token = useAppSelector((state) => state.login.token)
    const [image, setImage] = useState<any>()
    const [otherFile, setOtherFile] = useState<any>()
    const [submitFiles, setSubmitFiles] = useState<any>()
    const [totalSize, setTotalSize] = useState(0);
    const [deletedFileId, setDeletedFileId] = useState<any>();
    const [saveEdit, setSaveEdit] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const fileUploadRef = useRef<any>(null);
    const layerData = useAppSelector((state) => state.info.layer);
    const layerDataTable = useAppSelector((state) => state.table.layer);
    const openEditFromInfo: boolean = useAppSelector((state) => state.popup.openEditPopupFromInfo)
    const selectedFeature: any = useAppSelector((state) => state.selectedFeature.feature)
    const [domainsOfCity, setDomainsOfCity] = useState<any>([])
    const [domainsOfDistincts, setDomainsOfDistincts] = useState<any>([])
    const [domainsOfNeighborhoods, setDomainsOfNeighborhoods] = useState<any>([])
    let notNullableProperties: any = []
    let finded: any = {}
    let cityProperties: any = []
    let distinctsOfCity: any = []
    let neighborhoods: any = []

    useEffect(() => {
    }, [token])

    useEffect(() => {
    }, [saveEdit])

    useEffect(() => {
        setCities();
        setDistincts(properties.il)
        setNeighborhoods(properties.ilce)
    }, [properties])

    // tüm illeri getirir ve değişkene setler
    const setCities = () => {
        DigitizationRestApi.getCities().then((res) => {
            const features = res.data.features
            features.map((feature: any) => {
                cityProperties.push(feature.properties)
            })
            setDomainsOfCity(cityProperties)
        })
    }

    // seçilen ildeki ilçeleri getirir
    const setDistincts = (cityId: number) => {
        DigitizationRestApi.getDistincts(cityId).then((res) => {
            const features = res.data.features
            features?.map((feature: any) => {
                distinctsOfCity.push(feature.properties)
            })
            setDomainsOfDistincts(distinctsOfCity)
        })
    }

    // seçilen ilçedeki mahalleleri getirir
    const setNeighborhoods = (distinctId: number) => {
        DigitizationRestApi.getNeighborhoods(distinctId).then((res) => {
            const features = res.data.features
            features?.map((feature: any) => {
                neighborhoods.push(feature.properties)
            })
            setDomainsOfNeighborhoods(neighborhoods)
        })
    }

    const requiredFields = () => {
        if(layerDataTable){
            layerDataTable.fields?.map((field: any) => {
                // nullable olamayan fieldları bi arraye atıyoruz
                if (!field.nullable) {
                    notNullableProperties.push(field)
                }
            })

            const filteredProperties = notNullableProperties.filter((property:any)=>(property.name !== 'id' && property.name !== 'createdDate' && property.name !== 'createdBy' && property.name !=='geom' && property.type !== 'Attachment' ))
            if(filteredProperties.length === 0 ){
                setIsValid(true)
            }
            filteredProperties.map((property: any) => {
                if (properties[property.name] && properties[property.name] !== null) {
                    setIsValid(true)
                }
                else {
                    setIsValid(false)
                    return
                }
            })

        }

        if(layerData){
            layerData.fields?.map((field: any) => {
                // nullable olamayan fieldları bi arraye atıyoruz
                if (!field.nullable ) {
                    notNullableProperties.push(field)
                }
            })

            const filteredProperties = notNullableProperties.filter((property:any)=>(property.name !== 'id' && property.name !== 'createdDate' && property.name !== 'createdBy' && property.name !=='geom'))

            filteredProperties.map((property: any) => {
                if (properties[property.name] && properties[property.name] !== null) {
                    setIsValid(true)
                }
                else {
                    setIsValid(false)
                    return
                }
            })
        }

    }

    useEffect(() => {
        requiredFields()
    }, [inputValObj])

    /**
     * Veritabanindan gelen belgeler
     */
    useEffect(() => {
        const layerId = selectedFeatureForAttachment.layer_id
        if (selectedFeatureId) {
            var featureId = selectedFeatureId
        } else {
            featureId = selectedFeatureForAttachment.id
        }
        setImage(null)
        setOtherFile(null)
        AttachmentRestApi.getAttachmentInfo(layerId, featureId).then((res: any) => {
            setImage(null)
            setOtherFile(null)
            const response = res.data
            const pattern = /[\/.](gif|jpg|jpeg|tiff|png|svg)$/;
            response.forEach((item: any) => {
                if (deletedFileId && item.id === deletedFileId) {
                    deleteFile(deletedFileId)
                }
                const isValidImage = pattern.test(item.filename.toLowerCase());
                const url2 = generateLink(item)
                item.imageUrl = isValidImage ? url2 : null;
                item.downloadUrl = url2 + `&download=true`;
                item.isValidImage = isValidImage;

                const dataTypeImage = response.filter((type: any) => {
                    return type.file_type === 'image/png'
                })
                const dataTypeOthers = response.filter((type: any) => {
                    return type.file_type !== 'image/png'
                })
                setImage(dataTypeImage)
                setOtherFile(dataTypeOthers)
                dispatch(setAttachment(res.data))
            });
        })
    }, [deletedFileId])

    useEffect(() => {
        // quick search icin feature lara istek yapiyoruz(bunu transact wfs componentinde kullanacagiz)
        if (layerData && layerData.fields) {
            LayerRestApi.getFeatures([layerData.name]).then((res: any) => {
                dispatch(setFeatures(res.data.features));
            })
        }
    }, [])


    const onChangeDateFormat = (field: any, e: any) => {
        const offset = e.getTimezoneOffset()
        e = new Date(e.getTime() - (offset * 60 * 1000))
        onChangeInput(field, e.toISOString().split('T')[0])
    }

    const onChangeInput = (field: any, e: any) => {
        if (layerData.fields) {
            const findedField: any = layerData.fields?.find((f: any) => (f.name === field.name))
            const findedFieldName = findedField?.name

            dispatch(setInputValues({ findedFieldName, e }))

            // mevcut kullanim sekli setleniyorsa, mevcut_kullanim_sekli1 de buna bagli olarak setleniyor.
            if (findedFieldName === 'mevcut_kullanim_sekli') {
                dispatch(setInputValues({ findedFieldName: 'mevcut_kullanim_kodu', e }))
            }
        }
        else if (layerDataTable.fields) {
            const findedField: any = layerDataTable.fields?.find((f: any) => (f.name === field.name))
            const findedFieldName = findedField?.name

            dispatch(setInputValues({ findedFieldName, e }))

            // mevcut kullanim sekli setleniyorsa, mevcut_kullanim_sekli1 de buna bagli olarak setleniyor.
            if (findedFieldName === 'mevcut_kullanim_sekli') {
                dispatch(setInputValues({ findedFieldName: 'mevcut_kullanim_kodu', e }))
            }
        }
    }

    /**
     * Belgeleri indirmek için.
     */
    const downloadFile = (item: any) => {
        saveAs(item.downloadUrl);
    }

    const deleteFile = (id: any) => {
        AttachmentRestApi.deleteFileById(id).then((res: any) => {
            if (res.data.success) {
                console.log('Dosya Silindi.');
            } else {
                console.log('Dosya Silinemedi!');
            }
        })
        setDeletedFileId(id)
    }

    const generateLink = (attachment: IAttachment) => {
        const { REACT_APP_BASE_URL } = process.env
        return REACT_APP_BASE_URL + `rest/v1/attachment/${attachment.id}?token=${token}`
    }

    const handleSubmit = (e: SyntheticEvent) => {
        e.preventDefault();
        setSaveEdit(true)
        dispatch(setEditSave(true))
        dispatch(setOpenGeometryEdit(false))
        const layerId = selectedFeatureForAttachment.layer_id
        if (selectedFeatureId) {
            var featureId = selectedFeatureId
        } else {
            var featureId = selectedFeatureForAttachment.id
        }
        //dispatch(changeEditPopupVisibility(false))
        //dispatch(changeEditPopupFromInfoVisibility(false))
        return new Promise(async resolve => {
            if (submitFiles) {
                for (const file of submitFiles) {
                    const f = new FormData()
                    f.append('file', file)
                    AttachmentRestApi.addAttachment(layerId, featureId, f).then((res: any) => {
                        dispatch(changeEditPopupVisibility(false))
                        dispatch(changeEditPopupFromInfoVisibility(false))
                    }).catch((err: any) => { console.error(err); })
                    resolve(true)
                }
            }
        })
    }

    const closeEditPopup = () => {
        dispatch(cancelButtonActive(true))
        dispatch(changeEditPopupVisibility(false))
        dispatch(changeEditPopupFromInfoVisibility(false))
    }

    const onTemplateRemove = (file: any, callback: any) => {
        setTotalSize(totalSize - file.size);
        callback();
    }
    const onTemplateSelect = (e: any) => {
        let _totalSize = totalSize;
        [...e.files].forEach((file: any) => {
            setSubmitFiles([...e.files])
            _totalSize += file.size;
        });
        setTotalSize(_totalSize);
    }

    const onTemplateClear = () => {
        setTotalSize(0);
    }

    const headerTemplate = (options: any) => {
        const { className, chooseButton } = options;

        return (
            <div
                className={className}
                style={{
                    backgroundColor: 'transparent',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    padding: '1.25rem 0',
                    border: 'none',

                }}>
                {t('EDIT.Upload')}
                {chooseButton}
            </div>
        );
    }
    const chooseOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'file-upload-icon' };

    const itemTemplate = (file: any, props: any) => {
        return (
            <div className='flex align-items-center flex-wrap'>
                <div className='flex align-items-center' style={{ width: '100%' }}>
                    <img role='presentation' src={file.objectURL ? file.objectURL : 'customize-icons/file.svg'} style={{ width: '214px', height: '150px', border: '1px solid lightgray' }} />
                    <span className='flex flex-column text-left ml-3'>
                        {file.name}
                    </span>
                </div>
                <div className='file-footer'>
                    <Tag value={props.formatSize} className='format-size' />
                    <Button type='button' icon='pi pi-times' className='delete-file' onClick={() => onTemplateRemove(file, props.onRemove)} />
                </div>
            </div>
        )
    }

    //belgeler field'ının html kısmı
    function AttachmentContainer() {
        return <>
            <>
                {image && image.length > 0 && <div>
                    {image.map((item: any, i: any) => {
                        return <div className='file-box' key={i}>
                            <Image
                                src={item.imageUrl}
                                alt={item.file_type}
                                preview
                            />
                            <div key={i} className='file-name-container'>
                                <div className='name file-name'>
                                    {item.filename}
                                </div>
                            </div>
                            <div className='button-container'>
                                <a className='anim anim-from-left' onClick={() => downloadFile(item)} download>
                                    {t('EDIT.Download')}
                                </a>
                                <a className='anim anim-from-right' onClick={() => deleteFile(item.id)}>
                                    {t('EDIT.Delete')}
                                </a>
                            </div>
                        </div>
                    })}
                </div>
                }
                {otherFile && otherFile.length > 0 && <div>
                    {otherFile.map((item: any) => {
                        return <div className='file-box' key={item.filename}>
                            <Image
                                src='customize-icons/file.svg'
                                alt={item.file_type}
                            />
                            <div className='file-name-container'>
                                <div className='name file-name'>
                                    {item.filename}
                                </div>
                            </div>
                            <div className='button-container'>
                                <a className='anim anim-from-left' onClick={() => downloadFile(item)} download>
                                    {t('EDIT.Download')}
                                </a>
                                <a className='anim anim-from-right' onClick={() => deleteFile(item.id)}>
                                    {t('EDIT.Delete')}
                                </a>
                            </div>
                        </div>
                    })
                    }
                </div>}
            </>
        </>
    }

    const editObject =
        <>
            <form onSubmit={handleSubmit} action='#'>
                <h3 className='edit-object-header'>{header} {t('EDIT.Information')}</h3>
                <div className='form-data-container'>
                    <div className='form-data-properties'>
                        {
                            dataColumns.map((fp: any) => {
                                if (fp.name !== 'bbox' && fp.name !== 'createdDate' && fp.name != 'createdBy' && fp.name != 'geom') {
                                    return (
                                        <div className='form-data' key={fp.id}>
                                            {
                                                layerDataTable && layerDataTable?.fields.find((ff: any) => {
                                                    if (ff.name === fp.name) {
                                                        finded = ff;
                                                    }
                                                })
                                            }
                                            {
                                                layerData && layerData.fields?.find((ff: any) => {
                                                    if (ff.name === fp.name) {
                                                        finded = ff;
                                                    }
                                                })
                                            }
                                            {finded && finded.type === 'Attachment' && <div className='attachment-container'>
                                                <div className='attachment-header'>{fp.alias}</div>
                                                <div className='file-container'>
                                                    <div className='file-selection'>
                                                        <FileUpload
                                                            className='file-upload-container'
                                                            ref={fileUploadRef}
                                                            name='demo[]'
                                                            url='https://primefaces.org/primereact/showcase/upload.php'
                                                            multiple
                                                            onSelect={onTemplateSelect}
                                                            onError={onTemplateClear}
                                                            onClear={onTemplateClear}
                                                            headerTemplate={headerTemplate}
                                                            itemTemplate={itemTemplate}
                                                            chooseOptions={chooseOptions}
                                                        />
                                                    </div>
                                                    {AttachmentContainer()}
                                                </div>
                                            </div>}
                                            {finded && finded.type === 'Text' &&
                                                <>
                                                    {!finded.nullable ? <label htmlFor='field' className='p-d-block'>{fp.alias} *</label> : <label htmlFor='field' className='p-d-block'>{fp.alias}</label>}
                                                    <InputText id={fp.alias} value={properties[fp.name] ? properties[fp.name] : ''} onChange={(e) => onChangeInput(fp, e.target.value)} required={!finded.nullable} maxLength={250} className='p-inputtext-sm p-d-block p-mb-2' />
                                                </>
                                            }
                                            {finded && finded.type === 'Integer' &&
                                                <>
                                                    {!finded.nullable ? <label htmlFor='integeronly' className='p-d-block'>{fp.alias} *</label> : <label htmlFor='integeronly' className='p-d-block'>{fp.alias}</label>}
                                                    <InputNumber inputId='integeronly' disabled={fp.name === 'layer_id'} id={fp.alias} value={properties[fp.name] ? properties[fp.name] : ''} required={!finded.nullable} onChange={(e) => onChangeInput(fp, e.value)} min={0} max={2147483647} useGrouping={false} className='p-inputtext-sm p-d-block p-mb-2' />
                                                </>
                                            }
                                            {finded && (finded.type === 'Float' || finded.type === 'Area') &&
                                                <>
                                                    {!finded.nullable ? <label htmlFor='minmaxfraction' className='p-d-block'>{fp.alias} *</label> : <label htmlFor='minmaxfraction' className='p-d-block'>{fp.alias}</label>}
                                                    <InputNumber inputId='minmaxfraction' mode='decimal' id={fp.alias} value={properties[fp.name] ? properties[fp.name] : ''} onChange={(e) => onChangeInput(fp, e.value)} required={!finded.nullable} className='p-inputtext-sm p-d-block p-mb-2' />
                                                </>
                                            }
                                            {finded && finded.type === 'Date' &&
                                                <>
                                                    {!finded.nullable ? <label htmlFor='date' className='p-d-block'>{fp.alias} *</label> : <label htmlFor='date' className='p-d-block'>{fp.alias}</label>}
                                                    <Calendar id='date' value={properties[fp.name] ? new Date(properties[fp.name].split('Z')[0]) : new Date('')} onChange={(e: any) => onChangeDateFormat(fp, e.value)} required={!finded.nullable} dateFormat='dd/mm/yy' />
                                                </>
                                            }
                                            {finded && finded.type === 'City' &&
                                                <>
                                                    {!finded.nullable ? <label htmlFor='city' className='p-d-block'>{fp.alias} *</label> : <label htmlFor='city' className='p-d-block'>{fp.alias}</label>}
                                                    <Dropdown className='input-domain' id='city' value={properties[fp.name] ? properties[fp.name] : ''} options={domainsOfCity} placeholder={t('Select')} optionLabel='adi' optionValue='il_id' required={!finded.nullable} onChange={(e: any) => onChangeInput(fp, e.value)} style={{ height: '36px', display: 'flex', alignItems: 'center' }} />
                                                </>
                                            }
                                            {finded && finded.type === 'Distinct' &&
                                                <>
                                                    {!finded.nullable ? <label htmlFor='distinct' className='p-d-block'>{fp.alias} *</label> : <label htmlFor='distinct' className='p-d-block'>{fp.alias}</label>}
                                                    <Dropdown className='input-domain' id='distinct' value={properties[fp.name] ? properties[fp.name] : ''} options={domainsOfDistincts} placeholder={t('Select')} optionLabel='adi' optionValue='ilce_id' required={!finded.nullable} onChange={(e: any) => onChangeInput(fp, e.value)} style={{ height: '36px', display: 'flex', alignItems: 'center' }} />
                                                </>
                                            }
                                            {finded && finded.type === 'Neighbourhood' &&
                                                <>
                                                    {!finded.nullable ? <label htmlFor='neighborhood' className='p-d-block'>{fp.alias} *</label> : <label htmlFor='neighborhood' className='p-d-block'>{fp.alias}</label>}
                                                    <Dropdown className='input-domain' id='neighborhood' value={properties[fp.name] ? properties[fp.name] : ''} options={domainsOfNeighborhoods} placeholder={t('Select')} optionLabel='adi' optionValue='mah_id' required={!finded.nullable} onChange={(e: any) => onChangeInput(fp, e.value)} style={{ height: '36px', display: 'flex', alignItems: 'center' }} />
                                                </>
                                            }
                                            {finded && finded.type === 'Domain' && finded.name !== 'mevcut_kullanim_kodu' &&
                                                <>
                                                    {!finded.nullable ? <label htmlFor='domain' className='p-d-block'>{fp.alias} *</label> : <label htmlFor='domain' className='p-d-block'>{fp.alias}</label>}
                                                    <Dropdown className='input-domain' id='domain' value={properties[fp.name] ? properties[fp.name] : undefined} options={finded.domain} optionLabel='desc' optionValue='id' onChange={(e: any) => onChangeInput(fp, e.value)} required={!finded.nullable} placeholder={t('Select')} style={{ height: '36px', display: 'flex', alignItems: 'center' }} />
                                                </>
                                            }
                                            {finded && finded.type === 'Domain' && finded.name === 'mevcut_kullanim_kodu' &&
                                                <>
                                                    {!finded.nullable ? <label htmlFor='domain' className='p-d-block'>{fp.alias} *</label> : <label htmlFor='domain' className='p-d-block'>{fp.alias}</label>}
                                                    <Dropdown disabled className='input-domain' id='domain' value={properties[fp.name] ? properties[fp.name] : undefined} options={finded.domain} optionLabel='desc_code' optionValue='id' onChange={(e: any) => onChangeInput(fp, e.value)} required={!finded.nullable} placeholder={t('Select')} style={{ height: '36px', display: 'flex', alignItems: 'center' }} />
                                                </>
                                            }

                                        </div>)
                                }
                            })
                        }
                    </div>
                </div>

                <div className='edit-confirm-buttons'>
                    <PrimaryButton label={t('BUTTON.Cancel')} onClick={closeEditPopup}></PrimaryButton>
                    <SecondaryButton type='submit' label={t('BUTTON.Save')} disabled={!isValid}></SecondaryButton>
                </div>
            </form>
        </>

    return (
        <>
            <Popup
                className='edit-object-container'
                visible={true}
                onHide={closeEditPopup}
                content={editObject}>
            </Popup>
            {openEditFromInfo && saveEdit && <TransactWfs feature={selectedFeature} mode={Mode.UPDATE} type={selectedFeature.geometry ? selectedFeature.geometry.type : null} from={From.INFO} />}
        </>
    )
}
import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
    header: '',
    properties: {},
    dataColumns: [],
    inputValues: {},
    editSave: false,
    layerFields: [], 
    cancelActive: false
}

export const editObjectSlice = createSlice({
    name: 'editObject',
    initialState,
    reducers: {
        //layerın başlığı
        setHeader: (state: any, action) => {
            state.header = action.payload;
        },
        //objenin property ve değerleri
        setProperties: (state: any, action) => {
            state.properties = action.payload;
        },
        //sadece name ve aliaslardan olusuyor
        setDataColumns: (state: any, action) => {
            state.dataColumns = action.payload
        },
        // edit isleminde kaydet butonuna basılması durumunu kontrol ediyor
        setEditSave: (state: any, action) => {
            state.editSave = action.payload
        },
        // tablodan secilen layer i ve fieldlarini aliyor 
        setTableSelectedLayer: (state: any, action) => {
            state.tableSelectedLayer = action.payload
        },
        // inputtan girilen degerleri burada setliyoruz
        setInputValues: (state: any, action) => {
            state.inputValues = action.payload
            // obje halinde geliyorsa ve icinde desc varsa domaindir
            if(state.inputValues.e.desc ){
                state.properties[state.inputValues.findedFieldName] = state.inputValues.e.id
            }
            else if(state.inputValues.e.il_id){
                state.properties[state.inputValues.findedFieldName] = state.inputValues.e.il_id
            }
            else if(state.inputValues.e.ilce_id){
                state.properties[state.inputValues.findedFieldName] = state.inputValues.e.ilce_id
            }
            else if(state.inputValues.e.mah_id){
                state.properties[state.inputValues.findedFieldName] = state.inputValues.e.mah_id
            }
            //domain disindakiler
            else {
                state.properties[state.inputValues.findedFieldName] = state.inputValues.e;
            }
        },
        // quick search in feature ını tüm featurelar arasinda arayacagiz
        setFeatures: (state: any, action) => {
            state.features = action.payload
        },
        // edit geometri de popupta cancel e basınca işlem iptal edilir 
        cancelButtonActive : (state: any, action) => {
            state.cancelActive = action.payload
        },
    },
})

export const { setHeader,
    setProperties,
    setDataColumns,
    setEditSave,
    setInputValues,
    setTableSelectedLayer,
    setFeatures,
    cancelButtonActive
} = editObjectSlice.actions


export default editObjectSlice.reducer
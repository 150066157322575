import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    openEditPopup: false,
    openEditPopupFromInfo: false,
    openDeletePopup: false,

}
export const popupSlice = createSlice({
    name: 'popup',
    initialState,
    reducers: {
        changeEditPopupVisibility: (state: any, action) => {
            state.openEditPopup = action.payload
        },
        changeEditPopupFromInfoVisibility: (state: any, action) => {
            state.openEditPopupFromInfo = action.payload
        },
        changeDeletePopupVisibility: (state: any, action) => {
            state.openDeletePopup = action.payload
        }
    }
})

export const { changeEditPopupVisibility, changeDeletePopupVisibility, changeEditPopupFromInfoVisibility } = popupSlice.actions;

export default popupSlice.reducer;
import { useContext, useEffect, useState } from 'react'
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { Feature } from 'ol';
import { GeoJSON } from 'ol/format';
import { MapContext } from '../../MapContext';
import { useAppSelector } from '../../../../../../state/hooks';
import { From, Mode, TransactWfs } from '../TransactWfs';
import Modify from 'ol/interaction/Modify'
import { changeEditPopupVisibility } from '../../../../../../state/features/popupSlice';
import { useDispatch } from 'react-redux';
import Collection from 'ol/Collection';
import { setOpenGeometryEdit } from '../../../../../../state/features/digitizationSlice';
import Snap from 'ol/interaction/Snap';
import MultiPoint from 'ol/geom/MultiPoint';
import { setIsDrawing } from '../../../../../../state/features/drawStateSlice';
import Draw, { DrawEvent } from 'ol/interaction/Draw';
import { never } from 'ol/events/condition';

/* 
 * Bu componentte seçilen feature önce haritada gösteriliyor, sonra geometrisi ve property leri değiştirilebiliyor.
 *
*/

export const DigiDrawForEdit = () => {
    const map = useContext(MapContext);
    const dispatch = useDispatch()
    const [source, setSource] = useState<VectorSource<any>>(new VectorSource())
    const [modifyInteraction, setModifyInteraction] = useState<any>(undefined)
    const [snapInteraction, setSnapInteraction] = useState<any>(undefined)
    const [drawInteraction, setDrawInteraction] = useState<any>(undefined)
    const [vectorLayer, setVectorLayer] = useState<VectorLayer<any>>(new VectorLayer())
    const selectedFeature: any = useAppSelector((state) => state.digitization.digiFeature)
    const selectedFeatureType = selectedFeature?.geometry.type

    const tableVisibility = useAppSelector((state) => state.table.visible)
    const startDeleteDigitization = useAppSelector((state) => state.digitization.startDeleteDigi)
    const openEditGeometry = useAppSelector((state) => state.digitization.openGeometryEdit)
    const editSave = useAppSelector((state) => state.editObject.editSave)
    const cancelButton = useAppSelector((state) => state.editObject.cancelActive)
    const [olFeatureForEdit, setolFeatureForEdit] = useState<any>(new Feature())

    useEffect(() => {
        map.updateSize();
    }, [])

    const style = new Style({
        fill: new Fill({
            color: 'rgb(56, 238, 245, 0.44)',
        }),
        stroke: new Stroke({
            color: '#38EEF5',
            width: 2,
        }),
        image: new CircleStyle({
            radius: 5,
            fill: new Fill({
                color: '#38EEF5',
            }),
        }),
    })

    const styles = [
        new Style({
            fill: new Fill({
                color: 'rgb(165, 33, 37, 0.44)',
            }),
            stroke: new Stroke({
                color: '#38EEF5',
                width: 2,
            }),
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: '#38EEF5',
                }),
            }),
        }),
        new Style({
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: 'orange',
                }),
            }),
            geometry: function (olFeatureForEdit: any) {
                // return the coordinates of the first ring of the polygon
                const point = new MultiPoint([])
                point.appendPoint(olFeatureForEdit.getGeometry())

                return point;
            },
        }),
    ];

    useEffect(() => {
        if(selectedFeature.geometry.type){
            const vectorSource = new VectorSource();
    
            const vector = new VectorLayer({
                source: vectorSource,
                zIndex: 10000000000000000,
                style: style
            });
            map.addLayer(vector);
            setVectorLayer(vector)
            const geoJson = new GeoJSON()
            const feature = geoJson.readFeature(selectedFeature)
            vectorSource.addFeature(feature);
            setSource(vectorSource)
            if (feature && feature.getGeometry()) {
                map.getView().fit(feature.getGeometry(), { duration: 500, maxZoom: 19 });
                setolFeatureForEdit(feature);
            }
    
            return () => {
                map.removeLayer(vector)
                source.clear();
            }
        }
    }, [selectedFeature])

    useEffect(() => {

        if (openEditGeometry) {
            let draw = new Draw({
                source: source,
                type: 'Polygon',
                style: new Style({
                    fill: new Fill({
                        color: 'rgb(165, 33, 37, 0.44)',
                    }),
                    stroke: new Stroke({
                        color: '#A52125',
                        width: 2,
                    }),
                    image: new CircleStyle({
                        radius: 5,
                        fill: new Fill({
                            color: '#A52125',
                        }),
                    }),
                }),
                finishCondition: never,
                condition: function (e: any) {
                    //sadece solla çizim yapıyor ve sağla bitiriyor
                    if (e.originalEvent.buttons === 1) {
                        return true;
                    } else if (e.originalEvent.buttons === 2) {
                        draw.finishDrawing();
                        return false;
                    }
                    else {
                        return false;
                    }
                },
            });
            setDrawInteraction(draw)
            const modify = new Modify({
                features: new Collection([olFeatureForEdit])
            })

            map.addInteraction(draw);
            setModifyInteraction(modify)
            map.addInteraction(modify);
            dispatch(setIsDrawing(true)); 


            vectorLayer.setStyle(styles)

            const snap = new Snap({
                source: source
            });

            setSnapInteraction(snap)
            map.addInteraction(snap);

            const rightClickHandler = (event: any) => {
                event.preventDefault();
                map.removeInteraction(modify);
                dispatch(changeEditPopupVisibility(true))
                // bi defa yaptiktan sonra kaldiriyoruz event i
                document.removeEventListener('contextmenu', rightClickHandler)
            }

            // sag tiklayinca bitirmesi icin
            document.addEventListener('contextmenu', rightClickHandler)
        }

        // geometri düzenle butonu kapanınca interactionlar kaldırılıyor, style table dan seçim haline dönüyor. 
        else {
            map.removeInteraction(modifyInteraction)
            map.removeInteraction(snapInteraction);
            dispatch(setIsDrawing(false)); 
            vectorLayer.setStyle(style)
            map.removeInteraction(drawInteraction)
        }

        return () => {
            map.removeInteraction(drawInteraction)
            map.removeInteraction(modifyInteraction)
            map.removeInteraction(snapInteraction);
        }

    }, [openEditGeometry])

    useEffect(() => {
        if (cancelButton || !tableVisibility) {
            source.clear();
            dispatch(setOpenGeometryEdit(false))
        }
    }, [cancelButton, tableVisibility])

    return (
        <>
            {startDeleteDigitization && <TransactWfs feature={selectedFeature} mode={Mode.DELETE} type={selectedFeatureType} source={source} from={From.TABLE} />}
            {editSave && <TransactWfs feature={olFeatureForEdit} mode={Mode.UPDATE} type={selectedFeatureType} source={source} from={From.TABLE} />}
        </>
    )
}
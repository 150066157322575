import './DataTableGridLines.scss';
import { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { changeReportVisibility, changeTableVisibility, isSelectedFeatureOnTable, setLayerFromTable } from '../../../state/features/tableSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../state/hooks';
import { RootState } from '../../../state/store';
import DeleteObject from '../../feature/DeleteObject';
import { changeInfoVisibility, setSelectedFeatureForInfoEdit, setSelectedFeatureId } from '../../../state/features/infoSlice';
import { setDataColumns, setHeader, setProperties, setTableSelectedLayer } from '../../../state/features/editObjectSlice';
import Reports from '../../report/Reports';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { useTranslation } from 'react-i18next';
import { setDigiFetaure, setDigitizationLayer, setOpenGeometryEdit } from '../../../state/features/digitizationSlice';
import { changeDeletePopupVisibility, changeEditPopupVisibility } from '../../../state/features/popupSlice';
import { AttachmentRestApi } from '../../../util/restapi/attachment';
import ExcelJS from 'exceljs';
import { ProgressSpinner } from 'primereact/progressspinner';
import axios from 'axios';

/* 
 * Table ın oluşturulduğu ve table işlemlerinin (düzenle, geometri düzenle, sil ) yapıldığı component.
 *
*/
export const DataTableGridLines = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [tableData, setTableData] = useState<any>([])
    const [selectedLayer, setSelectedLayer] = useState<any>(null)
    const [rowSelection, setRowSelection] = useState<any>();
    const [perm, setPerm] = useState<any>();
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [fieldsWithDomain, setFieldsWithDomain] = useState<any>([]);
    const [fieldsWithCity, setFieldsWithCity] = useState<any>([]);
    const [fieldsWithDistinct, setFieldsWithDistinct] = useState<any>([]);
    const [fieldsWithDate, setFieldsWithDate] = useState<any>([]);
    const [fieldsWithNeighborhood, setFieldsWithNeighborhood] = useState<any>([]);
    const [filters, setFilters] = useState({});
    const [attachments, setAttachemnts] = useState<any>([])
    const [filteredDataValue, setFilteredDataValue] = useState<any>([])
    const tableVisibility = useAppSelector((state: RootState) => state.table.visible);
    const infoVisibility = useAppSelector((state: RootState) => state.info.visible);
    const features: any = useAppSelector((state) => state.service.features)
    const layerData = useAppSelector((state) => state.table.layer)
    const services = useAppSelector((state) => state.service.services)
    const token = useAppSelector((state: RootState) => state.login.token);
    const openDelete: boolean = useAppSelector((state) => state.popup.openDeletePopup)
    const openReports: boolean = useAppSelector((state) => state.table.reportVisible)
    const geometryEdit: boolean = useAppSelector((state) => state.digitization.openGeometryEdit)
    const editPopupFromInfo: boolean = useAppSelector((state) => state.popup.openEditPopupFromInfo)
    const editPopup: boolean = useAppSelector((state) => state.popup.openEditPopup)
    const deletePopup: boolean = useAppSelector((state) => state.popup.openDeletePopup)
    const selectedFeature: any = useAppSelector((state) => state.info.edit)
    const domainsOfCity: any = useAppSelector((state) => state.digitization.allCities)
    const domainsOfDistincts: any = useAppSelector((state) => state.digitization.allDistincts)
    const domainsOfNeighborhoods: any = useAppSelector((state) => state.digitization.allNeighborhoods)
    let dataColumns: any[] = []
    let globalFilterFieldNames: any[] = [];
    let domainFields: any[] = []
    let cityFields: any[] = []
    let distinctFields: any[] = []
    let neighbourhoodFields: any[] = []
    let dateFields: any[] = []

    useEffect(() => {
    }, [infoVisibility])

    /*
     * map click te seçilen feature ın tabloda da seçili gosterilmesi 
     */
    useEffect(() => {
        if (selectedFeature && selectedFeature.adi) {
            if (selectedFeature.layer_id && (selectedFeature.layer_id === layerData.id)) {
                const finded = tableData?.find((elem: any) => (elem.id === selectedFeature.id))
                setRowSelection(finded)

                let allProperties = finded
                for (const domainField of fieldsWithDomain) {
                    for (const domain of domainField.domain) {
                        if (domain.desc === allProperties[domainField.name]) {
                            allProperties = { ...allProperties, [domainField.name]: domain.id }
                        }
                    }
                }

                // proopertieslerde tablodan alınan il adi yerine il_id gönderiliyor 
                for (const cityField of fieldsWithCity) {
                    const finded = domainsOfCity.find((city: any) => (city.adi === allProperties[cityField.name]))
                    if (finded) {
                        allProperties = { ...allProperties, [cityField.name]: finded.il_id }
                    }
                }

                // proopertieslerde tablodan alınan ilce adi yerine ilce_id gönderiliyor 
                for (const distinctField of fieldsWithDistinct) {
                    const finded = domainsOfDistincts.find((distinct: any) => (distinct.adi === allProperties[distinctField.name]))
                    if (finded) {
                        allProperties = { ...allProperties, [distinctField.name]: finded.ilce_id }
                    }

                }

                // proopertieslerde tablodan alınan mahalle adi yerine mah_id gönderiliyor 
                for (const neighborhoodField of fieldsWithNeighborhood) {
                    const finded = domainsOfNeighborhoods.find((neighborhood: any) => (neighborhood.adi === allProperties[neighborhoodField.name]))
                    if (finded) {
                        allProperties = { ...allProperties, [neighborhoodField.name]: finded.mah_id }
                    }
                }
                dispatch(setProperties(allProperties))
                dispatch(changeInfoVisibility(false))
                dispatch(isSelectedFeatureOnTable(true))
                if (finded) {
                    const findedFeature = features.find((feature: any) => (feature.properties.id === finded.id))
                    dispatch(setDigiFetaure(findedFeature))
                    // bunu attachment icin setliyoruz 
                    dispatch(setSelectedFeatureForInfoEdit(findedFeature.properties));
                    dispatch(setSelectedFeatureId(findedFeature.id.split('.')[1]));
                }
            }
        }
    }, [selectedFeature])

    useEffect(() => {
        initFilters1()
    }, [])

    useEffect(() => {
        services.map((service) => {
            // @ts-ignore
            const selected = service.layers.find((layer: any) => layer.id === layerData.id)
            if (selected) {
                setSelectedLayer(selected)
                dispatch(setTableSelectedLayer(selected))
            }

            // domain olanları bir arraye atıyoruz
            selected?.fields.map((field: any) => {
                if (field.type === "Domain") {
                    domainFields.push(field)
                }
                else if (field.type === "City") {
                    cityFields.push(field)
                }
                else if (field.type === "Distinct") {
                    distinctFields.push(field)
                }
                else if (field.type === "Neighbourhood") {
                    neighbourhoodFields.push(field)
                }
                // date lerde geoserverdan gelen Z leri kaldirmak icin
                else if (field.type === "Date") {
                    dateFields.push(field)
                }
            })

            setFieldsWithDomain(domainFields)
            setFieldsWithCity(cityFields)
            setFieldsWithDistinct(distinctFields)
            setFieldsWithNeighborhood(neighbourhoodFields)
            setFieldsWithDate(dateFields)
        })

    }, [layerData])

    // islemler bittiginde tablodaki secimin kaldirilmasi
    useEffect(() => {
        if (!editPopupFromInfo || !editPopup || !deletePopup) {
            setRowSelection(undefined)
        }
    }, [editPopupFromInfo, editPopup, deletePopup])

    useEffect(() => {
        getUserPerm()
    }, [token, layerData])

    const getUserPerm = async () => {
        const response = await axios.get(process.env.REACT_APP_BASE_URL + `rest/v1/util/permissions?token=${token}`)
        const findedPermObj = response.data.permissions.find((data: any) => data.layer_name === layerData.name)
        setPerm(findedPermObj.permission)
    }

    if (selectedLayer && selectedLayer.fields) {
        selectedLayer.fields.map((obj: any) => {
            dataColumns.push({ name: obj.name, alias: obj.alias })
        })
    }

    const filteredDataColumns = dataColumns.filter((dc: any) => (
        dc.name !== 'bbox' &&
        dc.name !== 'createdBy' &&
        dc.name !== 'createdDate' &&
        dc.name !== 'geom' &&
        dc.name !== 'id' &&
        dc.name !== 'layer_id'
    ))

    const alphabeticalDataColumns = filteredDataColumns.sort((a, b) => a.alias.localeCompare(b.alias, 'tr'));

    /*
     * Table da filtrelemenin yapıldığı bölüm. (hem global hem de field bazlı filtreleme)
     */
    useEffect(() => {
        alphabeticalDataColumns.map((col) => {
            globalFilterFieldNames.push(col.name)
        })

        let filterState = { 'global': { value: null, matchMode: FilterMatchMode.CONTAINS } }

        globalFilterFieldNames.map((name) => {
            filterState = {
                ...filterState,
                [name]: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
            }
        })
        setFilters(filterState)
    }, [tableData])

    useEffect(() => {
        AttachmentRestApi.getAttachmentsOfLayer(layerData.id).then((res: any) => {
            const attachmentsResponse = res.data
            setAttachemnts(attachmentsResponse)
        })
        let attachmentFileNameArray: any[] = []
        attachmentFileNameArray.push(attachments.map((obj: any) => { return obj.file_name }))
        if (features) {
            const tableValue: any = features.map((feature: any) => {
                let properties = feature.properties

                for (let attachment of attachments) {
                    if (+feature.id.split('.')[1] === attachment.feature_id) {
                        properties = { ...properties, belgeler: attachmentFileNameArray.join(',') }
                    }
                }

                // table da domainlerin doldurulması
                for (const domainField of fieldsWithDomain) {
                    for (const domain of domainField.domain) {
                        if (domain.id === properties[domainField.name]) {
                            // domain mevcut_kullanim_sekli1 se desc_code yazılacak diğer durumlarda desc yazılacak
                            domainField.name !== 'mevcut_kullanim_kodu' ? properties = { ...properties, [domainField.name]: domain.desc } : properties = { ...properties, [domainField.name]: domain.desc_code }
                        }
                    }
                }


                // table da il, ilce ve mahallenin doldurulmasi
                for (const cityField of fieldsWithCity) {
                    const finded = domainsOfCity.find((city: any) => (city.il_id === +properties[cityField.name]))
                    if (finded) {
                        properties = { ...properties, [cityField.name]: finded.adi }
                    }
                }

                // table da il, ilce ve mahallenin doldurulmasi
                for (const distinctField of fieldsWithDistinct) {
                    const finded = domainsOfDistincts.find((distinct: any) => (distinct.ilce_id === +properties[distinctField.name]))
                    if (finded) {
                        properties = { ...properties, [distinctField.name]: finded.adi }
                    }

                }

                // table da il, ilce ve mahallenin doldurulmasi
                for (const neighborhoodField of fieldsWithNeighborhood) {
                    const finded = domainsOfNeighborhoods.find((neighborhood: any) => (neighborhood.mah_id === +properties[neighborhoodField.name]))
                    if (finded) {
                        properties = { ...properties, [neighborhoodField.name]: finded.adi }
                    }
                }

                // geoserverdan gelen date in sonundaki z yi split ediyoruz
                for (const dateField of fieldsWithDate) {
                    if (properties[dateField.name]) {
                        properties = { ...properties, [dateField.name]: properties[dateField.name].split('Z')[0] }
                    }
                }

                return properties
            })
            setTableData(tableValue)
        }
        // eslint-disable-line react-hooks/exhaustive-deps
    }, [features]);

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value;
        let filtered: any = { ...filters };
        filtered['global'].value = value;

        setFilters(filtered);
        setGlobalFilterValue(value);
    }

    const initFilters1 = () => {
        setFilters({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'adi': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            // globalFilterFieldNames: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },

        });
        setGlobalFilterValue('');
    }

    /**
     * Tablonun excel formatında dışa aktarılması için yazılan fonksiyon.
     */
    const exportExcel = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        format: "xlsx" | "csv"
    ) => {
        e.preventDefault();

        const workbook = new ExcelJS.Workbook();
        workbook.addWorksheet(layerData.alias);
        const worksheet = workbook.getWorksheet(layerData.alias);

        //başlıklar
        worksheet.columns =
            alphabeticalDataColumns.map((column: any) => {
                return {
                    header: column.alias, key: column.name, width: 20
                }
            })


        // başlık dışındaki satırlar
        if (filteredDataValue.length === 0) {
            for (const data of tableData) {
                worksheet.addRow(data);
            }
        } else if (filteredDataValue.length > 0) {
            for (const data of filteredDataValue) {
                worksheet.addRow(data);
            }
        }

        // Sayfadaki ilk satırın (sütun başlıkları) kalın olmasını sağlar
        worksheet.getRow(1).font = { bold: true }
        // Sayfadaki ilk satırın (sütun başlıkları) ortada hizalanmasını sağlar.
        worksheet.getRow(1).alignment = { horizontal: 'center', vertical: 'middle' }
        worksheet.getRow(1).height = 30
        worksheet.getRow(1).border = {
            top: { style: 'thick' },
            left: { style: 'thick' },
            right: { style: 'thick' },
            bottom: { style: 'thick' },
        }


        const uint8Array =
            format === "xlsx"
                ? await workbook.xlsx.writeBuffer()
                : await workbook.csv.writeBuffer();
        const blob = new Blob([uint8Array], { type: "application/octet-binary" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${layerData.alias}.` + format;
        a.click();
        a.remove();
    };

    /**
     * Tablonun özelleştirilmiş raporu için yazılan fonksiyon.
     */
    const customizedReports = () => {
        if (layerData.name) {
            dispatch(changeReportVisibility(true))
            dispatch(setLayerFromTable(layerData))
        }
    }

    /*
     * Geometri ve property düzenlemek için
     */
    const openEditPopup = () => {
        if (geometryEdit) {
            dispatch(setOpenGeometryEdit(false))
        }
        else {
            dispatch(setOpenGeometryEdit(true))
            if (alphabeticalDataColumns) {
                // dispatch(changeEditPopupVisibility(true))
                dispatch(setLayerFromTable(layerData))
                dispatch(setDigitizationLayer(layerData))
                dispatch(setHeader(layerData.alias))
                dispatch(setDataColumns(alphabeticalDataColumns))
            }
        }
    }

    /*
     * Geometrisiz sadece property editlemek icin
     */
    const openEditPropertyPopup = () => {
        if (alphabeticalDataColumns) {
            dispatch(setLayerFromTable(layerData))
            dispatch(setDigitizationLayer(layerData))
            dispatch(setHeader(layerData.alias))
            dispatch(setDataColumns(alphabeticalDataColumns))
            dispatch(changeEditPopupVisibility(true))
        }
    }

    /*
     * Silme popup ının açıldığı fonksiyon.
     */
    const openDeletePopup = () => {
        dispatch(changeDeletePopupVisibility(true))
    }

    /**
    * Tabloda seçilen satırın bilgilerini aldığımız kısım.(sayısallaştırma için vs.)
    */
    const tableRowSelection = (e: any) => {
        dispatch(setOpenGeometryEdit(false))
        if (e.value) {
            setRowSelection(e.value)
            // table da desc i yazan domainin id sini göneriyoruz burda
            let allProperties = e.value
            for (const domainField of fieldsWithDomain) {
                for (const domain of domainField.domain) {
                    if (domain.desc === allProperties[domainField.name]) {
                        allProperties = { ...allProperties, [domainField.name]: domain.id }
                    }
                }
            }

            // proopertieslerde tablodan alınan ilce adi yerine ilce_id gönderiliyor
            for (const cityField of fieldsWithCity) {
                const finded = domainsOfCity.find((city: any) => (city.adi === allProperties[cityField.name]))
                if (finded) {
                    allProperties = { ...allProperties, [cityField.name]: finded.il_id }
                }
            }

            // proopertieslerde tablodan alınan ilce adi yerine ilce_id gönderiliyor 
            for (const distinctField of fieldsWithDistinct) {
                const finded = domainsOfDistincts.find((distinct: any) => (distinct.adi === allProperties[distinctField.name]))
                if (finded) {
                    allProperties = { ...allProperties, [distinctField.name]: finded.ilce_id }
                }

            }

            // proopertieslerde tablodan alınan mahalle adi yerine mah_id gönderiliyor 
            for (const neighborhoodField of fieldsWithNeighborhood) {
                const finded = domainsOfNeighborhoods.find((neighborhood: any) => (neighborhood.adi === allProperties[neighborhoodField.name]))
                if (finded) {
                    allProperties = { ...allProperties, [neighborhoodField.name]: finded.mah_id }
                }
            }

            dispatch(setProperties(allProperties))
            dispatch(changeInfoVisibility(false))
            dispatch(isSelectedFeatureOnTable(true))

            const findedFeature = features.find((feature: any) => (feature.properties.id === e.value.id))

            dispatch(setDigiFetaure(findedFeature))
            // bunu attachment icin setliyoruz 
            dispatch(setSelectedFeatureForInfoEdit(findedFeature.properties));
            dispatch(setSelectedFeatureId(findedFeature.id.split('.')[1]));
        } else if (e.value === null) {
            setRowSelection(undefined)
            dispatch(setProperties(undefined))
            dispatch(isSelectedFeatureOnTable(false))
        } else if (e.value && infoVisibility) {
            dispatch(isSelectedFeatureOnTable(false))
        }
    }

    /**
     * Tabloda 
     */
    const filteredRowsData = (e: any) => {
        if (e.length && e.length > 0) {
            setFilteredDataValue(e)
        }
    }

    const close = () => {
        dispatch(changeTableVisibility(false))
    }

    /**
      * Tablonun başlığı ve export fonskiyonlarını gösteren kısım.
      */
    const header = (
        <div className='header-container'>
            <div style={{ display: 'flex' }}>
                <span className='header-span'>{layerData.alias}</span>
                <span className='vr'></span>
                <div className='table-tools'>
                    <div className='table-tools-part'>
                        <button className='table-button' onClick={(e) => exportExcel(e, "xlsx")}>
                            Rapor Al
                        </button>
                        <button
                            className={`table-button ${layerData.name !== 'yapilar_usr' && layerData.name !== 'aof_alan_usr' && layerData.name !== 'tahsisli_parsel_usr' && layerData.name !== 'parsel_usr' ? 'table-button-disabled' : ''}`}
                            onClick={customizedReports}
                        >
                            Özel Raporlar
                        </button>
                    </div>
                    <span className='vr'></span>
                    {perm !== 1 && <><div className='table-tools-part'>
                        <button disabled={!rowSelection} className={`table-button ${!rowSelection ? 'table-button-disabled' : ''}`} onClick={() => openEditPropertyPopup()}>
                            {t('TABLE.Edit')}
                        </button>
                        <button disabled={!rowSelection} className={`table-button ${!rowSelection ? 'table-button-disabled' : ''}`} onClick={() => openDeletePopup()}>
                            {t('TABLE.Delete')}
                        </button>
                    </div><span className='vr'></span><div className='table-tools-part'>
                            <button disabled={!rowSelection} className={`table-button ${!rowSelection ? 'table-button-disabled' : ''} ${geometryEdit ? 'geometry-edit-button-active' : ''} `} onClick={() => openEditPopup()}>
                                {t('TABLE.Edit Vertex')}
                            </button>
                        </div></>}
                </div>
            </div>

            <div className='header-right'>
                <div className='global-search'>
                    <span className='p-input-icon-left'>
                        <i className='pi pi-search' />
                        <InputText value={globalFilterValue} onInput={onGlobalFilterChange} placeholder={t('TABLE.Search')} className='p-inputtext-sm block mb-2' />
                    </span>
                </div>
                <i className='pi pi-times' onClick={close}></i>
            </div>
        </div>
    );

    return (
        <div className='card' >
            {tableVisibility && tableData && token &&
                <DataTable
                    className='table-style'
                    columnResizeMode='expand'
                    stripedRows={true}
                    resizableColumns={true}
                    value={tableData}
                    header={header}
                    showGridlines
                    selectionMode='single'
                    selection={rowSelection}
                    onSelectionChange={(e) => tableRowSelection(e)}
                    paginator
                    currentPageReportTemplate='Showing {first} to {last} of {totalRecords}'
                    rows={10}
                    rowsPerPageOptions={[10, 20, 50]}
                    filters={filters}
                    globalFilter={globalFilterValue} //burasnı ekleyınce oldu artık global fıltreyı de goruyor
                    onValueChange={(e) => filteredRowsData(e)}
                    //onSort={(e) => filteredRowsData(e)}
                    //onFilter={(e) => filteredRowsData(e)}
                    filterDisplay="menu"
                    emptyMessage='No data found.'
                // dataKey='id'
                //sadece bu field icinde arama yapar
                // globalFilterFields={['adi']}
                >
                    {
                        alphabeticalDataColumns.map((col, index) => <Column
                            key={index}
                            field={col.name}
                            header={col.alias}
                            sortable
                            filter
                            filterPlaceholder='Search'
                            style={{ minWidth: '14rem' }}
                        />)
                    }
                </DataTable>}
            {tableVisibility && tableData.length === 0 && <ProgressSpinner style={{ width: "100px", height: "100px", position: "absolute", bottom: "165px", left: "725px" }} />}
            {openDelete && <DeleteObject layer={layerData} />}
            {openReports && <Reports tableData={tableData} dataColumns={alphabeticalDataColumns} />}
        </div>
    );
}


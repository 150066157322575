import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
    categoricalSearchValue: 'Açık Öğretim Fakültesi Büroları ve Birim Arama',
    quickSearchValue: ''
}

export const categoricalSearchSlice = createSlice({
    name: 'categoricalSearch',
    initialState,
    reducers: {
        setValue: (state: any, action) => {
            state.categoricalSearchValue = action.payload;
        },
        setQuickSearchValue: (state: any, action) => {
            state.quickSearchValue = action.payload;
        }
    }
})

export const { setValue, setQuickSearchValue } = categoricalSearchSlice.actions

export default categoricalSearchSlice.reducer
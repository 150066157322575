import './MenuDetails.scss';
import { useDispatch } from 'react-redux';
import { setButtonState } from '../../../state/features/buttonStateSlice';
import { useAppSelector } from '../../../state/hooks';
import { ButtonState } from '../../shared/button-state/ButtonState';
import { Language } from '../../language/Language';
import { DrawTools } from '../../layouts/toolbar/draw-tools/DrawTools';
import { Share } from '../../share/Share';
import { useCurrentWidth } from '../../shared/resize/Resize';
import { useEffect, useState } from 'react';
import { CategoricalSearch } from '../../categorical-search/CategoricalSearch';

export default function MenuDetails() {
    const dispatch = useDispatch();
    const width = useCurrentWidth();
    const buttonVisibility = useAppSelector((state) => state.buttonState.value);
    const [mobile, setMobile] = useState(false);

    useEffect(() => {
        if (width <= 767) {
            setMobile(true)
        } else {
            setMobile(false)
        }
    }, [width])

    useEffect(() => {
    }, [mobile])


    return (
        <div className='MenuDetails'>
            <div className='menu-header'>
                <i className='pi pi-arrow-left' onClick={() => dispatch(setButtonState(ButtonState.HAMBURGER))} />
            </div>
            <div className='menu-container-box'>
                {/* {
                    <div style={{ visibility: buttonVisibility === ButtonState.BASEMAPS ? 'visible' : 'hidden' }}>
                        <Basemap />
                    </div>
                } */}
                {
                    <div style={{ visibility: buttonVisibility === ButtonState.MOBILE_CATEGORICAL_SEARCH ? 'visible' : 'hidden' }}>
                        <CategoricalSearch />
                    </div>
                }
                {
                    <div style={{ visibility: buttonVisibility === ButtonState.LANGUAGE ? 'visible' : 'hidden' }}>
                        <Language />
                    </div>
                }
                {
                    <div style={{ visibility: buttonVisibility === ButtonState.MEASUREMENT ? 'visible' : 'hidden' }}>
                        <DrawTools />
                    </div>
                }
                {
                    <div style={{ visibility: buttonVisibility === ButtonState.MOBILE_SHARE && mobile ? 'visible' : 'hidden' }}>
                        <Share onClose={() => dispatch(setButtonState(ButtonState.HAMBURGER))} />
                    </div>
                }
            </div>
        </div>
    )
}

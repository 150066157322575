import './Toolbar.scss';
import { useDispatch } from 'react-redux';
import { Basemap } from '../../basemap/Basemap';
import { useAppSelector } from '../../../state/hooks';
import { RootState } from '../../../state/store';
import { PrimaryButton } from '../../shared/buttons/primary-button/PrimaryButton';
import { SecondaryButton } from '../../shared/buttons/secondary-button/SecondaryButton';
import { useTranslation } from 'react-i18next';
import { changePanoVisibility } from '../../../state/features/panoramaSlice';
import { setButtonState } from '../../../state/features/buttonStateSlice';
import { ButtonState } from '../../shared/button-state/ButtonState';
import { Sidebar } from '../sidebar/Sidebar';
import { useState } from 'react';
import { DrawStateType, DrawTools } from './draw-tools/DrawTools';
import { setDrawState } from '../../../state/features/drawStateSlice';

export const Toolbar = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const panoramaVisibility = useAppSelector((state: RootState) => state.panorama.visible);
    const infoVisibility = useAppSelector((state: RootState) => state.info.visible);
    const buttonVisibility = useAppSelector((state: RootState) => state.buttonState.value);
    const token = useAppSelector((state: RootState) => state.login.token);
    const layerNameForPanoButton = useAppSelector((state: RootState) => state.panorama.layerName);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { REACT_APP_BASE_URL } = process.env

    /**
     * Bu fonksiyon haritaların bulunduğu componentin açılmasını kontrol eder.
     */
    const toggleBasemap = () => {
        if (buttonVisibility === ButtonState.BASEMAPS) {
            dispatch(setButtonState(ButtonState.DEFAULT))
        }
        else {
            setSidebarOpen(false)
            dispatch(setButtonState(ButtonState.BASEMAPS))
        }
    }

    /**
     * Bu fonksiyon sidebarın açılmasını kontrol eder.
     */
    const toggleSidebar = () => {
        //dispatch(changeSidebarVisibility(true))
        if (buttonVisibility === ButtonState.LAYERS) {
            setSidebarOpen(false)
            dispatch(setButtonState(ButtonState.DEFAULT))
        }
        else {
            setSidebarOpen(true)
            dispatch(setButtonState(ButtonState.LAYERS))
        }
    }

    /**
   * Bu fonksiyon ölçüm buton grubunun açılıp kapanmasını kontrol eder.
   */
    const toggleDrawTools = () => {
        if (buttonVisibility === ButtonState.MEASUREMENT) {
            dispatch(setButtonState(ButtonState.DEFAULT));
            dispatch(setDrawState(DrawStateType.NONE));
        }
        else {
            dispatch(setButtonState(ButtonState.MEASUREMENT));
        }
    }

    /**
     * Panoramanın görünürlüğünü kontrol eder.
     */
    const openClosePanorama = () => {
        dispatch(changePanoVisibility(true))
        if (panoramaVisibility) {
            dispatch(changePanoVisibility(false))
        }
    }

    return (
        <div className='Toolbar'>
            <div className='toolbar-left-content'>
                {token &&
                    <PrimaryButton
                        label={t('BUTTON.Layers')}
                        onClick={toggleSidebar}
                        active={buttonVisibility === ButtonState.LAYERS}
                    >
                    </PrimaryButton>
                }
                <PrimaryButton
                    label={t('BUTTON.Basemaps')}
                    onClick={toggleBasemap}
                    active={buttonVisibility === ButtonState.BASEMAPS}
                >
                </PrimaryButton>
                <PrimaryButton
                    label={t('BUTTON.Measurement')}
                    onClick={toggleDrawTools}
                    active={buttonVisibility === ButtonState.MEASUREMENT}>
                </PrimaryButton>

                <div style={{ visibility: buttonVisibility === ButtonState.MEASUREMENT ? 'visible' : 'hidden' }}>
                    <DrawTools />
                </div>
                <div style={{ visibility: buttonVisibility === ButtonState.BASEMAPS ? 'visible' : 'hidden' }}>
                    <Basemap />
                </div>
                <div style={{ visibility: buttonVisibility === ButtonState.LAYERS ? 'visible' : 'hidden' }}>
                    <Sidebar />
                </div>
                {layerNameForPanoButton && layerNameForPanoButton === 'aof_alan_usr' && !panoramaVisibility && infoVisibility && <PrimaryButton label={t('BUTTON.360° Campus')} onClick={openClosePanorama} />}

                {panoramaVisibility && !infoVisibility &&
                    <a href={`${REACT_APP_BASE_URL}3d/`} target="_blank">
                        <PrimaryButton label={t('BUTTON.3D Campus')} />
                    </a>}
                {infoVisibility && !panoramaVisibility &&
                    <a href={`${REACT_APP_BASE_URL}3d/`} target="_blank">
                        <PrimaryButton label={t('BUTTON.3D Campus')} />
                    </a>}
                {infoVisibility && panoramaVisibility &&
                    <a href={`${REACT_APP_BASE_URL}3d/`} target="_blank">
                        <PrimaryButton label={t('BUTTON.3D Campus')} />
                    </a>}
            </div>
            <div className='toolbar-right-content'>
                {!infoVisibility && !panoramaVisibility && <SecondaryButton label={t('BUTTON.360° Campus')} onClick={openClosePanorama} />}
                {!panoramaVisibility && !infoVisibility && <a href={`${REACT_APP_BASE_URL}3d/`} target="_blank"> <SecondaryButton label={t('BUTTON.3D Campus')} /> </a>}
            </div>
        </div>
    )
}

import { createSlice, current } from '@reduxjs/toolkit';
import { IBasemap } from '../../util/model/basemap';

interface Basemap {
    value: IBasemap
}
//interface duzelt
const initialState: any = {
    value: {
        id: 0,
        title: '',
        url: '',
        icon: '',
        layername: '',
        priority: 0,
        type: 0,
        visible: false
    },
}

export const basemapSlice = createSlice({
    name: 'basemap',
    initialState,
    reducers: {
        getBasemaps: (state: any, action) => {
            state.value = action.payload;
        },
        changeVisibility: (state: any, action) => {
            const { index, visibility } = action.payload as { index: number, visibility: boolean };

            state.value.map((basemap: any) => {
                // if (basemap.title === 'Arazi Kullanım') {
                //     return;
                // }
                basemap.visible = false
            })
            state.value[index].visible = visibility
        }
    },
})

export const { getBasemaps, changeVisibility } = basemapSlice.actions


export default basemapSlice.reducer